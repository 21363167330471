export const Styles = (Theme) => ({
  dFlexTxt: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  outer: {
    padding: "20px 25px",
  },
  topBox1: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 0 40px",
    justifyContent: "space-between",
  },
  spendTxt: {
    display: "flex",
    alignItems: "center",
    marginBottom:"15px",
    "& h3":{
      marginBottom:"0",
    }
  },
  formControl: {
    margin: "0 0 30px",
  },
  titleTxt: {
    color: "#3F444480 !important",
    "& span": {
      color: "#3F444480 !important",
    },
  },

  btnContent: {
    padding: "60px 0 20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  creditsLabel: {
    display: "flex",
    alignItems: "center",
  },
  // tabs design
  pr0: {
    padding: "30px 0 !important",
  },
  tabsList: {
    width: "auto",
    background: "#f1eedb",
    borderRadius: "32px",
    display: "table !important",
  },
  tabsBox: {
    marginTop: "20px",
    "& span": {
      // display: "none",
    },
    "& button": {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#3F4444",
      fontFamily: "'Bebas Neue', serif",
      textTransform: "uppercase",
      padding: "12px 24px",
      textAlign: "center",
      minWidth: "150px",
      "@media (max-width: 900px)": {
        fontSize: "20px",
        lineHeight: "20px",
      },
      "&[aria-selected=true]": {
        color: "#3F4444",
        background: "#ffc200",
        borderRadius: "32px",
      },
    },
  },
  tabsInner: {
    "& > div > div": {
      borderRadius: "30px",
      overflow: "hidden",
      display: "table",
      backgroundColor: "#F1EEDB",
    },
  },
  tabsContent: {
    padding: "25px 0",
    borderRadius: "0 20px 20px 20px",
    display: "table",
    width: "100%",
    height: "100%",
  },
  dFlex: {
    // display: "flex",
    alignItems: "center",
    "& :nth-child(2)": {
      paddingLeft: "20px",
    },
  },
  width70: {
    width: "100%",
  },
  width50: {
    width: "50%",
  },
  width30: {
    width: "100%",
  },
  cursorRemmove: {
    cursor: "inherit !important",
  },
  ddTxtbold:{
    "& span": {
      fontWeight:"800 !important",
    },
  }
});

import { withStyles } from "@mui/styles";
import React from 'react';
import { Styles } from "./Style";
import ForgotForm from "../../component/ForgotForm/ForgotForm";
import StrConstent from "../../common/StrConstent";
const Forgot = (props) => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.overlaycontainer}>
          <div className={`${classes.overlaypanel} ${classes.overlayright}`}>
            <h1>{StrConstent.MainHeading.heading} </h1>
            <p>{StrConstent.MainHeading.description}</p>
          </div>
        </div>
        <div className={`${classes.formcontainer} ${classes.logincontainer}`}>
          <ForgotForm />
        </div>
      </div>
    </div>
  );
}

export default withStyles(Styles)(Forgot);

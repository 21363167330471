import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import Heading from "../../component/Heading/Heading";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import { useDispatch } from "react-redux";
import { addEmployees } from "../../redux/actions/Employees/EmployeesAction";
import StrConstent from "../../common/StrConstent";
import constant from "../../common/constant";
import {
  debounce,
  encryptValue,
  validateEmail,
} from "../../common/utils/utils";
import axios from "axios";
import UseButtonMulticlick from "../useButtonMultiClick/UseButtonMulticlick";

const AddEmployeesForm = (props) => {
  const { classes, cancelHandler, parentCallback } = props;
  let dispatch = useDispatch();
  const [emailError, setEmailError] = useState("");
  const [empError, setEmpError] = useState("");
  const [isTrue, setIsTrue] = useState(false);
  const [inValid, setInValid] = useState(false);
  const [addEmp, setEmp] = useState({
    first_name: "",
    last_name: "",
    work_email: "",
    emp_id: "",
  });

  const getEmail = async (email) => {
    // setEmailError("");
    let payload = {
      email: encryptValue(email.work_email),
    };
    try {
      const { data } = await axios.post(
        `${constant.baseUrl}/api/v1/verify-email`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      if (data.message == "You may continue") {
        setIsTrue(true);
        setEmailError("");
      } else {
        setIsTrue(false);
        setEmailError(data.message);
        // console.log(data.message,'data.message');
      }
    } catch (error) {
      setIsTrue(false);
    }
  };
// unique Emp Id 
  const getEmpid = async (email) => {
 
    // setEmpError("");
    let payload = {
      emp_id: encryptValue(email.emp_id),
    };
    // console.log("empid",email)
    try {
      const { data } = await axios.post(
        `${constant.baseUrl}/api/v1/verify-empid`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      // console.log("empiddata",data)
      if (data.message=="You may continue.") {
        setIsTrue(true);
        setEmpError("");
      } else {
        setIsTrue(false);
        setEmpError(data.message);
        // console.log(data.message,'data.message');
      }
    } catch (error) {
      setIsTrue(false);
    }
  };
  const betterFunction = debounce(getEmail, 300);
  const betterFunctionempid = debounce(getEmpid, 300);
  const handlerChangeInput = (e) => {
    let { name, value } = e.target;
    if (name === "work_email") {
      if (value == " " || value === '') {
        setEmailError("");
      }
      setEmp({ ...addEmp, [name]: value });
      betterFunction({ ...addEmp, [name]: value });
     
    }
    setEmp({ ...addEmp, [name]: value });
 
    if(name==="emp_id"){
      if (value == " " || value === '') {
        setEmpError("");
      }
    betterFunctionempid({...addEmp, [name]: value });
  }};

  const addEmpHandler = () => {
    // console.log("addEmpHandler");
    if (
      addEmp.first_name === "" ||
      addEmp.last_name === "" ||
      validateEmail(addEmp.work_email) ||
      (emailError !== "You may continue." && emailError)||(
        empError!== "You may continue." && empError
      )
     
    )
      return setInValid(true);
    else {
      let payload = {
        first_name: encryptValue(addEmp.first_name),
        last_name: encryptValue(addEmp.last_name),
        work_email: encryptValue(addEmp.work_email),
        emp_id: encryptValue(addEmp.emp_id),
      };
      dispatch(addEmployees(payload));
      setEmp({
        first_name: "",
        last_name: "",
        work_email: "",
        emp_id: "",
      });
      // parentCallback(true);
      cancelHandler();
    }
  };

  const addemp= UseButtonMulticlick(async()=>{
    addEmpHandler()
  })
  return (
    <>
      <div className={classes.formOuter}>
        <div className={classes.formBox}>
          <Heading
            heading={StrConstent.AddEmployeesForm.headerTitle}
            description={StrConstent.AddEmployeesForm.headerDesc}
          />
          <div className={classes.formInner}>
            <div className={classes.formControl}>
              <FormLabel>
                Name<span className="req">*</span>
              </FormLabel>
              <div className={classes.dFlex}>
                <div>
                  <input
                    type="text"
                    name="first_name"
                    value={addEmp.first_name}
                    placeholder="First name"
                    onChange={handlerChangeInput}
                  />
                  {inValid && addEmp.first_name === "" && (
                    <p className="errorMessage">This field is required.</p>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    name="last_name"
                    value={addEmp.last_name}
                    placeholder="Last name"
                    onChange={handlerChangeInput}
                    className={classes.mleft15}
                  />
                  {inValid && addEmp.last_name === "" && (
                    <p className="errorMessage">This field is required.</p>
                  )}
                </div>
              </div>
              <div className={classes.dFlex}></div>
            </div>
            <div className={classes.formControl}>
              <FormLabel>
                Work email address<span className="req">*</span>
              </FormLabel>
              <input
                type="text"
                name="work_email"
                value={addEmp.work_email}
                placeholder="Enter work email address"
                onChange={handlerChangeInput}
              />
              {inValid && addEmp.work_email === "" ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <div></div>
              )}
              {addEmp.work_email !== "" && validateEmail(addEmp.work_email) ? (
                <p className="errorMessage">
                  Information entered is invalid. Please try again.
                </p>
              ) : null}
              <span className="errorMessage">
                {emailError !== "You may continue." && emailError}
              </span>
            </div>
            <div className={classes.formControl}>
              <FormLabel>Employee ID (optional)</FormLabel>
              <input
                type="text"
                name="emp_id"
                value={addEmp.emp_id}
                placeholder="Enter employee ID"
                onChange={(e)=>{handlerChangeInput(e)
               
                }}
              />
              <span className="errorMessage">{empError !== "This employee id already exists." &&  empError}</span>
            </div>
          </div>
        </div>
        <div className={classes.btnContent}>
          <BorderBtn name={"cancel"} clickHandler={cancelHandler} />
          <YellowBtn name={"Confirm"} clickHandler={addemp} />
        </div>
      </div>
    </>
  );
};

export default withStyles(Styles)(AddEmployeesForm);

import React from "react";
import { useAuth } from "../Hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import Sidebar from "../component/Sidebar/Sidebar";
const PrivateRoutes = ({ children }) => {
  const location = useLocation();
  const isAuth = useAuth();
  return isAuth ? (
    <>
      <div className="headerOuter">
        <Sidebar />
      </div>
      <div className="pb LeftSpace">{children}</div>
    </>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default PrivateRoutes;

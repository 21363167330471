export const Styles = Theme => ({
    empOuter: {
        padding: "20px 25px",
    },
    topBox1: {
        display: "flex",
        alignItems: "flex-start",
        padding: "0 0 40px",
        justifyContent: "space-between",
    },
    topBox2: {
        display: "flex",
        alignItems: "flex-start",
        padding: "0 0 30px",
        justifyContent: "space-between",
        "@media (max-width: 991px)": {
            flexDirection: "column",
        },
    },
    inlineBlock: {
        display: "inline-block",
    },
    dFlex: {
        display: "flex",
        alignItems: "center",
        fontFamily: "'Bebas Neue', serif !important",
    },
    imgBox: {
        height: "15px",
        width: "15px",
        objectFit: "cover",
        marginLeft: "5px",
        "& img": {
            height: "100%",
            width: "100%",
        }
    },
    actionBox: {
        "& button": {
            background: "transparent",
            padding: "0",
            minWidth: "auto",
            marginRight: "5px",
        },
    },
    statusBtn: {
        fontFamily: "'Bebas Neue', serif !important",
    },
    verified: {
        background: "#3BD4AE4D !important",
    },

    pending: {
        background: "#F5CFB0; !important",
    },

    archived: {
        background: "#BFC6CD4D !important",
    },

    balBtn: {
        "& button": {
            border: "1px solid #DDDDDD",
            padding: "6px 8px",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444",
            "&:hover": {
                color: "#3F4444",
            },
        },
    },
    saveBtn: {
        color: "#F1EEDB !important",
        background: "#3F4444 !important",
        minWidth: "auto !important",
        fontFamily: "'Bebas Neue', serif !important",
        marginLeft: "5px !important",
        lineHeight: "20px !important",
    },

    toolTipcontent: {
        bacakgroundColor: "#fff",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
    },

    statusDb: {
        padding: "12px 0",
        "& button": {
            fontSize: "18px",
            lineHeight: "20px",
            color: "#3F4444",
            fontWeight: "400",
            padding: "6px !important",
            fontFamily: "'Bebas Neue', serif !important",
            textTransform: "capitalize",
            padding: "0",
            background: "#F4F4F4 !important"
        },
        "& :hover": {
            background: "#F4F4F4"
        },
        "& img": {
            marginLeft: "8px",
        }
    },
    dbContent: {
        "& input": {
            minHeight: "20px",
            height: "20px",
        },
        "& ul": {
            padding: "10px 0 !important",
            "& p": {
                fontSize: "12px !important",
                lineHeight: "20px",
                color: "#3F4444",
                fontWeight: "700",
                fontFamily: "'Noto Serif', serif",
                fontStyle: "normal",
                margin: "5px 0 0",
                textAlign: "left",
                padding: " 0 12px"
            },
            "& li": {
                padding: "8px 10px !important",
                fontSize: "12px !important",
                lineHeight: "20px",
                color: "#3F444480",
                fontWeight: "400",
                fontFamily: "'Noto Serif', serif",
                textTransform: "capitalize",
                cursor: "pointer",
                "& label": {
                    margin: "0 !important",
                    "& span:nth-child(2)": {
                        fontSize: "12px !important",
                    }
                }
            },
        },
    },
    btnContent: {
        display: "flex",
        alignItems: "center",
    },
    balanceBox: {
        padding: "0 !important",
        marginRight: "2px !important",
        "& input": {
            padding: "6px 6px",
            textAlign: "center",
            color: "#3F4444",
            fontSize: "14px",
            fontFamily: "'Noto Serif', serif",
            fontWeight: "400",
            lineHeight: "14px",
            minHeight: "auto !important",
            background: "rgba(227, 221, 210, 0.5) !important",
            border: "none !important",
            borderRadius: "25px !important",
            maxWidth: "60px",
        },
        "& span": {
            padding: "10px 10px",
            textAlign: "center",
            color: "#3F4444",
            fontSize: "14px",
            fontFamily: "'Noto Serif', serif",
            fontWeight: "400",
            lineHeight: "14px",
            minHeight: "auto !important",
            background: "rgba(227, 221, 210, 0.5) !important",
            border: "none !important",
            borderRadius: "25px !important",
            maxWidth: "60px",
            display: "block",
            minWidth: "70px",
        },

    },
    fieldset: {
        display: "none !important",
    },
    modalBox: {
        "& h2": {
            fontSixe: "30px",
            fontWeight: "700",
            lineHeight: "35px",
        }
    },
    noData: {
        height: "100%",
        minHeight: "450px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "750px",
        margin: "auto",
        padding: "0 20px",
        "@media(max-width:991px)": {
            minHeight: "250px",
        },
        "& h2": {
            fontSize: "44px",
            lineHeight: "48px",
            fontWeight: "400",
            marginBottom: "0",
            "@media(max-width:991px)": {
                fontSize: "35px",
                lineHeight: "40px",
            },
        },
        "& p": {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "400",
            color: "#3F444480",
            wordBreak: "break-word",
            textAlign: "center",
        }
    },
    tableLayout:{
        // tableLayout:"fixed",
        "& th":{
            // width:"150px",
        },
    },
    wid180px:{
        width:"280px !important",
    }
})
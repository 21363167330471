import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import StrConstent from "../../common/StrConstent";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CreditAllocation from "./CreditAllocation";
import SpendPolicyUpdate from "./SpendPolicyUpdate";
const SpendPolicyPage = (props) => {
  const { classes } = props;
  const [value, setValue] = useState("1");
  const tabsHandleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.outer}>
      <div className={classes.topBox1}>
        <Heading
          heading={StrConstent.SpendPolicy.headerTitle}
          description={StrConstent.SpendPolicy.headerDesc}
        />
        <CreditsInfo />
      </div>
      <div className={classes.tabsBox}>
        <TabContext value={value}>
          <TabList
            onChange={tabsHandleChange}
            aria-label="lab API tabs example"
            className={classes.tabsList}
          >
            <Tab label="Credit allocation" value={"1"} onChange={value} />
            <Tab label="Spend Policy" value={"2"} onChange={value} />
          </TabList>
          <CreditAllocation onToggle={value} />
          <SpendPolicyUpdate onToggle={value} />
        </TabContext>
      </div>
    </div>
  );
};
export default withStyles(Styles)(SpendPolicyPage);

import { Styles } from "./Style";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardPickUpAction } from "../../redux/actions/Dashboard/DashboardAction";
const PolarArea = (props) => {
  const { classes, stateDate = null, endDate = null } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.dashboardPickUp);
  const options = {
    colors: ["#F1EEDB", "#FFC200"],
    hover: {
      brightness: 0,
      color: "red",
    },
    chart: {
      width: 350,
      type: "pie",
    },
    legend: {
      position: "right",
    },
    labels: data?.pickUpGraph?.success
      ? data?.pickUpGraph?.data?.map((e) => e?.name)
      : "",
    responsive: [
      {
        breakpoint: 991,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "top",
          },
        },
      },
    ],
  };
  const series = data?.pickUpGraph?.success
    ? data?.pickUpGraph?.data?.map((e) => e?.y)
    : 0;

  useEffect(() => {
    if (stateDate && endDate)
      return dispatch(getDashboardPickUpAction(stateDate, endDate));
  }, [stateDate, endDate]);

  useEffect(() => {
    dispatch(getDashboardPickUpAction());
  }, []);
  return (
    <div id="chart" className={classes.pieChart}>
      <Chart options={options} series={series} type="pie" height={350} />
    </div>
  );
};

export default withStyles(Styles)(PolarArea);

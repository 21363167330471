import { ActionType } from "../../ActionTypes";
import axios from "axios";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { getCreditsInfo } from "../Dashboard/DashboardAction";
import constant from "../../../common/constant";
import { reloadHandler } from "../../../common/utils/utils";

export const getTopUpHistoryList = (page) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.TOPUP_HISTORY_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/credit/top-ups?page=${page}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.TOPUP_HISTORY_LIST_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.TOPUP_HISTORY_LIST_FAIL, payload: message });
  }
};

export const getCardsList = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CARDS_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/cards`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.CARDS_LIST_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.CARDS_LIST_FAIL, payload: message });
  }
};

export const deleteCard = (cid) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.DELETE_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.delete(
      `${constant.baseUrl}/api/v1/b2b/users/cards/${cid}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.DELETE_LIST_SUCCESS, payload: data });
    dispatch(getCardsList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.DELETE_LIST_FAIL, payload: message });
  }
};

export const topUpsHandler = (paymentData) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.TOP_UPS_DETAILS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      `${constant.baseUrl}/api/v1/b2b/users/credit/top-up`,
      paymentData,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.TOP_UPS_DETAILS_SUCCESS, payload: data });
    dispatch(getCardsList());
    dispatch(getCreditsInfo());
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.TOP_UPS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const topUpsCorporateHandler =
  (paymentData) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.TOP_UPS_WITH_CORPORATE_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.post(
        `${constant.baseUrl}/api/v1/b2b/users/credit/corporate-top-up`,
        paymentData,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.TOP_UPS_WITH_CORPORATE_SUCCESS,
        payload: data,
      });
      dispatch(getCardsList());
      dispatch(getCreditsInfo());
    } catch (error) {
      reloadHandler(error?.response?.status);
      dispatch({
        type: ActionType.TOP_UPS_WITH_CORPORATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCard = (cardDetail) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.SAVE_CARD_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.addCard, cardDetail, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
      },
    });
    dispatch({ type: ActionType.SAVE_CARD_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.SAVE_CARD_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import { Styles } from "./Style";
import Chart from "react-apexcharts";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
/* const series = [
    {
        name: "sales",
        data: [0, 5, 20, 15, 38, 20, 30, 17, 10, 9, 30, 12]
    }
]
const options = {
    colors: ['#FFC200'],
    borderWidth: 1,
    chart: {
        height: 350,
        type: 'line',
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight'
    },
    xaxis: {
        type: 'category',
        categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        group: {
            groups: [
                {
                    title: 'TIME',
                    cols: 2
                },
            ]
        },
        title: {
            text: 'TIME',
        }
    },
    yaxis: {
        title: {
            text: "TOTAL CREDITS SPENT"
        }
    },
} */
const LineChart = (props) => {
  const { classes, graphData } = props;
  const [optionData, setOptionData] = useState({});
  const [optionValueData, setOptionValueData] = useState([]);

  useEffect(() => {
    const opData = {
      colors: ["#FFC200"],
      borderWidth: 1,
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        type: "category",
        categories: graphData?.creditUsageGraph?.data?.name,
        group: {
          groups: [
            {
              title: "TIME",
              cols: 2,
            },
          ],
        },
        title: {
          text: "TIME",
        },
      },
      yaxis: {
        title: {
          text: "TOTAL CREDITS SPENT",
        },
      },
    };

    setOptionData(opData);
    setOptionValueData([
      {
        name: "sales",
        data: graphData?.creditUsageGraph?.data?.value,
      },
    ]);
  }, [graphData]);
  // const dispatch = useDispatch();
  // const data = useSelector((state) => state?.dashboardPickUp);
  // useEffect(() => {
  //     dispatch(getCreditUsageAction());
  // }, []);
  // useEffect(() => {
  //     console.log("creditUsageGraph:", data);
  // }, [data]);

  return (
    <>
      <div id="chart" className={classes.lineChart}>
        {graphData && optionValueData.length > 0 && (
          <Chart
            options={optionData}
            series={optionValueData}
            type="line"
            height={280}
          />
        )}
      </div>
    </>
  );
};

export default withStyles(Styles)(LineChart);

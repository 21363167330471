export const Styles = (theme) => ({
  h1: {
    fontWeight: "bold",
    margin: "0 0 20px",
    textTransform: "uppercase",
    fontFamily: "'Bebas Neue', serif",
    fontSize: "44px",
    fontWeight: "500",
    lineHeight: "44px",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  wid50: {
    width: "50%",
  },
  ml15: {
    marginLeft: "15px !important",
  },
  box: {
    display: "flex",
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  p: {
    fontSize: "14px",
    fontWeight: "100",
    lineHeight: "20px",
    letterSpacing: "0.5px",
    margin: "20px 0 30px",
  },
  logoBettr: {
    width: 90,
    marginTop: 50,
  },
  fromControl: {
    margin: "20px 0",
  },
  form: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    display: "inline-block",
    justifyContent: "left",
    flexDirection: "column",
    padding: "2rem",
    height: "auto",
    borderRadius: "12px",
    width: "100%",
    boxSizing: " border-box",
    overflowY: "auto",
    margin: "0 !important",
    maxHeight: "calc(100vh - 100px)",
    "&::-webkit-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-moz-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-o-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-ms-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "& input": {
      padding: "12px 20px !important",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      outline: "none",
      border: "1px solid #D5D4DC",
      borderRadius: "4px",
      backgroundColor: "#fff !important",
      width: "100%",
      color: "#3F4444",
      boxSizing: "border-box",
      height: "45px",
      fontFamily: "'Noto Serif', serif",
      borderRadius: "32px",
      border: "none !important",
      "&:focus": {
        backgroundColor: "#fff!important",
      },
      "&:active": {
        backgroundColor: "#fff!important",
      },
      "&:hover": {
        backgroundColor: "#fff!important",
      },
    },
  },
  count: {
    display: "inline-block",
    color: " #3F4444",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    fontFamily: "'Noto Serif', serif",
  },
  titleTxt: {
    "& span": {
      color: "#3F444480 !important",
    },
  },
  yellowBtn: {
    width: "100%",
    "& button": {
      padding: "12px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      width: "100%",
      "&:hover": {
        background: "#FFC200",
      },
    },
  },
  stepper: {
    display: "flex",
    justifyContent: "space-around",
    maxWidth: "358px",
    margin: "auto",
  },
  stepperText: {
    display: "flex",
    justifyContent: "space-around",
    maxWidth: "358px",
    margin: "auto",
    "& span": {
      color: "#3F4444",
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
      display: "block",
      padding: "8px 0",
    },
  },
  stepperBoxDefault: {
    height: "36px",
    width: "36px",
    borderRadius: "100%",
    border: "2px solid #FFC200",
    margin: "0 5px",
    position: "relative",
    zIndex: "10",
    background: "#fff",
    cursor: "pointer",
    "&::before": {
      content: "''",
      height: "82px",
      borderLeft: "2px solid #ffc200",
      position: "absolute",
      right: "-42px",
      top: "50%",
      zIndex: "9",
      transform: "translate(0%, -50%) rotate(90deg)",
    },
    "@media (max-width: 991px)": {
      height: "30px",
      width: "30px",
    },
    "& span": {
      height: "12px",
      width: "12px",
      borderRadius: "100%",
      background: "#FFC200",
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "-1",
    },
  },
  stepperBoxActive: {
    height: "36px",
    width: "36px",
    borderRadius: "100%",
    border: "2px solid #FFC200",
    margin: "0 5px",
    position: "relative",
    zIndex: "10",
    background: "#fff",
    cursor: "pointer",
    "&::before": {
      content: "''",
      height: "82px",
      borderLeft: "2px solid #ffc200",
      position: "absolute",
      left: "-42px",
      top: "50%",
      zIndex: "9",
      transform: "translate(0%, -50%) rotate(90deg)",
    },
    "@media (max-width: 991px)": {
      height: "30px",
      width: "30px",
    },
    "& span": {
      height: "12px",
      width: "12px",
      borderRadius: "100%",
      background: "#FFC200",
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "-1",
    },
  },
  stepperBoxComplete: {
    height: "36px",
    width: "36px",
    borderRadius: "100%",
    border: "2px  solid  #ffc200",
    margin: "0 5px",
    position: "relative",
    zIndex: "10",
    background: "#ffc200",
    cursor: "pointer",
    "&::after": {
      content: "''",
      height: "82px",
      borderLeft: "2px solid #ffc200",
      position: "absolute",
      right: "-42px",
      top: "50%",
      zIndex: "-1",
      transform: "translate(0%, -50%) rotate(90deg)",
    },
    "@media (max-width: 991px)": {
      height: "30px",
      width: "30px",
    },
    "& span": {
      height: "16px",
      width: "8px",
      background: "#FFC200",
      display: "block",
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -50%) rotate(52deg)",
      zIndex: "99",
      border: "2px solid  #3f4444",
      borderLeft: "0",
      borderTop: "0",
    },
  },

  btTxt: {
    padding: "10px 0 0",
    "& p": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "400",
      margin: "5px 0",
      fontFamily: "'Noto Serif', serif",
      paddingRight: "5px",
      textAlign: "center",
    },
    "& a": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "700",
      textAlign: "center",
      fontFamily: "'Noto Serif', serif",
      textDecoration: "underline !important",
      padding: "0 2px",
    },
  },
  policyTxt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  ml: {
    marginLeft: "20px",
  },
  menuItemHidden: {
    display: "none !important",
  },
});

export const Styles = Theme => ({
    repRemove: {
        textAlign:"center !important",
        "& button": {
            background: "transparent !important",
            padding:"0",
        },
    },
    actionBox: {
        "& button": {
            background: "transparent",
            padding: "0",
            minWidth: "auto",
            marginRight: "5px",
        },
    },
    bottomBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& p": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#464A53",
            margin: "0",
        },
    },
    customTooltip: {
        position: "relative",
        display: "flex",
        fontWeight: "700 !important",
        "& img": {
            marginLeft: "5px",
        },
    },
    tooltipBox: {
        "& div:nth-child(2)": {
            overflowY: "unset",
            overflowX: "unset",
            background: "red",
        }
    },
    tooltiptext: {
        background: "#fff",
        padding: "12px",
        borderRadius: "8px",
        position: "relative",
        maxWidth: "250px",
        overflowY: "unset",
        overflowX: "unset",
        "&:after": {
            content: "''",
            position: "absolute",
            top: "100%",
            left: "10%",
            marginLeft: "-5px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "#fff transparent transparent transparent",
        },
        "& b": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444",
            display: "block",
        },
        "& span": {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444B2",
            display: "block",
            padding: "5px 0 0",
        },
    },
    heading: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& h4": {
            fontSize: "18px",
            lineHeight: "18px",
            fontWeight: "400",
            fontFamily: "'Bebas Neue', serif",
            color: "#3F4444",
            display: "block",
            margin: "5px 0",
        },
        "& a": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444",
            display: "block",
            textDecoration: "underline !important",
        }
    },
    toolTipcontent: {
        bacakgroundColor: "#fff",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
    },
    transTableeOuter: {
        padding: "8px",
        background: "#fafafa",
    },
    expandBox: {
        padding: "0 !important",
        width: "100%",
        "& tr": {
            "&:first-child td": {
                background: "transparent",
            },
            "&:nth-child(odd) td": {
                background: "transparent",
            },
            "&:nth-child(even) td": {
                background: "#FFC20033",
                borderRadius: "8px",
            },
            "& td": {
                borderBottom: "none",
                padding: "12px 12px !important",
            },
        },

    },
    creditsBox: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    ltBox: {
        width: "80%",
        display: "flex",
        alignItems: "flex-start",
    },
    imgBox: {
        height: "45px",
        width: "45px",
        background: "#FAFAFA",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        "& img": {
            height: "100%",
            maxWidth: "40px",
            display: "block",
            margin: "auto",
        },
    },
    txtBox: {
        paddingLeft: "10px",
        "& span": {
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            display: "block",
            padding: "0 0 3px",
        },
    },
    rtBox: {
        width: "20%",
        "& span": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            textAlign: "right",
            display: "block",
        },
    },
})
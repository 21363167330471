export const Styles = Theme => ({
    pieChart: {
        position: 'relative',
        "& span": {
            "&:nth-child(1)": {
                border: "2px solid #fff !important",
                borderRadius: "4px !important",
                height: "18px !important",
                width: "18px !important",
            },
            "&:nth-child(2)": {
                fontFamily: "'Bebas Neue', serif !important",
                fontSize: "14px !important",
                lineHeight: "16px !important",
                "@media (max-width:991px)": {
                    fontSize: "12px !important",
                    lineHeight: "12px !important",
                }
            }
        },
        "& path":{
        //    fill:" rgb(255, 194, 0) !important"
        }
    },
    spendingChart: {
        "& text": {
            fontSize: "14px !important",
            lineHeight: "14px !important",
            fontFamily: "'Bebas Neue', serif !important",
            fontWeight:"400",
            "@media (max-width:991px)": {
                fontSize: "12px !important",
                lineHeight: "12px !important",
            }
        }
    },
    lineChart:{
        "& text": {
            fontSize: "14px !important",
            lineHeight: "14px !important",
            fontFamily: "'Bebas Neue', serif !important",
            fontWeight:"400",
            "@media (max-width:991px)": {
                fontSize: "12px !important",
                lineHeight: "12px !important",
            }
        } 
    },
    text:{
        fontSize:"20px",
        lineHeight: "200px !important",
        fontFamily: "'Bebas Neue', serif !important",
    }
})
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

const HomeContentBox = (props) => {
  const propdata = props?.onCounter;
  const { classes } = props;

  return (
    <>
      <div className={classes.homeOuter}>
        <h3>Get started</h3>
        <div className={classes.listingBox}>
          <ul className={classes.boxContent}>
            <li>
              <Link to="/company-profile">
                <div className={classes.innerListing}>
                  <span
                    className={
                      propdata?.set_up_company_profile === 0
                        ? ""
                        : classes.complate
                    }
                  >
                    {propdata?.set_up_company_profile === 0 ? 1 : <CheckIcon />}
                  </span>
                  <p>Set up company profile</p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/employees">
                <div className={classes.innerListing}>
                  <span
                    className={
                      propdata?.add_employee === 0 ? "" : classes.complate
                    }
                  >
                    {propdata?.add_employee === 0 ? 2 : <CheckIcon />}
                  </span>
                  <p>Add Employees</p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/credits">
                <div className={classes.innerListing}>
                  <span
                    className={
                      propdata?.top_up_credits === 0 ? "" : classes.complate
                    }
                  >
                    {propdata?.top_up_credits === 0 ? 3 : <CheckIcon />}
                  </span>
                  <p>Top-up Credits</p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/spend-policy">
                <div className={classes.innerListing}>
                  <span
                    className={
                      propdata?.set_up_spend_policy === 0
                        ? ""
                        : classes.complate
                    }
                  >
                    {propdata?.set_up_spend_policy === 0 ? 4 : <CheckIcon />}
                  </span>
                  <p>Set Spend Policy</p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default withStyles(Styles)(HomeContentBox);

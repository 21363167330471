import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import EditEmployeesForm from "../AddEmployeesForm/EditEmployeesForm";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { getSignleEmployees } from "../../redux/actions/Employees/EmployeesAction";
const DrawerRightEditForm = (props) => {
  const { classes, editEmpHandler, statusImg, parentCallback } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect(() => {
    if (editEmpHandler !== undefined) {
      dispatch(getSignleEmployees(editEmpHandler));
    }
  }, [state]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 440 }}
      role="presentation"
      className={classes.boxOuter}
    >
      <EditEmployeesForm
        status={statusImg}
        editEmpHandler={editEmpHandler}
        cancleHandler={toggleDrawer(anchor, false, "")}
        parentCallback={parentCallback}
      />
    </Box>
  );
  return (
    <div style={{ display: "inline-block" }}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {statusImg == 2 ? (
            <Button>
              <Tooltip title="Edit" arrow>
                <img src="./images/edit-gary.svg" alt="Better cloud" />
              </Tooltip>
            </Button>
          ) : (
            <Button onClick={toggleDrawer(anchor, true)}>
              {" "}
              <Tooltip title="Edit" arrow>
                <img src="./images/edit.svg" alt="Better cloud" />
              </Tooltip>
            </Button>
          )}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            transitionDuration={600}
            // onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
export default withStyles(Styles)(DrawerRightEditForm);

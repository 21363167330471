import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
const YellowBtn = (props) => {
  const { classes, name, clickHandler, disabled } = props;
  return (
    <div className={classes.yellowBtn}>
      <Button type="submit" disabled={disabled} onClick={clickHandler}>
        {name}
      </Button>
    </div>
  );
};
export default withStyles(Styles)(YellowBtn);

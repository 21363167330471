import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import AlertMessage from "../AlertMessage/AlertMessage";
import { userSetPassword } from "../../redux/actions/UserActions";
import LogoContainer from "../LogoContainer/LogoContainer";
import Propover from "../Propover/Propover";
import StrConstent from "../../common/StrConstent";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  encryptValue,
  validatePassword,
  validateConfirmPassword,
} from "../../common/utils/utils";
import Loader from "react-spinner-loader";
import { ActionType } from "../../redux/ActionTypes";
import { useNavigate, useParams } from "react-router-dom";
const SetPasswordForm = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputPassword, setInputPassword] = useState({
    setPassword: "",
    confirmPassword: "",
  });
  const { password_token } = useParams();
  // console.log("passwordToken", password_token);
  const [isOpen, setIsOpen] = useState(false);
  const [passwordShowPassword, setPasswordShowPassword] = useState("");
  const [confirmShowPassword, setConfirmShowPassword] = useState("");
  const { setpassMesg, success, loading, error } = useSelector(
    (state) => state.setPassword
  );
  const [inValid, setInValid] = useState(false);

  // useEffect(() => {
  //   if (success || error) {
  //     setIsOpen(true);
  //     // setTimeout(() => {
  //     //   setIsOpen(false);
  //     //   dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
  //     // }, 30000);
  //   }
  // }, [success, error]);

  const snackHandler = (event, reason) => {
    if (reason === "clickaway") return;
    setIsOpen(false);
    dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
  };
  const handleSetPassword = () => {
    if (
      validatePassword(inputPassword.setPassword) ||
      validateConfirmPassword(
        inputPassword.setPassword,
        inputPassword.confirmPassword
      )
    )
      return setInValid(true);
    else {
      const payLoad = {
        password_token,
        password: encryptValue(inputPassword.setPassword),
        password_confirmation: encryptValue(inputPassword.confirmPassword),
      };
      dispatch(userSetPassword(payLoad), setIsOpen(true));
    }
  };
  const handlerChangeInput = (e) => {
    const { name, value } = e.target;
    setInputPassword((pre) => ({ ...pre, [name]: value }));
  };
  useEffect(() => {
    if (success) {
      localStorage.setItem("isChanged", true);
      navigate("/login");
    }
  }, [success]);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {/* {success && (
        <AlertMessage
          handleClose={snackHandler}
          description={setpassMesg?.message}
          title="Password created successfully"
          type="Success"
          isopen={isOpen}
          url="../images/success.svg"
        />
      )} */}
      {error && (
        <AlertMessage
          handleClose={snackHandler}
          description={
            typeof error !== "object" ? error : "Something went wrong."
          }
          title="Error"
          type="Error"
          isopen={isOpen}
          url="../images/error.svg"
        />
      )}
      <form action="#" className={classes.form} onSubmit={handleSetPassword}>
        <LogoContainer
          heading={StrConstent.SetPassword.heading}
          goUrl={"/login"}
          urlTxt={"I have an account"}
        />
        <div className={classes.formControl}>
          <FormLabel>
          New Password<span className="req">*</span>
            <Propover>
              <b>{StrConstent.passwordGuideLine.propoverTitle}</b>
              <ul>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription1}
                  </span>
                </li>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription2}
                  </span>
                </li>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription3}
                  </span>
                </li>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription4}
                  </span>
                </li>
              </ul>
            </Propover>
          </FormLabel>
          <div className={classes.passwordBox}>
            <input
              type={passwordShowPassword ? "text" : "password"}
              name="setPassword"
              placeholder="Enter your new password"
              onChange={handlerChangeInput}
            />
            <span
              onClick={() => setPasswordShowPassword(!passwordShowPassword)}
            >
              {passwordShowPassword ? <Visibility /> : <VisibilityOff />}
            </span>
          </div>
          {inValid && inputPassword.setPassword === "" ? (
            <p className="errorMessage">This field is required.</p>
          ) : (
            <div></div>
          )}
          {inputPassword.setPassword !== "" &&
          validatePassword(inputPassword.setPassword) ? (
            <p className="errorMessage">
              Your password must be at least 8 characters, with at least one number, one special character, and have a mixture of uppercase and lowercase letters.
            </p>
          ) : null}
        </div>
        <div className={classes.formControl}>
          <FormLabel>
          Confirm New Password <span className="req">*</span>
            <Propover>
              <b>{StrConstent.passwordGuideLine.propoverTitle}</b>
              <ul>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription1}
                  </span>
                </li>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription2}
                  </span>
                </li>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription3}
                  </span>
                </li>
                <li>
                  <span>
                    {StrConstent.passwordGuideLine.propoverDescription4}
                  </span>
                </li>
              </ul>
            </Propover>
          </FormLabel>
          <div className={classes.passwordBox}>
            <input
              type={confirmShowPassword ? "text" : "password"}
              name="confirmPassword"
              placeholder="Re-enter your new password"
              onChange={handlerChangeInput}
            />
            <span onClick={() => setConfirmShowPassword(!confirmShowPassword)}>
              {confirmShowPassword ? <Visibility /> : <VisibilityOff />}
            </span>
          </div>
          {inValid && inputPassword.confirmPassword === "" ? (
            <p className="errorMessage">This field is required.</p>
          ) : (
            <div></div>
          )}
          {inputPassword.confirmPassword !== "" &&
          validateConfirmPassword(
            inputPassword.setPassword,
            inputPassword.confirmPassword
          ) ? (
            <p className="errorMessage">
              The passwords do not match. Please try again.
            </p>
          ) : null}
        </div>
        <div className={classes.buttonCls}>
          <div className={classes.yellowBtn}>
            <Button onClick={() => handleSetPassword()}>Submit</Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default withStyles(Styles)(SetPasswordForm);

export const Styles = (Theme) => ({
  formOuter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  formBox: {
    padding: "0 0 20px",
    "& > div": {
      width: "100%",
      paddingRight: "0",
    },
  },
  formInner: {
    padding: "30px 0",
    "& > div":{
      margin: "0 0 20px",
    }
  },
  formControl: {
    // margin: "0 0 20px",
  },
  btnContent: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  dFlex: {
    display: "flex",
    // alignItems: "center",
    "& > div":{
      width:"50%",
      "& input":{
        width:"100%",
      }
    },
  },
  mleft15: {
    marginLeft: "15px",
  },
});

import * as React from 'react';
import Button from '@mui/material/Button';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import YellowBtn from '../../component/YellowBtn/YellowBtn';
const ThankYouMessage = (props) => {
    const { classes } = props
    return (
        <div className={classes.thankOuter}>
            <div className={classes.thankBox}>
                <h2>Thank You !</h2>
                <div className={classes.content}><CheckCircleIcon /><h4>Payment Done successfully</h4></div>
                <p>for contacting us, we will reply promptly once your message is received.</p>
                <YellowBtn name="Take me to My Profile" />
            </div>
        </div>
    )
}

export default withStyles(Styles)(ThankYouMessage);
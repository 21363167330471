import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ListItemText from "@mui/material/ListItemText";
import Constant from "../../common/constant";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "react-spinner-loader";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import { logout, userDetailAction } from "../../redux/actions/UserActions";
import { getCompanyDetails } from "../../redux/actions/CompanyProfile/CompanyProfileAction";
import { ActionType } from "../../redux/ActionTypes";
const Navbar = (props) => {
  const { classes } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { loading, userInfo } = useSelector((state) => state.userDetail);
  const { CompanyDetails } = useSelector((state) => state.company);
  useEffect(() => {
    dispatch(userDetailAction());
    dispatch(getCompanyDetails());
  }, []);
  const navigate = useNavigate()
  useEffect(() => {
    if (window.history.length == 1) {

      sessionStorage.setItem("status", "active");
    }
    if (localStorage.getItem("isremember") === "true") {
    } else if (
      localStorage.getItem("isremember") === "false" &&
      sessionStorage.getItem("status") !== "active"
    ) {
      localStorage.clear();
      dispatch({ type: ActionType.CLEAR_ERROR });
      navigate("/login");
      console.log("Vikalp",sessionStorage.getItem("status"))
    }

  }, [])

  return (
    <>
      {loading && (
        <Loader
          Style={{ zIndex: "9999" }}
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <div className={classes.navBar}>
        <div className={classes.navBarInner}>
          <div className={classes.menu}>
            <div className={classes.logoContent}>
              <Link to="/">
                <img src="./images/logo.svg" alt="Better cloud" />
              </Link>
              {process.env.REACT_APP_STAGING_MODE === "true" ? (
                <Chip label="Staging" variant="" className={classes.staging} />
              ) : null}
            </div>
            <ul className="navbar">
              {Constant.menus.map((data, index) => (
                <MenuItem
                  key={index}
                  selected={location.pathname == `${data.route}` ? true : false}
                >
                  <Link to={data.route} className={classes.items}>
                    <div className={classes.iconContent}>
                      <ListItemText primary={data.icon} />
                    </div>
                    <div className={classes.txtContent}>
                      <ListItemText primary={data.label} />
                    </div>
                  </Link>
                </MenuItem>
              ))}
            </ul>
          </div>
          <div className={classes.btBox}>
            <span className={classes.txtBox}>
              <span className={classes.txtLimit}>
                {userInfo?.data.user_data.first_name}{" "}
                {userInfo?.data.user_data.last_name}
              </span>
              <span className={classes.txtLimit}>{CompanyDetails?.data.company_data.company_name}</span>
            </span>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? "stella" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              disableRipple
            >
              <MoreVertIcon />
            </Button>
            <Menu
              id="stella"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              className={classes.logoutSelectBox}
              disableRipple
              disableFocusRipple
            >
              <MenuItem onClick={() => dispatch(logout())}>
                <p>Logout</p>
                <img src="./images/logout.svg" alt="Better cloud" />
              </MenuItem>
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/settings">
                  <p>Settings</p>
                  <img src="./images/setting.svg" alt="Better cloud" />
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(Navbar);

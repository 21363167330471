export const Styles = (Theme) => ({
    pr10:{
        paddingRight:"10px",
    },
    logoContent: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        margin: "0 0 25px",
    },
    logo: {
        width: "100px",
        margin: "0 0 25px",
    },
    haveAccount: {
        paddingLeft: "5px",
    },
    heading:{
        fontSize:"44px",
        lineHeight:"48px",
        fontWeight:"400",
        margin:"0 0 !important",
        "@media (max-width: 1200px)": {
            fontSize: "34px",
            lineHeight: "38px",
          },
    }
})
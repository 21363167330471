import { combineReducers } from "redux";
import {
  companyReducer,
  updateCompanyReducer,
} from "./reducers/Comapany/CompanyProfileReducer";
import {
  cardsListReducer,
  deleteCardReducer,
  topupCardReducer,
  topupCardWithCorporateReducer,
  topUpHistoryListReducer,
  saveCardReducer,
} from "./reducers/Credits/CreditReducer";
import {
  addEmpReducer,
  deleteEmpReducer,
  employeeListReducer,
  updateEmpReducer,
  employeeImportReducer,
  updateSingleEmployeeBalanceReducer,
} from "./reducers/Employees/EmployeesReducer";
import { faqListitemReducer, faqListReducer } from "./reducers/Faqs/FaqReducer";
import {
  getCategoryListReducer,
  getOutletsListReducer,
  spendPolicyHistoryListReducer,
  updatePolicyReducer,
  updateCreditAllocationReducer,
  spendPolicyCreditAllocationHistory,
  spendPolicyCompanyModes,
} from "./reducers/SpendPolicy/SpendPolicyReducer";
import {
  transactionReducer,
  getTransactions,
  getOrdreDetailReducer,
} from "./reducers/Transactions/transactionReducer";
import {
  userRegisterReducer,
  userResendEmailLinkReducer,
  userSetPasswordReducer,
  userSigninReducer,
  userSignOutReducer,
  userUpdatePasswordReducer,
  userForgotPasswordReducer,
  userSettingReducer,
  emailVerifyReducer,
  verifyUenReducer,
  userDetailReducer,
  verifyCompanyname,
} from "./reducers/userReducer";
import {
  dashboardReducer,
  creditsInfoReducer,
  configurationReducer,
  dashboardPickUpReducer,
  creditUsageReducer,
  userFrequencyReducer,
  topFiveReducer,
} from "./reducers/Dashboard/DashboardReducer";
const rootReducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  resendEmailLink: userResendEmailLinkReducer,
  setPassword: userSetPasswordReducer,
  forgot: userForgotPasswordReducer,
  logout: userSignOutReducer,
  updatePassword: userUpdatePasswordReducer,
  transactionDta: transactionReducer,
  employeeData: employeeListReducer,
  addEmpMsg: addEmpReducer,
  deleteEmpMsg: deleteEmpReducer,
  updateEmpMsg: updateEmpReducer,
  company: companyReducer,
  updateCompanyData: updateCompanyReducer,
  spendPolicyHistory: spendPolicyHistoryListReducer,
  updatePolicy: updatePolicyReducer,
  spendPolicyCategory: getCategoryListReducer,
  spendPolicyOutlets: getOutletsListReducer,
  faqs: faqListReducer,
  faqItem: faqListitemReducer,
  topupHistory: topUpHistoryListReducer,
  cardList: cardsListReducer,
  topupwithcorporate: topupCardWithCorporateReducer,
  messageShow: topupCardReducer,
  messageDelete: deleteCardReducer,
  details: dashboardReducer,
  creditsData: creditsInfoReducer,
  userSetting: userSettingReducer,
  addCard: saveCardReducer,
  getTransacList: getTransactions,
  employeeImport: employeeImportReducer,
  updateEmpBalance: updateSingleEmployeeBalanceReducer,
  getOrderDetail: getOrdreDetailReducer,
  updateCreditAllocation: updateCreditAllocationReducer,
  spendPolicyCreditAllocatedHistory: spendPolicyCreditAllocationHistory,
  getSpendPolicyCompanyModes: spendPolicyCompanyModes,
  configuration: configurationReducer,
  emailVerify: emailVerifyReducer,
  dashboardPickUp: dashboardPickUpReducer,
  creditUsage: creditUsageReducer,
  userFrequency: userFrequencyReducer,
  topFive: topFiveReducer,
  verifyUen: verifyUenReducer,
  verifycompany:verifyCompanyname,
  userDetail: userDetailReducer,
});

export default rootReducer;

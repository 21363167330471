import React from "react";
export default {
  baseUrl: process.env.REACT_APP_BASEURL,
  encKey: process.env.REACT_APP_ENCKEY,
  stripeKey: process.env.REACT_APP_STRIPKEY,
  menus: [
    {
      label: "Home",
      route: "/",
      icon: <img src="./images/home.svg" alt="Better cloud" />,
    },
    {
      label: "Employees",
      route: "/employees",
      icon: <img src="./images/employees.svg" alt="Better cloud" />,
    },
    {
      label: "Spend Policy",
      route: "/spend-policy",
      icon: <img src="./images/spend.svg" alt="Better cloud" />,
    },
    {
      label: "Transactions",
      route: "/transactions",
      icon: <img src="./images/ballot.svg" alt="Better cloud" />,
    },
    {
      label: "Credits",
      route: "/credits",
      icon: <img src="./images/credits.svg" alt="Better cloud" />,
    },
    {
      label: "Company Profile",
      route: "/company-profile",
      icon: <img src="./images/ballot.svg" alt="Better cloud" />,
    },
    {
      label: "FAQs",
      route: "/faqs",
      icon: <img src="./images/excalamationmarksqu.svg" alt="Better cloud" />,
    },
  ],
};

import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import FormLabel from "@mui/material/FormLabel";
import StrConstent from "../../common/StrConstent";
import { Link as Linkscroll } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import {
  encryptValue,
  validateEmail,
  validatePhone,
  validatePostalCode,
  validateRequired,
  validateUEN,
} from "../../common/utils/utils";
import Loader from "react-spinner-loader";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
import {
  companyProfileUpdate,
  getCompanyDetails,
} from "../../redux/actions/CompanyProfile/CompanyProfileAction";
import { ActionType } from "../../redux/ActionTypes";
import { MenuItem, Select } from "@mui/material";
const CompanyProfilePage = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [updateAccount, setAccount] = useState();
  const [refreshWallet, setRefreshWallet] = useState(false);
 
  const [updateCompany, setCompanyDetails] = useState({
    company_name: "",
    company_email: "",
    license_number: "",
    address: "",
    postal_code: "",
    city: "",
    company_representative_first_name: "",
    company_representative_last_name: "",
    contact_number: "",
    billing_address: "",
    billing_postal_code: "",
    billing_contact_person_first_name: "",
    billing_contact_person_last_name: "",
    billing_email: "",
    billing_contact_number: "",
    company_size: ""
  });
  const { CompanyDetails, loading } = useSelector((state) => state.company);
  const {
    updateComp,
    success,
    error,
    loading: loadingUpdate,
  } = useSelector((state) => state.updateCompanyData);
  useEffect(() => {
    dispatch(getCompanyDetails());
  }, [dispatch]);

  // useEffect(() => {
  //   if (success || error) {
  //     setTimeout(() => {
  //       dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
  //     }, 3000);
  //   }
  // }, [success, error]);
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway")
    dispatch({ type: ActionType.TOPUP_MESSAGE_RESET }, setIsOpen(false));
  };

  useEffect(() => {
    if (CompanyDetails?.data) {
      setAccount({
        accountList: CompanyDetails?.data.account_manager,
      });
      setCompanyDetails({
        ...updateCompany,
        company_name: CompanyDetails?.data.company_data.company_name,
        company_email: CompanyDetails?.data.company_data.company_email,
        license_number: CompanyDetails?.data.company_data.license_number,
        address: CompanyDetails?.data.company_data.address,
        postal_code: CompanyDetails?.data.company_data.postal_code,
        city: CompanyDetails?.data.company_data.city,
        company_representative_first_name:
          CompanyDetails?.data.company_data.company_representative_first_name,
        company_representative_last_name:
          CompanyDetails?.data.company_data.company_representative_last_name,
        contact_number: CompanyDetails?.data.company_data.contact_number,
        billing_address: CompanyDetails?.data.company_data.billing_address,
        billing_postal_code:
          CompanyDetails?.data.company_data.billing_postal_code,
        billing_contact_person_first_name:
          CompanyDetails?.data.company_data.billing_contact_person_first_name,
        billing_contact_person_last_name:
          CompanyDetails?.data.company_data.billing_contact_person_last_name,
        billing_email: CompanyDetails?.data.company_data.billing_email,
        billing_contact_number:
          CompanyDetails?.data.company_data.billing_contact_number,
        company_size: CompanyDetails?.data?.company_data?.company_size
      });
    }
  }, [CompanyDetails]);

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 69) {
      event.preventDefault();
    }
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({ ...updateCompany, [name]: value });
    console.log("companydetails", value)
  };
  const [inValid, setInValid] = useState(false);

  const handleCompanyUpdateSubmit = (e) => {
    e.preventDefault();
    setInValid(false);
    if (
      validateEmail(updateCompany.company_email) ||
      validateUEN(updateCompany.license_number) ||
      validateRequired(updateCompany.company_name) ||
      validatePhone(updateCompany.contact_number) ||
      validateRequired(updateCompany.address) ||
      validateRequired(updateCompany.postal_code) ||
      validateRequired(updateCompany.city) ||
      validateRequired(updateCompany.company_representative_first_name) ||
      updateCompany.company_representative_last_name === "" ||
      (updateCompany.billing_contact_number !== "" &&
        validatePhone(updateCompany.billing_contact_number)) ||
      validateRequired(updateCompany.company_size)
    ) {
  
      return setInValid(true);
    } else {
   
      const payload = {
        company_name: encryptValue(updateCompany.company_name),
        company_email: encryptValue(updateCompany.company_email),
        license_number: encryptValue(updateCompany.license_number),
        address: encryptValue(updateCompany.address),
        postal_code: encryptValue(updateCompany.postal_code),
        city: encryptValue(updateCompany.city),
        company_representative_first_name: encryptValue(
          updateCompany.company_representative_first_name
        ),
        company_representative_last_name: encryptValue(
          updateCompany.company_representative_last_name
        ),
        contact_number: encryptValue(updateCompany.contact_number),
        billing_address: encryptValue(updateCompany.billing_address),
        billing_postal_code: encryptValue(updateCompany.billing_postal_code),
        billing_contact_person_first_name: encryptValue(
          updateCompany.billing_contact_person_first_name
        ),
        billing_contact_person_last_name: encryptValue(
          updateCompany.billing_contact_person_last_name
        ),
        billing_email: encryptValue(updateCompany.billing_email),
        billing_contact_number: encryptValue(
          updateCompany.billing_contact_number
        ),
        company_size: encryptValue(updateCompany.company_size)
      };
      setIsOpen(true);
      return dispatch(companyProfileUpdate(payload), setRefreshWallet(true));
    }
  };

  const clickHandler = (input) => {
    if (input) {
      setAccount({
        accountList: CompanyDetails?.data.account_manager,
      });
      setCompanyDetails({
        ...updateCompany,
        company_name: CompanyDetails?.data.company_data.company_name,
        company_email: CompanyDetails?.data.company_data.company_email,
        license_number: CompanyDetails?.data.company_data.license_number,
        address: CompanyDetails?.data.company_data.address,
        postal_code: CompanyDetails?.data.company_data.postal_code,
        city: CompanyDetails?.data.company_data.city,
        company_representative_first_name:
          CompanyDetails?.data.company_data.company_representative_first_name,
        company_representative_last_name:
          CompanyDetails?.data.company_data.company_representative_last_name,
        contact_number: CompanyDetails?.data.company_data.contact_number,
        billing_address: CompanyDetails?.data.company_data.billing_address,
        billing_postal_code:
          CompanyDetails?.data.company_data.billing_postal_code,
        billing_contact_person_first_name:
          CompanyDetails?.data.company_data.billing_contact_person_first_name,
        billing_contact_person_last_name:
          CompanyDetails?.data.company_data.billing_contact_person_last_name,
        billing_email: CompanyDetails?.data.company_data.billing_email,
        billing_contact_number:
          CompanyDetails?.data.company_data.billing_contact_number,
        company_size: CompanyDetails?.data.company_data.company_size
      });
      return setInValid(false);
    } else return;
  };


  console.log("companysize", CompanyDetails?.data.company_data.company_size);
  return (
    <>
      {loadingUpdate && (
        <Loader
          Style={{ zIndex: "9999" }}
          show={loadingUpdate}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {(success || error) && (
        <AlterMessage
          handleClose={snackHandler}
          description={success ? updateComp?.message : error}
          title={success ? "Success" : "Error"}
          type={success ? "Success" : "Error"}
          isopen={isOpen}
          url={success ? "./images/success.svg" : "./images/error.svg"}
        />
      )}
      <div className={classes.empOuter} id={'viewmores'}>
        <div className={classes.topBox1} >
          <Heading
            heading={StrConstent.CompanyProfile.headerTitle}
            description={StrConstent.CompanyProfile.headerDesc}
          />
          <CreditsInfo refreshWallet={refreshWallet} />
        </div>
        <div className="proOuter">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              {updateAccount && updateAccount?.account_manager && (
                <div className={classes.tabsBox}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    className={classes.tabsInner}
                  >
                    <Tab label="Company" value="1" />

                    <Tab label="Account Manager" value="2" />
                  </TabList>
                </div>
              )}
              <TabPanel value="1" className={classes.p0}>
                <div className={classes.tabsContent}>
                  {updateCompany && (
                    <form>
                      <div className={classes.fromBox}>
                        <h3>details</h3>
                        <div className={classes.formInner}>
                          <div className={classes.dFlex}>
                            <div className={classes.width70}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Company name<span className="req">*</span>
                                </FormLabel>
                                <input
                                  type="text"
                                  name="company_name"
                                  value={updateCompany.company_name}
                                  placeholder="Enter company name"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {inValid &&
                                  validateRequired(
                                    updateCompany.company_name
                                  ) && (
                                    <p className={classes.errorMes}>
                                      This field is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className={classes.width30}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Unique Entity Number (UEN)
                                  <span className="req">*</span>
                                </FormLabel>
                                <input
                                  type="text"
                                  name="license_number"
                                  value={updateCompany.license_number}
                                  placeholder="Enter UEN"
                                  disabled={CompanyDetails?.data}
                                />
                                {inValid &&
                                  updateCompany.license_number === "" && (
                                    <p className={classes.errorMes}>
                                      This field is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className={classes.dFlex}>
                            <div className={classes.width70}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Address<span className="req">*</span>
                                </FormLabel>
                                <input
                                  type="text"
                                  name="address"
                                  value={updateCompany.address}
                                  placeholder="Enter address"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {inValid &&
                                  validateRequired(updateCompany.address) && (
                                    <p className={classes.errorMes}>
                                      This field is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className={classes.width30}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Postal code<span className="req">*</span>
                                </FormLabel>
                                <input
                                  type="number"
                                  name="postal_code"
                                  value={updateCompany.postal_code}
                                  placeholder="Enter postal code"
                                  onChange={(e) => {
                                    if (e.target.value.length > 6) {
                                      return
                                    }
                                    return inputHandler(e)
                                  }}
                                  onKeyDown={handleKeyDown}
                                  step="0"
                                // onInput={(e) => {
                                //   e.target.value = Math.max(
                                //     0,
                                //     parseInt(e.target.value)
                                //   )
                                //     .toString()
                                //     .slice(0, 6);
                                // }}
                                />
                                {inValid &&
                                  validateRequired(updateCompany.postal_code) ? (
                                  <p className={classes.errorMes}>
                                    This field is required.
                                  </p>
                                ) : (
                                  <div></div>
                                )}
                                {updateCompany.postal_code !== "" &&
                                  validatePostalCode(
                                    updateCompany.postal_code
                                  ) ? (
                                  <p className={classes.errorMes}>
                                    Information entered is invalid. Please try
                                    again.
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className={classes.dFlex}>
                            <div className={classes.width70}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  City<span className="req">*</span>
                                </FormLabel>
                                <input
                                  type="text"
                                  name="city"
                                  value={updateCompany.city}
                                  placeholder="Enter city"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {inValid &&
                                  validateRequired(updateCompany.city) && (
                                    <p className={classes.errorMes}>
                                      This field is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className={classes.width30}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Company Size<span className="req">*</span>
                                </FormLabel>
                                <Select
                                  requiredLinkscroll
                                  name="company_size"
                                  defaultValue={CompanyDetails?.data?.company_data?.company_size}
                                  // value={CompanyDetails?.data?.company_data?.company_size}
                                  value={updateCompany.company_size}
                                  // displayEmpty
                                  style={{ paddingLeft: 0 }}
                                  onChange={(e) => inputHandler(e)}
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem
                                    value=""
                                  // className={showPlaceholder && classes.menuItemHidden}
                                  >
                                    <span>Company size</span>
                                  </MenuItem>
                                  <MenuItem value={updateCompany.company_size == '50' ? "50" : "< 50"}>{"< 50"}</MenuItem>
                                  <MenuItem value={"51-250"}>51-250</MenuItem>
                                  <MenuItem value={"251-500"}>251-500</MenuItem>
                                  <MenuItem value={"551-1000"}>501-1000</MenuItem>
                                  <MenuItem value={updateCompany.company_size == '1000' ? "1000" : "> 1000"}>{"> 1000"}</MenuItem>
                                </Select>
                                {inValid &&
                                  validateRequired(updateCompany.company_size) && (
                                    <p className={classes.errorMes}>
                                      This field is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.fromBox}>
                        <h3>Contact</h3>
                        <div className={classes.formInner}>
                          <FormLabel>
                            Administrator<span className="req">*</span>
                          </FormLabel>
                          <div className={classes.dFlex}>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <input
                                  type="text"
                                  name="company_representative_first_name"
                                  value={
                                    updateCompany.company_representative_first_name
                                  }
                                  placeholder="First name"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {inValid &&
                                  validateRequired(
                                    updateCompany.company_representative_first_name
                                  ) && (
                                    <p className={classes.errorMes}>
                                      This field is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <input
                                  type="text"
                                  name="company_representative_last_name"
                                  value={
                                    updateCompany.company_representative_last_name
                                  }
                                  placeholder="Last name"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {inValid &&
                                  updateCompany.company_representative_last_name ===
                                  "" && (
                                    <p className={classes.errorMes}>
                                      This field is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className={classes.dFlex}>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Work Email<span className="req">*</span>
                                </FormLabel>
                                <input
                                  required
                                  type="email"
                                  name="company_email"
                                  value={updateCompany.company_email}
                                  placeholder="Enter email"
                                  onChange={(e) => inputHandler(e)}
                                  disabled={CompanyDetails?.data}
                                />
                                {inValid &&
                                  validateRequired(
                                    updateCompany.company_email
                                  ) ? (
                                  <p className={classes.errorMes}>
                                    Information entered is invalid. Please try
                                    again.
                                  </p>
                                ) : (
                                  <div></div>
                                )}
                                {updateCompany.company_email !== "" &&
                                  validateEmail(updateCompany.company_email) ? (
                                  <p className={classes.errorMes}>
                                    Information entered is invalid. Please try
                                    again.
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Contact number<span className="req">*</span>
                                </FormLabel>
                                <input
                                  type="number"
                                  name="contact_number"
                                  value={updateCompany.contact_number}
                                  placeholder="Enter contact number"
                                  onChange={(e) => inputHandler(e)}
                                  onKeyDown={handleKeyDown}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 8);
                                  }}
                                />
                                {inValid &&
                                  validateRequired(
                                    updateCompany.contact_number
                                  ) ? (
                                  <p className={classes.errorMes}>
                                    This field is required.
                                  </p>
                                ) : null}
                                {updateCompany.contact_number !== "" &&
                                  validatePhone(updateCompany.contact_number) ? (
                                  <p className={classes.errorMes}>
                                    Information entered is invalid. Please try
                                    again.{" "}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.fromBox}>
                        <h3>Billing</h3>
                        <div className={classes.formInner}>
                          <FormLabel>
                            Contact person (if different from administrator)
                          </FormLabel>
                          <div className={classes.dFlex}>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <input
                                  type="text"
                                  name="billing_contact_person_first_name"
                                  value={
                                    updateCompany.billing_contact_person_first_name
                                  }
                                  placeholder="First name"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {/* {inValid &&
                                    updateCompany.billing_contact_person_first_name ===
                                      "" && (
                                      <p className={classes.errorMes}>
                                        First Name Required
                                      </p>
                                    )} */}
                              </div>
                            </div>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <input
                                  type="text"
                                  name="billing_contact_person_last_name"
                                  value={
                                    updateCompany.billing_contact_person_last_name
                                  }
                                  placeholder="Last name"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {/* {inValid &&
                                    updateCompany.billing_contact_person_last_name ===
                                      "" && (
                                      <p className={classes.errorMes}>
                                        Last Name Required
                                      </p>
                                    )} */}
                              </div>
                            </div>
                          </div>
                          <div className={classes.dFlex}>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Email (if different from administrator)
                                </FormLabel>
                                <input
                                  type="email"
                                  name="billing_email"
                                  value={updateCompany.billing_email}
                                  placeholder="Enter email"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {/* {inValid &&
                                    updateCompany.billing_email === "" && (
                                      <p className={classes.errorMes}>
                                        Email Required
                                      </p>
                                    )} */}
                                {updateCompany.billing_email !== "" &&
                                  validateEmail(updateCompany.billing_email) ? (
                                  <p className={classes.errorMes}>
                                    Information entered is invalid. Please try
                                    again.
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className={classes.width50}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Contact number (if different from
                                  administrator)
                                </FormLabel>
                                <input
                                  type="number"
                                  name="billing_contact_number"
                                  value={updateCompany.billing_contact_number}
                                  placeholder="Enter contact number"
                                  onChange={(e) => inputHandler(e)}
                                  onKeyDown={handleKeyDown}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 8);
                                  }}
                                />
                                {/* {inValid &&
                                  updateCompany.billing_contact_number === "" ? (
                                  <p className={classes.errorMes}>
                                    Contact Number Required
                                  </p>
                                ) : null} */}
                                {updateCompany.billing_contact_number !== "" &&
                                  validatePhone(
                                    updateCompany.billing_contact_number
                                  ) ? (
                                  <p className={classes.errorMes}>
                                    Information entered is invalid. Please try
                                    again.{" "}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className={classes.dFlex}>
                            <div className={classes.width70}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Billing address (if different)
                                </FormLabel>
                                <input
                                  type="text"
                                  name="billing_address"
                                  value={updateCompany.billing_address}
                                  placeholder="Enter address"
                                  onChange={(e) => inputHandler(e)}
                                />
                                {/* {inValid &&
                                    updateCompany.billing_address === "" && (
                                      <p className={classes.errorMes}>
                                        Address Required
                                      </p>
                                    )} */}
                              </div>
                            </div>
                            <div className={classes.width30}>
                              <div className={classes.formControl}>
                                <FormLabel>
                                  Postal code (if different)
                                </FormLabel>
                                <input
                                  type="number"
                                  name="billing_postal_code"
                                  value={updateCompany.billing_postal_code}
                                  placeholder="Enter postal code "
                                  onChange={(e) => inputHandler(e)}
                                  onKeyDown={handleKeyDown}
                                  step="0"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                />
                                {updateCompany.billing_postal_code !== "" &&
                                  validatePostalCode(
                                    updateCompany.billing_postal_code
                                  ) ? (
                                  <p className={classes.errorMes}>
                                    Information entered is invalid. Please try
                                    again.
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.btnContent}>
                        <BorderBtn
                          name={"cancel"}
                          clickHandler={clickHandler}
                        />
                        {
                          validateEmail(updateCompany.company_email) ||
                            validateUEN(updateCompany.license_number) ||
                            validateRequired(updateCompany.company_name) ||
                            validatePhone(updateCompany.contact_number) ||
                            validateRequired(updateCompany.address) ||
                            validateRequired(updateCompany.postal_code) ||
                            validateRequired(updateCompany.city) ||
                            validateRequired(updateCompany.company_representative_first_name) ||
                            updateCompany.company_representative_last_name === "" ||
                            (updateCompany.billing_contact_number !== "" &&
                              validatePhone(updateCompany.billing_contact_number)) ||
                            validateRequired(updateCompany.company_size) ? <>
                            <Linkscroll
                              activeClass="active"
                              to="viewmores"
                              spy={true}
                              smooth={true}
                              offset={50}
                              duration={300}
                            >
                              <YellowBtn
                                clickHandler={handleCompanyUpdateSubmit}
                                name={"confirm"}
                              />
                            </Linkscroll>
                          </> : <>

                            <YellowBtn
                              clickHandler={handleCompanyUpdateSubmit}
                              name={"confirm"}
                            />

                          </>
                        }


                      </div>
                    </form>
                  )}
                </div>
              </TabPanel>
              {updateAccount && updateAccount?.account_manager && (
                <TabPanel value="2" className={classes.p0}>
                  <div className={classes.tabsContent}>
                    <div>
                      <div className={classes.proBox}>
                        <div className={classes.proLeft}>
                          <div className={classes.proImg}>
                            <div className={classes.imgBox}>
                              <img
                                src="./images/profile.svg"
                                alt="Better Coffee"
                              />
                            </div>
                          </div>
                          <div className={classes.proInfo}>
                            <h4>Account Manager</h4>
                            <p>{`Hi, I’m ${updateAccount?.account_manager.full_name}, your dedicated account manager`}</p>
                            <span>
                              “Short introduction, some fun facts about yourself
                              and how you can help the company.”{" "}
                            </span>
                          </div>
                        </div>
                        <div className={classes.socialBox}>
                          <ul>
                            <li>
                              <div className={classes.imgContent}>
                                <img
                                  src="./images/email.svg"
                                  alt="Better Coffee"
                                />
                              </div>
                              <p>{updateAccount?.account_manager.email}</p>
                            </li>
                            <li>
                              <div className={classes.imgContent}>
                                <img
                                  src="./images/phone.svg"
                                  alt="Better Coffee"
                                />
                              </div>
                              <p>
                                {updateAccount?.account_manager.contact_number}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(CompanyProfilePage);

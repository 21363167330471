import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import CustomPopover from "../../component/CustomPopover/CustomPopover";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import StrConstent from "../../common/StrConstent";
import { useDispatch, useSelector } from "react-redux";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
import Loader from "react-spinner-loader";
import TabPanel from "@mui/lab/TabPanel";
import {
  getCategoryList,
  getOutletsList,
  updateSpendPolicy,
  spendPolicyCompanyModes,
} from "../../redux/actions/SpendPolicy/SpendPolicyAction";
import { ActionType } from "../../redux/ActionTypes";
const SpendPolicyUpdate = (props) => {
  const { classes, onToggle } = props;
  const [selectModes, setSelectModes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getOutletsList());
    dispatch(spendPolicyCompanyModes());
  }, []);
  useEffect(() => {
    setSelectedDays([]);
    setSelectedCategory([]);
    setSelectModes([]);
    setInValid(false);
  }, [onToggle]);
  const { categoryList } = useSelector((state) => state?.spendPolicyCategory);
  const { outletsList } = useSelector((state) => state?.spendPolicyOutlets);
  const update = useSelector((state) => state?.updatePolicy?.spendPolicy);
  const { companyModes } = useSelector(
    (state) => state?.getSpendPolicyCompanyModes
  );
  const everyDayOption = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const isAllDaysSelected =
    everyDayOption?.length > 0 &&
    selectedDays?.length === everyDayOption?.length;
  const isAllModeSelected =
    selectModes?.length > 0 &&
    selectModes?.length === companyModes?.data?.modes?.length;
  const handleChange = (event) => {
    const value = event.target.value;
    console.log("value",value)
    if (value[value.length - 1] === "all") {
      setSelectedCategory(
        selectedCategory.length === categoryList.length ? [] : categoryList
      );
      return;
    }
    setSelectedCategory(value);
  };
  const handleChangeDays = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedDays(
        selectedDays?.length === everyDayOption?.length ? [] : everyDayOption
      );
      return;
    }
    setSelectedDays(value);
  };
  const handleChangeModes = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectModes(
        selectModes?.length === companyModes?.data?.modes?.length
          ? []
          : companyModes?.data?.modes
      );
      return;
    }
    setSelectModes(value);
  };
  // const [modeId,setModeId]=useState([]);
  // const modeIdHandler = (selected) => {
  //   let value=modeId;
  //   value.push(selected);
  //   setModeId(value);
  // }
  const [inValid, setInValid] = useState(false);
  const updateSpendPolicyHandler = () => {
    setIsOpen(true);
    if (selectedCategory === "") return setInValid(true);
    else if (selectModes === "") return setInValid(true);
    else if (selectedDays === "") return setInValid(true);
    else if (
      selectedCategory.length > 0 &&
      selectModes.length > 0 &&
      selectedDays.length > 0
    ) {
      // selectModes?.map((element) => modeIdHandler(element?.id));
      const payload = {
        allowed_categories: selectedCategory.toString(),
        allowed_mode: JSON.stringify(selectModes),
        allowed_days: selectedDays.toString(),
      };
      dispatch(updateSpendPolicy(payload));
      setSelectedCategory([]);
      setSelectedDays([]);
      setSelectModes([]);
      // setModeId([]);
      setInValid(false);
    } else setInValid(true);
  };
  // Check box css
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    background: "#E3DDD280",
    marginRight: "5px",
    "input:disabled ~ &": {
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#ffc200",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "url(./images/check.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffc200",
    },
  });
  // Inspired by blueprint's
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }
  const discardChanges = () => {
    setSelectedCategory([]);
    setSelectedDays([]);
    setSelectModes([]);
    setInValid(false);
  };
  return (
    <>
      {update?.message && (
        <AlterMessage
          handleClose={() =>
            dispatch({ type: ActionType.TOPUP_MESSAGE_RESET }, setIsOpen(false))
          }
          description={update?.message}
          title={update?.message ? "Success" : "Error"}
          type={update?.message ? "Success" : "Error"}
          isopen={isOpen}
          url={update?.message ? "./images/success.svg" : "./images/error.svg"}
        />
      )}
      <TabPanel value="2" className={classes.pr0}>
        <div className={classes.formInner}>
          <div className={classes.dFlexTxt}>
            <div className={classes.spendTxt}>
              <h3>Spend parameters </h3>
              {/* <CustomPopover
                // title={StrConstent.SpendPolicy.spendParametersTitle}
                description={StrConstent.SpendPolicy.spendParametersDeliveryDes}
              /> */}
            </div>
            <a
              href="/spend-policy-history"
              style={{ cursor: "pointer" }}
              className={classes.linktxt}
            >
              View Spend Policy History
            </a>
          </div>
          <div className={classes.formControl}>
            <FormLabel>
              Product category that employees can order from
            </FormLabel>
            <div className={classes.customDd}>
              <Select
                labelId="multiple-select-label"
                multiple
                displayEmpty
                value={selectedCategory}
                onChange={handleChange}
                renderValue={(selectedCategory) => {
                  if (selectedCategory.length === 0) {
                    return (
                      <div className={classes.titleTxt}>
                        <span>Select Category</span>
                      </div>
                    );
                  }
                  return selectedCategory.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <BpCheckbox
                      checked={
                        categoryList?.length > 0 &&
                        selectedCategory.length === categoryList?.length
                      }
                      indeterminate={
                        selectedCategory.length > 0 &&
                        selectedCategory.length < categoryList?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    className={classes.titleTxt}
                    primary="Select All"
                  />
                </MenuItem>
                {categoryList?.map((option, index) => (
                  <MenuItem key={index} value={option?.includes("***")?option?.split("*")[0]:option}>
                    <ListItemIcon>
                      <BpCheckbox
                        checked={selectedCategory.indexOf(option?.includes("***")?option?.split("*")[0]:option) > -1}
                      />
                    </ListItemIcon>
                    {option?.includes("***")? <ListItemText primary={option?.split("*")[0]} className={`${classes.ddTxtbold} ${classes.ddTxt}`} style={{fontSize:"10px"}} />: <ListItemText primary={option} className={classes.ddTxt} />}
                    {/* <ListItemText primary={option} className={classes.ddTxt} /> */}
                  </MenuItem>
                ))}
              </Select>
              {inValid && selectedCategory.length === 0 ? (
                <p className="errorMessage">This field is required.</p>
              ) : null}
            </div>
          </div>
          <div className={classes.formControl}>
            <FormLabel> Modes of Collection/Delivery</FormLabel>
            <Select
              labelId="multiple-select-label"
              multiple
              displayEmpty
              value={selectModes}
              onChange={(e) => handleChangeModes(e)}
              renderValue={(selectModes) => {
                if (selectModes?.length === 0) {
                  return (
                    <div className={classes.titleTxt}>
                      <span>Select Modes</span>
                    </div>
                  );
                }
                return selectModes?.length > 0
                  ? selectModes?.map(
                      (element, index) =>
                        `${index === 0 ? "" : ", "}${element?.name}`
                    )
                  : selectModes?.join(", ");
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllModeSelected ? classes.selectedAll : "",
                }}
                className={classes.titleTxt}
              >
                <ListItemIcon className={classes.checkboxCustom}>
                  <BpCheckbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllModeSelected}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.titleTxt}
                  classes={{ primary: classes.selectAllText }}
                  primary="All modes"
                />
              </MenuItem>
              {companyModes?.data?.modes?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  <ListItemIcon className={classes.checkboxCustom}>
                    <BpCheckbox checked={selectModes?.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option?.name} />
                </MenuItem>
              ))}
            </Select>
            {inValid && selectModes.length === 0 ? (
              <p className="errorMessage">This field is required.</p>
            ) : null}
          </div>
          <div className={classes.formControl}>
            <FormLabel> Days of the week for delivery/pick-up</FormLabel>
            <Select
              labelId="multiple-select-label"
              multiple
              displayEmpty
              value={selectedDays}
              onChange={handleChangeDays}
              renderValue={(selectedDays) => {
                if (selectedDays.length === 0) {
                  return (
                    <div className={classes.titleTxt}>
                      <span>Select Days of the week for delivery/pick-up</span>
                    </div>
                  );
                }
                return selectedDays.join(", ");
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllDaysSelected ? classes.selectedAll : "",
                }}
                className={classes.titleTxt}
              >
                <ListItemIcon className={classes.checkboxCustom}>
                  <BpCheckbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllDaysSelected}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.titleTxt}
                  classes={{ primary: classes.selectAllText }}
                  primary="Everyday"
                />
              </MenuItem>
              {everyDayOption?.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon className={classes.checkboxCustom}>
                    <BpCheckbox checked={selectedDays.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
            {inValid && selectedDays.length === 0 ? (
              <p className="errorMessage">This field is required.</p>
            ) : null}
          </div>
        </div>
        <div className={classes.btnContent}>
          <BorderBtn name={"CANCEL"} clickHandler={discardChanges} />
          <YellowBtn name={"CONFIRM"} clickHandler={updateSpendPolicyHandler} />
        </div>
      </TabPanel>
    </>
  );
};
export default withStyles(Styles)(SpendPolicyUpdate);

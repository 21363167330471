export const Styles = (Theme) => ({
  searchBox: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "75%",
    marginRight: "25px",
    background: "#fff",
    borderRadius: "32px",
    overflow: "hidden",
    transition: ".5s ease",
    "@media (max-width: 1024px)": {
      width: "100%",
    },
    "&:hover": {
      transition: ".5s ease",
      borderColor: "rgba(63, 68, 68, 0.12)",
    },
    "&:active": {
      transition: ".5s ease",
      borderColor: "rgba(63, 68, 68, 0.12)",
    },
    "& > img": {
      height: "15px",
      position: "absolute",
      left: "15px",
    },
    "& input": {
      border: "none !important",
      padding: "10px",
      outline: "none",
      width: "auto",
      borderRadius: "0",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3F4444",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
      width: "100%",
      background: "#fff",
      minHeight: "45px",
      borderRight: "2px solid #ddd",
      "&::placeholder": {
        fontSize: "12px",
        lineHeight: "12px",
        color: "#3F444480",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
        // textTransform: "uppercase",
      },
    },
    "& button": {
      position: "absolute",
      right: "0",
      padding: "15px 25px 15px",
      background: "#E3DDD2",
      color: "#3F4444",
      fontSize: "18px",
      lineHeight: "20px",
      fontWeight: "400",
      borderRadius: "32px",
      fontFamily: "'Bebas Neue', serif",
      "&:hover": {
        background: "#E3DDD2",
      },
    },
  },
  datapickerBox: {
    padding: "0 120px 0 50px",
    position: "relative",
    width: "55%",
    minWidth: "300px",
    minHeight: "45px",
    display: "flex",
    alignItems: "center",
    "& fieldset": {
      borderColor: "#fff !important",
      "& span": {
        fontSize: "12px",
        lineHeight: "12px",
        color: "rgba(63, 68, 68, 0.5)",
        fontWeight: "700",
        fontFamily: "'Noto Serif', serif",
        textTransform: "uppercase",
      },
    },
    "& input": {
      borderRadius: "0",
      padding: "0",
      boxSizing: "border-box",
      minHeight: "45px",
      width: "100%",
      fontSize: "18px",
      lineHeight: "18px",
      textAlign: "center",
      minHeight: "45px",
      fontFamily: "'Bebas Neue', serif",
      "&::placeholder": {
        fontSize: "12px",
        lineHeight: "12px",
        color: "#3F444480",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
        textTransform: "uppercase",
        opacity: "1",
      },
    },
    "& label": {
      display: "none",
    },
    "& img": {
      position: "absolute",
      left: "20px",
    },
  },
  borderRight: {
    width: "3px",
    height: "25px",
    position: "absolute",
    left: "0",
    top: "50%",
    background: "#ddd",
    transform: "translateY(-50%)",
    "& span": {
      color: "#3F444480",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "14px",
      position: "inherit",
    },
  },
  dateLabel: {
    color: "#3F444480",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "14px",
    position: "inherit",
  },
  datepicker: {
    display: "flex",
    alignItems: "center",
  },
  innerSearchBox: {
    position: "relative",
    width: "45%",
    "& input": {
      padding: "10px 10px 10px 50px",
    },
    "& img": {
      height: "16px",
      width: "16px",
      position: "absolute",
      zIndex: "99",
      top: "50%",
      left: "20px",
      transform: "translateY(-50%)",
    },
    "& button": {
      background: "transparent !important",
      padding: "10px 5px !important",
      position: "absolute",
      right: "0 !important",
      minWidth: "auto !important",
      "& svg": {
        color: "#3f4444 !important",
      },
    },
  },
});

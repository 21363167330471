import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
const BorderBtn = (props) => {
  const { classes, name, clickHandler } = props;
  const [clear, setClear] = useState();
  const toggler = () => {
    clickHandler(clear);
  };
  useEffect(() => {
    setClear(true);
  }, [clear]);
  return (
    <div className={classes.borderBtn}>
      <Button onClick={toggler}> {name}</Button>
    </div>
  );
};
export default withStyles(Styles)(BorderBtn);

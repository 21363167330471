import { ActionType } from "../../ActionTypes";
import constant from "../../../common/constant";
import axios from "axios";
import { reloadHandler } from "../../../common/utils/utils";

export const getFaqCategoryList = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.FAQS_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/faq/categories`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.FAQS_LIST_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.FAQS_LIST_FAIL, payload: message });
  }
};

export const getFaqListItemCategory =
  (faqUrl) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.FAQS_ITEMS_LIST_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.get(faqUrl, {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      });
      dispatch({ type: ActionType.FAQS_ITEMS_LIST_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ActionType.FAQS_ITEMS_LIST_FAIL, payload: message });
    }
  };

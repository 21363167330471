export const Styles = (theme) => ({
    notFountOuter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    },
    notFountInner: {
        textAlign: "center",
        "& h2": {
            color: "#3F4444",
            fontSize: "44px",
            lineHeight: "44px",
            fontWeight: "400",
            fontFamily: "'Bebas Neue', serif",
            margin: "0 0 15px",
            textAlign: "center",
        },
        "& p": {
            margin: "0 0",
            textAlign: "center",
        },
    },
    returnBtn: {
        padding: "12px 24px",
        background: "#FFC200",
        color: "#3F4444 !important",
        fontSize: "20px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Bebas Neue', serif",
        margin: "15px 0 0",
        letterSpacing: "0",
        whiteSpace: "nowrap",
        borderRadius: "32px",
        textDecoration: "none",
        "&:hover":{
            color: "#3F4444 !important",
        }
    },
});

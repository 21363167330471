const StrConstent = {
  Login: {
    heading: "Login",
  },
  SignUp: {
    heading: "Sign Up",
  },
  Forgot: {
    heading: "Reset Your Password",
  },
  SetPassword: {
    heading: "Set Password",
  },
  Home: {
    headerTitle: "Welcome, ",
    headerDesc:
      "Set spend policies, view invoices, and employee orders via this dashboard.",
  },
  Dashboard: {
    headerTitle: "Home",
    headerDesc:
      "View statistics relating to your company’s spending in this page.",
  },
  SpendPolicy: {
    headerTitle: "Spend policy",
    headerDesc:
      "Set parameters on which products, days, and times of the week that your employees can schedule their delivery or pickup. Go to Spend Policy History to view your current parameters.",
    spendParametersTitle: "Spend parameters",
    spendParametersDeliveryDes:
      "Please adhere to your company's Spend Policy. If you violate any of the terms and conditions, your admin reserves the right to remove your credits.",
    creditAllocationTitle: "Credit allocation",
    creditAllocationDes:
      "Credits can only be allocated to employees who have verified their account.",
  },
  SpendPolicyHistory: {
    headerTitle: "Spend policy history",
    headerDesc: "View all previous updates to your Spend Policy.",
  },
  Employees: {
    headerTitle: "employees",
    headerDesc:
      "Add employees via their email or bulk upload through our CSV template. Employees will receive an email to verify their account. Once the account is verified, you can assign credits to the employees.  ",
    idTitle: "ID",
    idDesc:
      "Enter employee ID. If left blank, the system will automatically assign an unique ID.",
    expiryTitle: "Expiry",
    expiryDesc:
      "Credits expire one year from purchase date. Only the latest expiry date will be displayed for credits bought on different dates.",
  },
  AddEmployeesForm: {
    headerTitle: "Add employees manually",
    headerDesc:
      "Enter full name and email address. Employee ID will be automatically generated if left blank.",
  },
  EditEmployeesForm: {
    headerTitle: "EDIT EMPLOYEE",
    headerDesc:
      "Change employee details here.",
  },
  AddEmployeesVia: {
    headerTitle: "Add employees via csv",
  },
  Credits: {
    headerTitle: "Credits",
    headerDesc:
      "Purchase credits here before proceeding to Spend Policy for credit allocation and spend parameters.",
    creditAmountCheck: "A minimum of $10 credits purchase is required.",
    creditMaxAmountCheck: "The credit amount should be smaller than $500",
  },
  CreditHistory: {
    headerTitle: "Credit top-up history",
    headerDesc:
      "View or download the invoices to see your credits top-up history.",
  },
  CreditTopUp: {
    headerTitle: "Credit top-up",
    headerDesc:
      "We will get in touch with you for payment after receiving your top-up request.",
  },
  CreditTopUpForm: {
    headerTitle: "Credit top-up",
    headerDesc1:"We will get in touch with you for payment after receiving your top-up request.",
    headerDesc:
      "Receive your credits immediately once the transaction is complete.",
  },
  CompanyProfile: {
    headerTitle: "company profile",
    headerDesc: "Access and update all company, payment, and contact details.",
  },
  Transaction: {
    headerTitle: "transactions",
    headerDesc: "View details of all completed orders here.",
  },
  TransactionsOrderDetail: {
    headerTitle: "order details",
    headerDesc: "View the breakdown of an employee’s order in this page.",
  },

  Setting: {
    headerTitle: "Settings",
    headerDesc: "Change your settings and password here.",
  },
  // Setting:{
  //     headerTitle: "Setting",
  //     headerDesc: "You can change the settings, password, contact information for this account.",
  // },
  Faqs: {
    headerTitle: "FREQUENTLY ASKED QUESTIONS",
    headerDesc: "Still got questions? Get in touch with us ",
  },
  MainHeading: {
    heading: "BETTR FOR BUSINESS",
    description:
      "  Manage employee benefits, define spend policies, and view engagement metrics at your convenience.",
  },
  Message: {
    Success: {
      type: "Success",
      url: "./images/success.svg",
      title: "Success",
      description:
        "Your request has been submitted, an email will be sent to you shortly.",
    },
    Info: {
      type: "Info",
      url: "./images/Info.svg",
      title: "Info",
      description:
        "Your request has been submitted, an email will be sent to you shortly.",
    },
    Error: {
      type: "Error",
      url: "./images/error.svg",
      title: "Issue/unsuccessful",
      description: "Your request has not been submitted due to... ",
    },
  },
  passwordGuideLine: {
    propoverTitle: "Password has to:",
    propoverDescription1: "Be at least 8 characters ",
    propoverDescription2:
      "Include at least one uppercase and one lowercase letter",
    propoverDescription3: "Include number(s)",
    propoverDescription4: "Include special characters (eg. ~!@$%^&*()_+|)",
  },
};

export default StrConstent;

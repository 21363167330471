import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
const CustomPopover = (props) => {
  const { classes, title, description } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <div
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.customTooltip}
      >
        <img src="./images/question.svg" alt="Better cloud" />
      </div>
      <Popover
        id="mouse-over-popover"
        modalprops
        disableEnforceFocus={false}
        className={classes.tooltipBox}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.tooltiptext}>
          <b>{title}</b>
          <span>{description}</span>
        </Typography>
      </Popover>
    </>
  );
};

export default withStyles(Styles)(CustomPopover);

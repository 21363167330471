import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { convertDDMMYYYDate } from "../../common/utils/utils";
function RowTable({ rowData, count, classes }) {
  const [open, setOpen] = useState(false);
  const selectionHandler = (orderId) => {
    localStorage.setItem("orderId", orderId);
  };

  const htmlString =
    rowData?.address?.address_tag +
    "<br/>" +
    rowData?.address?.address_line1 +
    " " +
    rowData?.address?.address_line2 +
    " " +
    rowData?.address?.unit_number +
    " " +
    rowData?.address?.postal_code;
  const theObj = { __html: htmlString };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell scope="row">{rowData.order_number}</TableCell>
        <TableCell>{`${rowData.user.first_name} ${rowData.user.last_name}`}</TableCell>
        <TableCell>{convertDDMMYYYDate(rowData.order_date)}</TableCell>
        <TableCell>{rowData.order_time_slot}</TableCell>

        {rowData?.order_mode === "Pick-Up" ? (
          <TableCell>{rowData?.outlet_name}</TableCell>
        ) : (
          <TableCell dangerouslySetInnerHTML={theObj}></TableCell>
        )}

        <TableCell>{rowData.grand_total}</TableCell>
        <TableCell className={classes.repRemove}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <div className={classes.actionBox}>
                <Tooltip title="View details" arrow toolTipcontent>
                  <Button>
                    <img src="./images/uparrow.svg" alt="Better coffee" />
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <div className={classes.actionBox}>
                <Tooltip title="View details" arrow toolTipcontent>
                  <Button>
                    <img src="./images/downarrow.svg" alt="Better coffee" />
                  </Button>
                </Tooltip>
              </div>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.expandBox} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.transTableeOuter}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className={classes.heading}>
                        <h4>Total bill: {rowData.grand_total} credits</h4>
                        <a
                          href="/order-detail"
                          onClick={() => selectionHandler(rowData.order_id)}
                        >
                          More Details
                        </a>
                      </div>
                    </TableCell>
                  </TableRow>
                  {rowData?.items.map((childData, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.historyText}>
                        <div className={classes.creditsBox}>
                          <div className={classes.ltBox}>
                            <div className={classes.imgBox}>
                              <img
                                src={childData.image_url}
                                alt="Better coffee"
                              />
                            </div>
                            <div className={classes.txtBox}>
                              <span>{childData.item_name}</span>
                              <span>
                                {childData.modifiers.map((modifier, cindex) => {
                                  return (
                                    (cindex === 0 ? " " : ", ") +
                                    modifier?.modifier_name
                                  );
                                })}
                                {/* Regular size (+2 credits), heart blend, regular
                                milk, regular shot */}
                              </span>
                              <span>Qty: {childData.item_quantity}</span>
                            </div>
                          </div>
                          <div className={classes.rtBox}>
                            <span>{childData.unit_price} Credits</span>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
export default withStyles(Styles)(RowTable);

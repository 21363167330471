import { ActionType } from "../../ActionTypes";

export const spendPolicyHistoryListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SPEND_POLICY_HISTORY_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.SPEND_POLICY_HISTORY_LIST_SUCCESS:
      return { ...state, loading: false, spendPolicyHistory: action.payload };
    case ActionType.SPEND_POLICY_HISTORY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePolicyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SPEND_POLICY_UPDATE_REQUEST:
      return { loading: true };
    case ActionType.SPEND_POLICY_UPDATE_SUCCESS:
      return { loading: false, success: true, spendPolicy: action.payload };
    case ActionType.SPEND_POLICY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const getCategoryListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SPEND_POLICY_CATEGORY_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.SPEND_POLICY_CATEGORY_LIST_SUCCESS:
      let CategoryItem = action.payload;
      let newCatedata = CategoryItem.data.categories.map((item) => {
        const addvalue="***"
        return item.parent_category_id==null? item.category_name+addvalue:item.category_name;
      });
      return { ...state, loading: false, categoryList: newCatedata };
    case ActionType.SPEND_POLICY_CATEGORY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getOutletsListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SPEND_POLICY_OUTLETS_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.SPEND_POLICY_OUTLETS_LIST_SUCCESS:
      let outletsItem = action.payload;
      let newOutLetsdata = outletsItem.data.outlets.map((item) => {
        return item.outlet_name;
      });
      return { ...state, loading: false, outletsList: newOutLetsdata };
    case ActionType.SPEND_POLICY_OUTLETS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateCreditAllocationReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.UPDATE_CREDIT_ALLOCATION_REQUEST:
      return { loading: true };
    case ActionType.UPDATE_CREDIT_ALLOCATION_SUCCESS:
      return { loading: false, updateCredit: action.payload, success: true };
    case ActionType.UPDATE_CREDIT_ALLOCATION_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const spendPolicyCreditAllocationHistory = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SP_CREDIT_ALLOCATION_HISTORY_REQUEST:
      return { loading: true };
    case ActionType.SP_CREDIT_ALLOCATION_HISTORY_SUCCESS:
      return { loading: false, spendPolicyCreditAllocation: action.payload, success: true };
    case ActionType.SP_CREDIT_ALLOCATION_HISTORY_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const spendPolicyCompanyModes = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SP_COMPANY_MODES_REQUEST:
      return { loading: true };
    case ActionType.SP_COMPANY_MODES_SUCCESS:
      return { loading: false, companyModes: action.payload, success: true };
    case ActionType.SP_COMPANY_MODES_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
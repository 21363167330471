export const Styles = (theme) => ({
  h1: {
    marginTop: "10",
    textTransform: "uppercase",
    fontSize: "44px",
    lineHeight: "48px",
    color: "#111827",
    fontWeight: "500",
    fontFamily: "'Bebas Neue', serif",
  },
  logoBettr: {
    width: "90px",
    marginTop: "25px",
  },
  formControl: {
    margin: "30px 0",
  },
  form: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    display: "inline-block",
    justifyContent: "left",
    flexDirection: "column",
    padding: "2rem",
    height: "auto",
    borderRadius: "12px",
    width: "100%",
    boxSizing: " border-box",
    overflowY: "auto",
    margin: "0 !important",
    maxHeight: "calc(100vh - 100px)",
    "&::-webkit-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-moz-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-o-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-ms-scrollbar": {
      appearance: "none",
      width: "0",
    },
  },
  yellowBtn: {
    width: "100%",
    "& button": {
      padding: "12px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "0 0 0 0px",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      width: "100%",
      "&:hover": {
        background: "#FFC200",
      },
    },
  },
  passwordBox: {
    position: "relative",
    "& svg": {
      position: "absolute",
      right: "10px",
      top: "50%",
      color: "#3F444480",
      height: "20px",
      width: "25px",
      cursor: "pointer",
      transform: "translate(-50%, -50%)",
    },
    "& input":{
        paddingRight:"55px",
      }
  },
});

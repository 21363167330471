export const Styles = Theme => ({
    customTooltip: {
        position: "relative",
        display: "flex",
        fontWeight: "700 !important",
        marginLeft: "5px",
        width:"12px",
        height:"12px",
        "& img": {
          height:"100%",
          width:"100%",
        },
    },
    tooltipBox: {
        "& div:nth-child(2)": {
            overflowY: "unset",
            overflowX: "unset",
        }
    },
    tooltiptext: {
        background: "#fff",
        padding: "12px",
        borderRadius: "8px",
        position: "relative",
        maxWidth: "250px",
        overflowY: "unset",
        overflowX: "unset",
        "&:after": {
            content: "''",
            position: "absolute",
            top: "100%",
            left: "10%",
            marginLeft: "-5px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "#fff transparent transparent transparent",
        },
        "& b": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444",
            display: "block",
        },
        "& span": {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444B2",
            display: "block",
            padding: "5px 0 0",
        },
    },
})
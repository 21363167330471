import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Styles";
import LoginForm from "../../component/Login/LoginForm";
import StrConstent from "../../common/StrConstent";
function LoginPage(props) {

  const { classes } = props;
  return (
    <div className={classes.root}>
      {/* <div className={classes.bgColor}> */}
        <div className={classes.container} id="container">
          <div className={classes.overlaycontainer}>
            <div className={`${classes.overlaypanel} ${classes.overlayright}`}>
              <h1>{StrConstent.MainHeading.heading} </h1>
              <p>{StrConstent.MainHeading.description}</p>
            </div>
          </div>
          <div className={`${classes.formcontainer} ${classes.logincontainer}`}>
            <LoginForm />
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default withStyles(Styles)(LoginPage);

export const Styles = Theme => ({
    tableLayout:{
        tableLayout:"fixed",
    },
    empOuter: {
        padding: "20px 25px",
    },
    topBox1: {
        display: "flex",
        alignItems: "flex-start",
        padding: "0 0 40px",
        justifyContent: "space-between",
    },
    dFlex: {
        display: "flex",
    },
    actionBox: {
        "& button": {
            background: "transparent",
            padding: "0",
            minWidth: "auto",
            marginRight: "5px",
        },
    },
    statusBtn: {
        "& button": {
            padding: "6px 8px",
            color: "#3F4444",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            textTransform: "uppercase",
            borderRadius: "4px",
        },
    },
    toolTipcontent: {
        bacakgroundColor: "#fff",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
    },
    // tabs design
    pr0: {
        padding: "30px 0 !important",
    },
    tabsList: {
        width: "auto",
        background: "#f1eedb",
        borderRadius: "32px",
        display: "table !important",
        "&  button": {
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: "400",
            color: "#3F4444",
            fontFamily: "'Bebas Neue', serif",
            textTransform: "uppercase",
            padding: "12px 24px",
            textAlign: "center",
            minWidth: "150px",
            "@media (max-width: 900px)": {
                fontSize: "20px",
                lineHeight: "20px",
            },
            "&[aria-selected=true]": {
                color: "#3F4444",
                background: "#ffc200",
                borderRadius: "32px",
            },
        },
    },
    tabsBox: {
        marginTop: "20px",
    },
    tabsInner: {
        "& > div > div": {
            borderRadius: "30px",
            overflow: "hidden",
            display: "table",
            backgroundColor: "#F1EEDB",
        },
    },
    tabsContent: {
        padding: "25px 0",
        borderRadius: "0 20px 20px 20px",
        display: "table",
        width: "100%",
        height: "100%",
    },
    noData: {
        height: "100%",
        minHeight: "450px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "750px",
        margin: "auto",
        padding: "0 20px",
        "@media(max-width:991px)": {
            minHeight: "250px",
        },
        "& h2": {
            fontSize: "44px",
            lineHeight: "48px",
            fontWeight: "400",
            marginBottom: "0",
            "@media(max-width:991px)": {
                fontSize: "35px",
                lineHeight: "40px",
            },
        },
        "& p": {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "400",
            color: "#3F444480",
            wordBreak: "break-word",
            textAlign:"center",
        }
    },
    width200:{
        minWidth:"300px",
    }
})
import { useCountdown } from "../Hooks/useCountDown";
import { ShowCounter } from "./ShowCounter";
export const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (minutes + seconds <= 0) {
    return;
  } else {
    return (
      <ShowCounter
        // days={days}
        // hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export const Styles = Theme => ({
    empOuter: {
        padding: "20px 25px",
    },
    topBox1: {
        display: "flex",
        alignItems: "center",
        padding: "0 0 40px",
        justifyContent:"space-between",
    },
})
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PolarArea from "../../component/Chart/PolarArea";
import LineChart from "../../component/Chart/LineChart";
import Calander from "../../component/Calander/Calander";
import UserFrequency from "../../component/Chart/UserFrequency";
import CreditsPurchased from "../../component/CreditsPurchased/CreditsPurchased";
import ChartSpendingProducts from "../../component/Chart/ChartSpendingProducts";
import { getCreditUsageAction } from "../../redux/actions/Dashboard/DashboardAction";

const DashboardContentBox = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState(null);
  const [stateDate, setStateDate] = useState(null);
  const endHandler = (endDate) => setEndDate(endDate);
  const data = useSelector((state) => state?.creditUsage);
  const startHandler = (stateDate) => setStateDate(stateDate);
  useEffect(() => {
    if (stateDate && endDate)
      return dispatch(getCreditUsageAction(stateDate, endDate));
  }, [stateDate, endDate]);

  useEffect(() => {
    dispatch(getCreditUsageAction());
  }, []);
  return (
    <>
      <div className={classes.dasboradOuter}>
        <div className={classes.dasTop}>
          <div className={classes.lt}>
            <h3>All Statistics</h3>
          </div>
          <div className={classes.Rt}>
            <Calander start={startHandler} end={endHandler} />
          </div>
        </div>
        <div className={classes.dFlex}>
          <div className={classes.ltBox}>
            <div className={classes.dFlex}>
              <div className={classes.lt}>
                <h3>CREDITS Usage</h3>
              </div>
              <div className={classes.Rt}>
                <CreditsPurchased />
              </div>
            </div>
            {<LineChart graphData={data} />}
          </div>
          <div className={classes.RtBox}>
            <h3>user frequency</h3>
            <UserFrequency stateDate={stateDate} endDate={endDate} />
          </div>
        </div>
        <div className={classes.dFlex}>
          <div className={classes.ltBox}>
            <h3 className={classes.minHeight}>Top 5 spending products (F&B)</h3>
            <ChartSpendingProducts stateDate={stateDate} endDate={endDate} />
          </div>
          <div className={classes.RtBox}>
            <h3>Delivery/ Pick-up</h3>
            <PolarArea stateDate={stateDate} endDate={endDate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(Styles)(DashboardContentBox);

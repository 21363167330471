import { Styles } from "./Style";
import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
const SearchBox = (props) => {
  const {
    classes,
    styleProps,
    placeholderText,
    onTypingEvent,
    handleSubmitSearch,
    onCancel = false,
  } = props;
  const [closeIcon, setCloseIcon] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (value) => {
    onTypingEvent(value);
    setValue(value);
    if (value !== "") {
      setCloseIcon(true);
      onCancel(false);
    } else {
      handleSubmitSearch();
      setCloseIcon(false);
      onCancel(true);
    }
  };
  const enterHandler = (event) => {
    if (event?.which === 13) {
      handleSubmitSearch();
    }
  };

  return (
    <div className={classes.searchBox} style={styleProps}>
      <img src="./images/search.svg" alt="Better cloud" />
      <input
        name=""
        type="text"
        value={value}
        onKeyDown={enterHandler}
        placeholder={placeholderText}
        onChange={(e) => handleChange(e.target.value)}
      />
      {closeIcon && (
        <Button
          disableRipple
          className="closeIcon"
          onClick={() => handleChange("")}
        >
          <CloseIcon />
        </Button>
      )}
      <Button className={classes.searchBtn} onClick={handleSubmitSearch}>
        Search
      </Button>
    </div>
  );
};
export default withStyles(Styles)(SearchBox);

export const Styles = (theme) => ({
  h1: {
    marginTop: "10",
    textTransform: "uppercase",
    fontSize: "44px",
    lineHeight: "48px",
    color: "#111827",
    fontWeight: "500",
    fontFamily: "'Bebas Neue', serif",
    "@media (max-width: 991px)": {
      fontSize: "35px",
      lineHeight: "35px",
      margin: "15px 0",
    },
  },

  logo: {
    maxWidth: "90px",
    margin: "0 0 30px",
  },
  formControl: {
    margin: "25px 0",
    "& button": {
      padding: "0",
    }
  },
  form: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    display: "inline-block",
    justifyContent: "left",
    flexDirection: "column",
    padding: "2rem",
    height: "auto",
    borderRadius: "12px",
    width: "100%",
    boxSizing: " border-box",
    overflowY: "auto",
    margin: "0 !important",
    maxHeight: "calc(100vh - 100px)",
    "&::-webkit-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-moz-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-o-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-ms-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "& input": {
      padding: "12px 20px !important",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      outline: "none",
      border: "1px solid #D5D4DC",
      borderRadius: "4px",
      backgroundColor: "#fff !important",
      width: "100%",
      color: "#3F4444",
      boxSizing: "border-box",
      height: "45px",
      fontFamily: "'Noto Serif', serif",
      borderRadius: "32px",
      border: "none !important",
      "&:focus": {
        backgroundColor: "#fff!important",
      },
      "&:active": {
        backgroundColor: "#fff!important",
      },
      "&:hover": {
        backgroundColor: "#fff!important",
      },
    },
  },
  textCheckbox: {
    color: "#111827 !important",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    fontFamily: "'Noto Serif', serif",
    cursor: "pointer",
  },
  textForgot: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "14px",
    cursor: "pointer",
  },
  rememberMe: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& label": {
      display: "flex !important",
      alignItems: 'center',
    }
  },
  passwordBox: {
    position: "relative",
    "& input": {
      paddingRight: "50px !important",
    },
    "& svg": {
      position: "absolute",
      right: "10px",
      top: "50%",
      color: "#3F444480",
      height: "20px",
      width: "25px",
      cursor: "pointer",
      transform: "translate(-50%, -50%)",
      
    }
  },
  errorPasswordBox: {
    position: "relative",
    "& input": {
      paddingRight: "50px !important",
    },
    "& svg": {
      position: "absolute",
      right: "10px",
      top: "50%",
      height: "20px",
      width: "25px",
      cursor: "pointer",
      color: "#3F444480",
      transform: "translate(-50%, -50%)",
    }
  },
  donTxt: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0 0",
    "& p": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "400",
      margin: "0",
      fontFamily: "'Noto Serif', serif",
      paddingRight: "5px",
    },
    "& a": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "700",
      fontFamily: "'Noto Serif', serif",
      textDecoration: "underline !important",
    }
  },
  yellowBtn: {
    width: "100%",
    "& button": {
      padding: "12px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "0",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      width: "100%",
      "&:hover": {
        background: "#FFC200",
      },
    },
  },
  btnBox: {
    marginTop: "25px",
  },
  errorMessage: {
    fontSize: "14px",
    lineHeight: "19px",
    color: "#DF3F51",
    fontWeight: "400",
    fontFamily: "'Noto Serif', serif",
    margin: "5px 10px 0",
    "& a": {
      textDecorationColor: "#DF3F51 !important",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Noto Serif', serif",
      display: 'inline-block',
      cursor: "pointer",
      color: "#DF3F51 !important",
    }
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& img": {
      height: "15px",
      margin: "0 0 0 10px",
    }
  }
});

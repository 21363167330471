export const Styles = Theme => ({
    dasTop: {
        display: "flex",
        alignItems: "center",
        background: "#00000005",
        borderRadius: "10px",
        padding: "10px 20px",
        marginBottom: "30px",
    },
    dFlex: {
        display: "flex",
    },
    box: {
        padding: "15px 20px",
        background: "#00000005",
        borderRadius: "10px",
        marginBottom: "30px",
    },
    lt: {
        width: "30%",
        "& h3": {
            marginBottom: "0",
        }
    },
    Rt: {
        width: "70%",
        paddingLeft: "10px"
    },
    ltBox: {
        width: "50%",
        padding: "15px 20px",
        background: "#fff",
        borderRadius: "10px",
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    RtBox: {
        width: "50%",
        marginLeft: "30px",
        padding: "15px 20px",
        background: "#fff",
        borderRadius: "10px",
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
})
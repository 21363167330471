import React, { useEffect, useMemo, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { styled } from "@mui/material/styles";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import SearchBox from "../../component/SearchBox/SearchBox";
import DrawerRight from "../../component/DrawerRight/DrawerRight";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paginator from "../../component/Paginator/Paginator";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import CustomPopover from "../../component/CustomPopover/CustomPopover";
import StrConstent from "../../common/StrConstent";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-spinner-loader";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  DeleteEmployee,
  getEmployeeList,
  updateSingleEmployeeBalance,
} from "../../redux/actions/Employees/EmployeesAction";
import DrawerRightEditForm from "../../component/DrawerRightCredits/DrawerRightEditForm";
import { ActionType } from "../../redux/ActionTypes";
import ModalBox from "../../component/Modal/ModalBox";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { displayAmountFormat, encryptValue } from "../../common/utils/utils";
import constant from "../../common/constant";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
const Employees = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [eid, setEId] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [openModel, setOpen] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [onCancel, setOnCancel] = useState(false);
  const [filterValue, setFilterValue] = useState();
  const company_id = localStorage.getItem("company_id");
  const [refreshWallet, setRefreshWallet] = useState(false);
  const open = Boolean(anchorEl);
  const success = useSelector(
    (state) => state?.updateEmpBalance?.updateBalMess
  );
  const balError = useSelector((state) => state?.updateEmpBalance?.error);
  const addViaCsvError = useSelector((state) => state?.employeeImport?.error);
  const addViaCsvSuccess = useSelector(
    (state) => state?.employeeImport?.updateMess?.message
  );
  const {
    employees: { data },
    loading,
  } = useSelector((state) => state.employeeData);
  const { addMess, error, scusses } = useSelector((state) => state.addEmpMsg);
  const {
    deleteMess,
    error: deleteError,
    scusses: deleteSuccess,
  } = useSelector((state) => state.deleteEmpMsg);
  const {
    updateMess,
    error: updateError,
    scusses: updateSuccess,
  } = useSelector((state) => state.updateEmpMsg);
  const renderCellByStatus = (status) => {
    if (status === 0) {
      return (
        <Button
          style={{
            backgroundColor: "#F5CFB0",
            padding: "6px 8px",
            color: "#3F4444",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            textTransform: "uppercase",
            borderRadius: "20px",
          }}
        >
          Pending
        </Button>
      );
    } else if (status === 1) {
      return (
        <Button
          style={{
            backgroundColor: "#3BD4AE4D",
            padding: "6px 8px",
            color: "#3F4444",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            textTransform: "uppercase",
            borderRadius: "20px",
          }}
        >
          Verified
        </Button>
      );
    } else if (status === 2) {
      return (
        <Button
          style={{
            backgroundColor: "#BFC6CD4D",
            padding: "6px 8px",
            color: "#3F4444",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            textTransform: "uppercase",
            borderRadius: "20px",
          }}
        >
          Archived
        </Button>
      );
    }
  };
  useEffect(() => {
    if (
      scusses ||
      deleteSuccess ||
      deleteError ||
      error ||
      updateSuccess ||
      updateError ||
      addViaCsvError ||
      addViaCsvSuccess ||
      success ||
      balError
    ) {
      setIsOpen(true);
      // if (!error) {
      //   setTimeout(() => {
      //     setIsOpen(false);
      //     dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
      //   }, 4000);
      // }
    }
  }, [
    dispatch,
    scusses,
    deleteSuccess,
    deleteError,
    error,
    updateSuccess,
    updateError,
    addViaCsvError,
    addViaCsvSuccess,
    success,
    balError,
  ]);

  const [filterBy, setFilyerBy] = useState({
    Verified: false,
    Pending: false,
    Archived: false,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { Verified, Pending, Archived } = filterBy;

  // useEffect(() => {
  //   if (searchVal?.length > 3) return dispatch(getEmployeeList(getURLParam()));
  // }, [searchVal]);
const [editupdate,seteditupdate]=useState('')
const [editupdateisopen,setedutupdateisopen]=useState(false)
  useEffect(()=>{
if(updateMess?.message ){
  setedutupdateisopenerror(false)
  seteditupdate(updateMess?.message)
  setedutupdateisopen(true)
  dispatch({ type: ActionType.TOPUP_MESSAGE_RESET })
}
  },[updateMess])

  const [editupdateerror,seteditupdateerror]=useState('')
  const [editupdateisopenerror,setedutupdateisopenerror]=useState(false)

  useEffect(()=>{
if(updateError){
  seteditupdateerror(updateError)
  setedutupdateisopenerror(true)
  setedutupdateisopen(false)
  dispatch({ type: ActionType.TOPUP_MESSAGE_RESET })
}
  },[updateError])
  const getURLParam = () => {
    let url = `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee?page=${page}`;
    url += filterValue ? `&filter_by=${filterValue}` : "";
    url += `&search_by=${searchVal}`;
    return url;
  };

  const handleChange = (event) => {
    setFilyerBy({ [event.target.name]: event.target.checked });
    setFilterValue(event.target.checked ? event.target.value : "");
    setAnchorEl(null);
  };

  const ItemTypingHandler = (searchText) =>
    setSearchVal(searchText?.replace("+", "^^"));

  const handleSubmitSearch = () => {
    dispatch(getEmployeeList(getURLParam()));
  };

  useEffect(() => {
    dispatch(getEmployeeList(getURLParam()));
  }, [filterValue, page]);

  const handleCloseDelete = () => {
    setOpen(false);
    setEId(null);
  };

  const deleteEmpHandler = (id) => {
    setEId(id);
    setOpen(true);
    if (openModel) {
      dispatch(
        DeleteEmployee(id),
        setRefreshWallet(true),
        getEmployeeList(getURLParam())
      );
      setOpen(false);
      setEId(null);
    }
  };

  const EmpAdded = (status) => {
    if (status == "pending") {
      // dispatch(getEmployeeDataList());
      dispatch(getEmployeeList(getURLParam()));
    }
  };

  const handleChangeValuePage = (event, value) => setPage(value);

  const [isUpdated, setIsUpdated] = useState("");
  const [dataList, setDataList] = useState();
  const [pagination, setPagination] = useState();

  useEffect(() => {
    setDataList(data?.employees);
    setPagination(data?.pagination);
  }, [data]);

  const onOpen = (isOpen) => {
    console.log("isOpen", isOpen);
  };

  // Check box css
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    background: "#E3DDD280",
    marginRight: "5px",
    // 'input:hover ~ &': {
    //   backgroundColor: theme.palette.mode === '#ffc200' ? '#30404d' : '#ebf1f5',
    // },
    "input:disabled ~ &": {
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  useEffect(()=>{
if(success?.success==true){
  setPage(1)
}
  },[success])
console.log("success",success)
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#ffc200",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "url(./images/check.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffc200",
    },
  });
  const isFloat = (n) => {
    return !!(n % 1);
  };

  const inputHandler = (event, pos) => {
    const { name, value } = event.target;
    let rowData = dataList[pos];
    if (value.length < 10) {
      let setVal = value;
      if (isFloat(value)) {
        const floatValue = value.split(".");
        if (floatValue[1].length > 1) {
          setVal = parseFloat(value).toFixed(1);
        }
      }
      rowData[name] = setVal;
      setDataList([...dataList]);
    }
  };
  const saveBalanceUpdated = (id, balance) => {
    let payload = {
      wallet_balance: encryptValue(balance),
    };
    dispatch(updateSingleEmployeeBalance(id, payload));
    setIsOpen(true);
  };

  // Inspired by blueprintjs
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }
  // const togglerHandler = (toggle) => {};
  const cancelHandler = (isCancel) => {
    setOnCancel(isCancel);
    if (isCancel) {
      setSearchVal("");
    } else console.log("isCancel:", isCancel);
  };

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway")
    dispatch({ type: ActionType.TOPUP_MESSAGE_RESET }, setIsOpen(false));
  };
  useMemo(() => {
    if (onCancel && searchVal == "") {
      dispatch(getEmployeeList(getURLParam()));
    }
  }, [onCancel]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (data?.employees.length === 0) return setAddMore(true);
  }, [data]);

  useEffect(() => {
    dispatch(getEmployeeList(getURLParam()));
  }, []);
  return (
    <>
      {loading && page === 1 && (
        <Loader
          Style={{ zIndex: "9999" }}
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {(scusses || error) && (
        <AlterMessage
          handleClose={snackHandler}
          description={addMess?.message || error}
          title={error ? "Error" : "Success"}
          type={error ? "Error" : "Success"}
          isopen={isOpen}
          url={error ? "./images/error.svg" : "./images/success.svg"}
        />
      )}
      {/* {(updateSuccess || updateError) && (
        <AlterMessage
          handleClose={snackHandler}
          description={updateMess?.message || updateError}
          title={updateMess?.message ? "Success" : "Error"}
          type={updateMess?.message ? "Success" : "Error"}
          isopen={isOpen}
          url={
            updateMess?.message ? "./images/success.svg" : "./images/error.svg"
          }
        />
      )} */}
      {(editupdateisopen) && (
        <AlterMessage
          handleClose={snackHandler}
          description={editupdate}
          title={"Success"}
          type={"Success"}
          isopen={isOpen}
          url={
           "./images/success.svg" 
          }
        />
      )}


{(editupdateisopenerror) && (
        <AlterMessage
          handleClose={snackHandler}
          description={editupdateerror}
          title={"Success"}
          type={"Success"}
          isopen={editupdateisopenerror}
          url={
           "./images/success.svg" 
          }
        />
      )}
      {deleteError && (
        <AlterMessage
          handleClose={snackHandler}
          description={deleteMess?.message}
          title="Error"
          type="Error"
          isopen={isOpen}
          url="./images/error.svg"
        />
      )}
      {deleteSuccess && (
        <AlterMessage
          handleClose={snackHandler}
          description={deleteMess?.message}
          title="Success"
          type="Success"
          isopen={isOpen}
          url="./images/success.svg"
        />
      )}
      {(success?.success || balError) && (
        <AlterMessage
          handleClose={snackHandler}
          description={success?.message || balError}
          title={success?.success ? "Success" : "Error"}
          type={success?.success ? "Success" : "Error"}
          isopen={isOpen}
          url={success?.success ? "./images/success.svg" : "./images/error.svg"}
        />
      )}
      {(addViaCsvError || addViaCsvSuccess) && (
        <AlterMessage
          handleClose={snackHandler}
          description={addViaCsvSuccess ? addViaCsvSuccess : addViaCsvError}
          title={addViaCsvSuccess ? "Success" : "Error"}
          type={addViaCsvSuccess ? "Success" : "Error"}
          isopen={isOpen}
          url={addViaCsvSuccess ? "./images/success.svg" : "./images/error.svg"}
        />
      )}
      <div className={classes.empOuter}>
        <div className={classes.topBox1}>
          <Heading
            heading={StrConstent.Employees.headerTitle}
            description={StrConstent.Employees.headerDesc}
          />
          <CreditsInfo refreshWallet={refreshWallet} />
        </div>
        <div className={classes.topBox2}>
          <SearchBox
            // placeholderText="Employee name, ID, email"
            placeholderText="Search Name, Email or ID"
            handleSubmitSearch={handleSubmitSearch}
            onTypingEvent={ItemTypingHandler}
            onCancel={cancelHandler}
          />
          <DrawerRight
            // parentCallback={togglerHandler}
            onOpen={onOpen}
            isCSV={true}
          />
        </div>
        <div className={classes.tableOuter}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" className={classes.tableLayout}>
                <TableHead>
                  <TableRow>
                    <TableCell> Name </TableCell>
                    <TableCell className={classes.wid180px}> Email </TableCell>
                    <TableCell>
                      #ID
                      <div className={classes.inlineBlock}>
                        <CustomPopover
                          title={StrConstent.Employees.idTitle}
                          description={StrConstent.Employees.idDesc}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.statusDb}>
                        <Button
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : null}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : null}
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          disableRipple
                          disableFocusRipple
                        >
                          Status{" "}
                          <img
                            src="./images/down-arrow.svg"
                            alt="Better Coffee"
                          />
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          className={classes.dbContent}
                        >
                          <p>Filter by:</p>
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <BpCheckbox
                                  {...label}
                                  onChange={handleChange}
                                  checked={Verified}
                                  name="Verified"
                                  value="1"
                                />
                              }
                              label="Verified"
                            />
                            {/* <BpCheckbox
                              {...label}
                              onChange={handleChange}
                              checked={Verified}
                              name="Verified"
                              value="1"
                              color="default"
                            />
                            Verified */}
                          </MenuItem>
                          <MenuItem>
                            {/* <BpCheckbox
                              {...label}
                              onChange={handleChange}
                              checked={Pending}
                              name="Pending"
                              value="0"
                              color="default"
                            /> */}
                            <FormControlLabel
                              control={
                                <BpCheckbox
                                  {...label}
                                  onChange={handleChange}
                                  checked={Pending}
                                  name="Pending"
                                  value="0"
                                />
                              }
                              label="Pending"
                            />
                          </MenuItem>
                          <MenuItem>
                            {/* <BpCheckbox
                              {...label}
                              onChange={handleChange}
                              checked={Archived}
                              name="Archived"
                              value="2"
                              color="default"
                            />
                            Archived */}
                            <FormControlLabel
                              control={
                                <BpCheckbox
                                  {...label}
                                  onChange={handleChange}
                                  checked={Archived}
                                  name="Archived"
                                  value="2"
                                />
                              }
                              label="Archived"
                            />
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                    <TableCell> Allocated </TableCell>
                    <TableCell> Balance (c) </TableCell>
                    <TableCell>
                      <div className={classes.dFlex}>
                        Expiry
                        <CustomPopover
                          title={StrConstent.Employees.expiryTitle}
                          description={StrConstent.Employees.expiryDesc}
                        />
                        <div className={classes.imgBox}>
                          <Link to="">
                            <img
                              src="./images/Filter.svg"
                              alt="Better Coffee"
                            />
                          </Link>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell> Actions </TableCell>
                  </TableRow>
                </TableHead>
                {data?.employees.length > 0 ? (
                  <TableBody>
                    {dataList &&
                      dataList?.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {data.first_name + " " + data.last_name}
                          </TableCell>
                          <TableCell>{data.email}</TableCell>
                          <TableCell>{data.emp_id}</TableCell>
                          <TableCell>
                            <div className={classes.statusBtn}>
                              {renderCellByStatus(data.status)}
                            </div>
                          </TableCell>
                          <TableCell>
                            {displayAmountFormat(parseFloat(data.allocated_credits).toFixed(1))}
                          </TableCell>
                          <TableCell>
                            {data.status === 1 ? (
                              <div className={classes.btnContent}>
                                <TextField
                                  type="number"
                                  name="wallet_balance"
                                  value={data.wallet_balance}
                                  onChange={(e) => inputHandler(e, index)}
                                  onClick={() => setIsUpdated(data.user_id)}
                                  className={classes.balanceBox}
                                />
                                {isUpdated === data.user_id ? (
                                  <Button
                                    onClick={() =>
                                      saveBalanceUpdated(
                                        data.user_id,
                                        data.wallet_balance
                                      )
                                    }
                                    className={classes.saveBtn}
                                  >
                                    save
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <div className={classes.balanceBox}>
                                <span>-</span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell>{data.balance_expiry}</TableCell>
                          <TableCell>
                            <div className={classes.actionBox}>
                              <Tooltip
                                title="Edit details"
                                arrow
                                toolTipcontent
                              >
                                <DrawerRightEditForm
                                  parentCallback={EmpAdded}
                                  statusImg={data.status}
                                  editEmpHandler={data.user_id}
                                />
                              </Tooltip>
                              <Tooltip title="Archive" arrow>
                                {data.status == 2 ? (
                                  <Button>
                                    <img
                                      src="./images/ButtonEditBlur.svg"
                                      alt="Better cloud"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      deleteEmpHandler(data.user_id)
                                    }
                                  >
                                    <img
                                      src="./images/edit-btn.svg"
                                      alt="Better cloud"
                                    />
                                  </Button>
                                )}
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div className={classes.noData}>
                          <h2>No data</h2>
                          <p>
                            Oops, there's nothing to show here. Add your
                            employees below.
                          </p>
                          <DrawerRight
                            // parentCallback={togglerHandler}
                            onOpen={onOpen}
                            isCSV={false}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {/* ) : (
                 <span className={classes.noData}> No Data</span>
                )} */}
              </Table>
            </TableContainer>
          </Paper>
          {pagination && (
            <div className={classes.bottomBox}>
              <Paginator
                handleChangeValuePage={handleChangeValuePage}
                pageData={pagination}
                currentPage={page}
              />
            </div>
          )}
        </div>
        {openModel && (
          <ModalBox
            open={openModel}
            handleClose={handleCloseDelete}
            handleDelete={(e) => deleteEmpHandler(eid)}
            className={classes.modalBox}
            heading="ARE YOU SURE YOU WANT TO ARCHIVE THIS EMPLOYEE?"
            paragraph=""
          ></ModalBox>
        )}
      </div>
    </>
  );
};
export default withStyles(Styles)(Employees);

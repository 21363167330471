export const Styles = Theme => ({
    datapickerOuter: {
        display: "table",
        background: "transparent",
        border: "none",
        width: "auto",
        maxWidth: "240px",
        marginLeft: "auto",

    },
    datapickerBox: {
        position: "relative",
        width: "100%",
        paddingLeft:"15px",
        transition: ".5s ease",
        "& div": {
            width: "100%",
            minWidth: "80px",
            alignItems:"center",
            "& span":{
                fontSize:"25px",
            }
        },
        "& fieldset": {
            borderColor: "transparent !important",
        },
        "& input": {
            borderRadius: "0",
            padding: "0px 8px 0 0",
            boxSizing: "border-box",
            height: "auto",
            minHeight: "15px",
            width: "100%",
            border: "none",
            background: "transparent !important",
            outline: "none",
            fontSize: "20px",
            lineHeight: "normal",
            textAlign: "center",
            fontFamily: "'Bebas Neue', serif",
            color: "#3F4444 !important",
            "&::placeholder": {
                fontSize: "12px",
                lineHeight: "12px",
                color: "#3F444480",
                fontWeight: "400",
                fontFamily: "'Noto Serif', serif",
                textTransform: "uppercase",
                opacity: "1",
            },
        },
        "& img": {
            height: "14px",
            width: "12px",
            position: "absolute",
            top: "10px",
            right: "0",
            transform: "translateY(-50%)",
        },
    },
    arrow: {
        left: "auto !important",
        right: "15px",
    },
    dFlex: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        "& label": {
            color: "#3F444480 !important",
            marginBottom: "0",
            lineHeight: "18px !important",
            fontSize: "12px !important",
        },

    },
    innerDate: {
        paddingLeft: "10px !important",
    },
    firstDate: {
        "& input": {
            textAlign: "left",
        }
    },
    secondDate: {
        "& input": {
            textAlign: "right",
            paddingRight:"0",
        }
    }
})
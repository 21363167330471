import {
  validatePassword,
  validateConfirmPassword,
  validateRequired,
} from "../../common/utils/utils";
import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import StrConstent from "../../common/StrConstent";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import FormLabel from "@mui/material/FormLabel";
import { encryptValue } from "../../common/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { userSetting, logout } from "../../redux/actions/UserActions";
import { Button } from "@mui/material";
import Loader from "react-spinner-loader";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
import { ActionType } from "../../redux/ActionTypes";
import Propover from "../../component/Propover/Propover";
const Setting = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [inValid, setInValid] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState("");
  const [reEnterShowPassword, setReEnterShowPassword] = useState("");
  const [oldShowPassword, setOldShowPassword] = useState("");
  const [inputPassword, setInputPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isOpen, setIsOpen] = useState(true);
  const { setting, loading, error, success } = useSelector(
    (state) => state.userSetting
  );
  useEffect(() => {
    if (success || error) {
      setIsOpen(true);
      success ? dispatch(logout(success)) : console.log(error);
    }
  }, [success, error, dispatch]);
  const updatePassword = () => {
    if (validatePassword(inputPassword.currentPassword))
      return setInValid((pre) => !pre);
    else if (validatePassword(inputPassword.newPassword))
      return setInValid((pre) => !pre);
    else if (validateConfirmPassword(inputPassword.confirmPassword)) {
      const payload = {
        current_password: encryptValue(inputPassword.currentPassword),
        new_password: encryptValue(inputPassword.newPassword),
        new_password_confirmation: encryptValue(inputPassword.confirmPassword),
      };
      return dispatch(userSetting(payload));
    } else return setInValid((pre) => !pre);
  };
  const handlerChangeInput = (e) => {
    const { name, value } = e.target;
    setInputPassword((pre) => ({ ...pre, [name]: value }));
  };
  const clickHandler = (input) => {
    if (input) {
      setInputPassword({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      return setInValid(false);
    } else return;
  };
  return (
    <>
      {loading && (
        <Loader
          Style={{ zIndex: "9999" }}
          message="Redirecting to login..."
          stack="vertical"
          show={loading}
          type="body"
        />
      )}

      {/* {success && (
        <AlterMessage
          handleClose={() => setIsOpen(false)}
          description={setting.message}
          url="./images/success.svg"
          isopen={isOpen}
          title="Success"
          type="Success"
        />
      )} */}

      {error && (
        <AlterMessage
          handleClose={() => setIsOpen(false)}
          url="./images/error.svg"
          description={error}
          isopen={isOpen}
          title="Error"
          type="Error"
        />
      )}
      <div className={classes.empOuter}>
        <div className={classes.topBox}>
          <Heading
            heading={StrConstent.Setting.headerTitle}
            description={StrConstent.Setting.headerDesc}
          />
          <CreditsInfo />
        </div>
        <div className={classes.outer}>
          <div className={classes.formInner}>
            <h3>Change password</h3>
            <div className={classes.formControl}>
              <FormLabel>
                Old password<span className="req">*</span>
              </FormLabel>
              <div className={classes.passwordBox}>
                <input
                  type={oldShowPassword ? "text" : "password"}
                  name="currentPassword"
                  value={inputPassword.currentPassword}
                  placeholder="Enter your current password"
                  onChange={handlerChangeInput}
                />
                <span onClick={() => setOldShowPassword(!oldShowPassword)}>
                  {oldShowPassword ? <Visibility /> : <VisibilityOff />}
                </span>
              </div>

              {inValid && validateRequired(inputPassword.currentPassword) ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <div></div>
              )}

              {inputPassword.currentPassword !== "" &&
                validatePassword(inputPassword.currentPassword) ? (
                  <p className="errorMessage">
                   Your password must be at least 8 characters, with at least one number, one special character, and have a mixture of uppercase and lowercase letters.
                  </p>
                ) : null}

              {/* {inputPassword.currentPassword !== "" &&
              validatePassword(inputPassword.currentPassword) ? (
                <p className="errorMessage">
                  Information entered is invalid. Please try again.
                </p>
              ) : null} */}
            </div>
            <div className={classes.dFlex}>
              <div className={classes.formControl}>
                <FormLabel>
                  New password<span className="req">*</span>
                  <Propover>
                    <b>{StrConstent.passwordGuideLine.propoverTitle}</b>
                    <ul>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription1}
                        </span>
                      </li>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription2}
                        </span>
                      </li>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription3}
                        </span>
                      </li>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription4}
                        </span>
                      </li>
                    </ul>
                  </Propover>
                </FormLabel>
                <div className={classes.passwordBox}>
                  <input
                    type={newShowPassword ? "text" : "password"}
                    name="newPassword"
                    value={inputPassword.newPassword}
                    placeholder="Enter your new password"
                    onChange={handlerChangeInput}
                  />
                  <span onClick={() => setNewShowPassword(!newShowPassword)}>
                    {newShowPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </div>
                {inValid && validateRequired(inputPassword.newPassword) ? (
                  <p className="errorMessage">This field is required.</p>
                ) : (
                  <div></div>
                )}
                {inputPassword.newPassword !== "" &&
                validatePassword(inputPassword.newPassword) ? (
                  <p className="errorMessage">
                   Your password must be at least 8 characters, with at least one number, one special character, and have a mixture of uppercase and lowercase letters.
                  </p>
                ) : null}
              </div>
              <div className={`${classes.formControl} ${classes.spaceRight}`}>
                <FormLabel>
                  Re-enter password<span className="req">*</span>
                  <Propover>
                    <b>{StrConstent.passwordGuideLine.propoverTitle}</b>
                    <ul>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription1}
                        </span>
                      </li>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription2}
                        </span>
                      </li>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription3}
                        </span>
                      </li>
                      <li>
                        <span>
                          {StrConstent.passwordGuideLine.propoverDescription4}
                        </span>
                      </li>
                    </ul>
                  </Propover>
                </FormLabel>
                <div className={classes.passwordBox}>
                  <input
                    type={reEnterShowPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={inputPassword.confirmPassword}
                    placeholder="Re-enter your new password"
                    onChange={handlerChangeInput}
                  />
                  <span
                    onClick={() => setReEnterShowPassword(!reEnterShowPassword)}
                  >
                    {reEnterShowPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </div>
                {inValid && validateRequired(inputPassword.confirmPassword) ? (
                  <p className="errorMessage">This field is required.</p>
                ) : (
                  <div></div>
                )}
                {inputPassword.confirmPassword !== "" &&
                validateConfirmPassword(
                  inputPassword.newPassword,
                  inputPassword.confirmPassword
                ) ? (
                  <p className="errorMessage">
                    The passwords do not match. Please try again.
                  </p>
                ) : null}
              </div>
            </div>
            <div className={classes.btnContent}>
              <BorderBtn name={"Cancel"} clickHandler={clickHandler} />
              <div className={classes.yellowBtn}>
                <Button onClick={() => updatePassword()}>Confirm</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(Setting);

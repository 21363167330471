import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTopFiveAction } from "../../redux/actions/Dashboard/DashboardAction";
const ChartSpendingProducts = (props) => {
  const { classes, stateDate = null, endDate = null } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.topFive);
  const options = {
    colors: ["#FFC200"],
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      animations: {
        speed: 500,
      },
    },
    plotOptions: {
      bar: {
        // distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: data?.topFiveGraph?.success
        ? data?.topFiveGraph?.data?.map((e) => e?.name)
        : "",
      group: {
        style: {
          fontSize: "10px",
          fontWeight: 700,
        },
        groups: [
          {
            title: "F&B",
            cols: 5,
          },
        ],
      },
    },
    yaxis: {
      title: {
        text: "NUMBER OF ORDERS ",
      },
    },
    labels: {
      rotate: -45,
    },
    tooltip: {
      x: {
        formatter: data?.topFiveGraph?.data?.map((e) => e?.name),
      },
    },
  };
  const series = [
    {
      name: "sales",
      data: data?.topFiveGraph?.success
        ? data?.topFiveGraph?.data?.map((e) => e?.y)
        : 0,
    },
  ];
  useEffect(() => {
    if (stateDate && endDate)
      return dispatch(getTopFiveAction(stateDate, endDate));
  }, [stateDate, endDate]);

  useEffect(() => {
    dispatch(getTopFiveAction());
  }, []);
  return (
    <div id="chart" className={classes.spendingChart}>
      <Chart options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default withStyles(Styles)(ChartSpendingProducts);

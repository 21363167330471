export const Styles = (Theme) => ({
  formOuter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  formBox: {
    padding: "0 0 20px",
    "& > div": {
      width: "100%",
      paddingRight: "0",
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      color: "rgba(63, 68, 68, 0.5)",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
      margin: "0",
      "& a": {
        fontWeight: "700",
        color: "#3F4444",
        display: "inline-block",
        textDecoration: "underline !important",
      },
    },
  },
  formInner: {
    padding: "30px 0 0",
  },
  uploadFile: {
    position: "relative",
    background: "#E3DDD280",
    borderRadius: "20px",
    padding: "12px 10px",
    boxSizing: "border-box",
    cursor:"pointer",
    "& input": {
      width: "100%",
      height: "100%",
      opacity: "0",
      overflow: "hidden",
      position: " absolute",
      top: "0",
      left: "0",
      zIndex: "9",
      cursor: "pointer",
    },
    "& img": {
      display: "block",
      margin: "10px auto 15px",
    },
    "& b": {
      display: "block",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#3F4444",
      fontFamily: "'Bebas Neue', serif",
      textAlign: "center",
      padding: "0 0 8px",
    },
    "& span": {
      display: "block",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "14px",
      color: "#3F4444B2",
      fontFamily: "'Noto Serif', serif",
      textAlign: "center",
    },
  },
  btnContent: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  fileContainer: {
    marginBottom: "16px",
    marginTop: "20px",
  },
  csvIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    "& div": {
      position: "unset !important",
      width: "19px",
      marginRight: "8px !important",
    },
  },
  image: {
    objectFit: "contain",
    width: "100% !important",
    position: "relative !important",
    height: "unset !important",
  },
  loadFilesBox: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "calc(100% - 18px)",
    position: "relative",
  },
  fileName: {
    whiteSpace: "nowrap",
    width: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    transform: "translateY(3px)",
    marginLeft: 10,
  },
  dltIcon: {
    color: "#FF4646",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  sizeOfFileClr: {
    color: "#DDDDDD",
  },
  deleteIcon: {
    position: "absolute",
    right: " 0",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  downloadLink: {
    padding: '0px 10px'
  }
});

import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paginator from "../../component/Paginator/Paginator";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import StrConstent from "../../common/StrConstent";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getTopUpHistoryList } from "../../redux/actions/Credits/CreditAction";
import Loader from "react-spinner-loader";
import axios from "axios";
import constant from "../../common/constant";
const CreditHistory = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [refreshWallet, setRefreshWallet] = useState(false);
  const { topuphistory, loading } = useSelector((state) => state.topupHistory);
  useEffect(() => {
    dispatch(getTopUpHistoryList(page), setRefreshWallet(true));
  }, [page]);

  const handleChangeValuePage = (event, value) => {
    setPage(value);
  };

  const downloadHandler = (invoiceId) => {
    let token = localStorage.getItem("auth_token");
    try {
      axios({
        url: `${constant.baseUrl}/api/v1/b2b/users/credit/top-ups/${invoiceId}/get-invoice`, //your url
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "Invoice.pdf");
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(fileURL);
        }, 100);
      });
    } catch (error) {}
  };

  return (
    <>
      {loading && (
        <Loader
          Style={{ zIndex: "9999" }}
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <div className={classes.empOuter}>
        <div className={classes.topBox1}>
          <Heading
            heading={StrConstent.CreditHistory.headerTitle}
            description={StrConstent.CreditHistory.headerDesc}
          />
          <CreditsInfo refreshWallet={refreshWallet} />
        </div>
        <div className={classes.tableOuter}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell> Transaction ID </TableCell>
                    <TableCell> Payment ID / Invoice Number</TableCell>
                    <TableCell> Date / Time</TableCell>
                    <TableCell> Description </TableCell>
                    <TableCell> Actions </TableCell>
                  </TableRow>
                </TableHead>
                {topuphistory?.data?.topups?.length >= 0 ? (
                  <TableBody>
                    {topuphistory?.data?.topups?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{data?.transaction_id}</TableCell>
                        <TableCell>
                          {data?.credit_type === 0
                            ? data?.payment_id
                            : data?.invoice_id}
                        </TableCell>
                        <TableCell>{data?.created_at}</TableCell>
                        <TableCell>{data?.payment_for}</TableCell>
                        <TableCell>
                          {data?.credit_type === 1 &&
                          data?.show_invoice !== "-" ? (
                            <div className={classes.actionBox}>
                              <Tooltip
                                title="view invoice"
                                arrow
                                tooltipcontent="true"
                              >
                                <a
                                  href={data?.show_invoice}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src="./images/view-pdf.svg"
                                    alt="Better cloud"
                                  />
                                </a>
                              </Tooltip>
                              <Tooltip title="download invoice" arrow>
                                {/* <a
                                  href={data?.show_invoice}
                                  rel="noreferrer"
                                  target="_blank"
                                  download
                                  // download="ZbhE-1667276186.pdf"
                                  // download={`Invoice-pdf ${new Date()}.pdf`}
                                > */}
                                <Button
                                  onClick={() => downloadHandler(data?.id)}
                                >
                                  {/* <Button
                                  onClick={() =>
                                    saveAs(
                                      data?.show_invoice,
                                      "ZbhE-1667276186.pdf"
                                    )
                                  }
                                > */}
                                  {/* <Button> */}
                                  <img
                                    src="./images/download-pdf.svg"
                                    alt="Better cloud"
                                  />
                                </Button>
                                {/* </a> */}
                              </Tooltip>
                            </div>
                          ) : (
                            <p className={classes.forCenter}>-</p>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div className={classes.noData}>
                          <h2>No data</h2>
                          <p>Oops, there's nothing to show here.</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <div className="">
            <Paginator
              handleChangeValuePage={handleChangeValuePage}
              pageData={topuphistory?.data.pagination}
              currentPage={page}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(CreditHistory);

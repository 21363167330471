import React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
const Heading = (props) => {
  const { classes, heading, description } = props;
  return (
    <div className={classes.headingBox}>
      <h2>{heading}</h2>
      {description === "Still got questions? Get in touch with us " ? (
        <p>
          {description}
          <a
            className={classes.forHere}
            style={{fontWeight:'bolder'}}
            href="https://www.order.bettr.coffee/contact-us/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </p>
      ) : null}
      {description !== "Still got questions? Get in touch with us " ? (
        <p>{description}</p>
      ) : null}
    </div>
  );
};
export default withStyles(Styles)(Heading);

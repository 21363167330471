export const ActionType = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",

  EMAIL_RESEND_LINK_REQUEST: "EMAIL_RESEND_LINK_REQUEST",
  EMAIL_RESEND_LINK_SUCCESS: "EMAIL_RESEND_LINK_SUCCESS",
  EMAIL_RESEND_LINK_FAIL: "EMAIL_RESEND_LINK_FAIL",

  SET_PASSWORD_REQUEST: "SET_PASSWORD_REQUEST",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_FAIL: "SET_PASSWORD_FAIL",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",

  USER_SIGNIN_REQUEST: "USER_SIGNIN_REQUEST",
  USER_SIGNIN_SUCCESS: "USER_SIGNIN_SUCCESS",
  USER_SIGNIN_FAIL: "USER_SIGNIN_FAIL",

  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "USER_LOGOUT_FAIL",

  USER_NEW_PASSWORD_REQUEST: "USER_NEW_PASSWORD_REQUEST",
  USER_NEW_PASSWORD_SUCCESS: "USER_NEW_PASSWORD_SUCCESS",
  USER_NEW_PASSWORD_FAIL: "USER_NEW_PASSWORD_FAIL",

  TRANSACTION_DATA_REQUEST: "TRANSACTION_DATA_REQUEST",
  TRANSACTION_DATA_SUCCESS: "TRANSACTION_DATA_SUCCESS",
  TRANSACTION_DATA_FAIL: "TRANSACTION_DATA_FAIL",

  EMPLOYEES_LIST_REQUEST: "EMPLOYEES_LIST_REQUEST",
  EMPLOYEES_LIST_SUCCESS: "EMPLOYEES_LIST_SUCCESS",
  EMPLOYEES_LIST_FAIL: "EMPLOYEES_LIST_FAIL",

  EMPLOYEES_ADD_REQUEST: "EMPLOYEES_ADD_REQUEST",
  EMPLOYEES_ADD_SUCCESS: "EMPLOYEES_ADD_SUCCESS",
  EMPLOYEES_ADD_FAIL: "EMPLOYEES_ADD_FAIL",

  DELETE_SINGLE_EMP_REQUEST: "DELETE_SINGLE_EMP_REQUEST",
  DELETE_SINGLE_EMP_SUCCESS: "DELETE_SINGLE_EMP_SUCCESS",
  DELETE_SINGLE_EMP_FAIL: "DELETE_SINGLE_EMP_FAIL",

  GET_SINGLE_EMP_REQUEST: "GET_SINGLE_EMP_REQUEST",
  GET_SINGLE_EMP_SUCCESS: "GET_SINGLE_EMP_SUCCESS",
  GET_SINGLE_EMP_FAIL: "GET_SINGLE_EMP_FAIL",

  UPDATE_SINGLE_EMP_REQUEST: "UPDATE_SINGLE_EMP_REQUEST",
  UPDATE_SINGLE_EMP_SUCCESS: "UPDATE_SINGLE_EMP_SUCCESS",
  UPDATE_SINGLE_EMP_FAIL: "UPDATE_SINGLE_EMP_FAIL",

  UPDATE_SINGLE_EMP_BALANCE_REQUEST: "UPDATE_SINGLE_EMP_BALANCE_REQUEST",
  UPDATE_SINGLE_EMP_BALANCE_SUCCESS: "UPDATE_SINGLE_EMP_BALANCE_SUCCESS",
  UPDATE_SINGLE_EMP_BALANCE_FAIL: "UPDATE_SINGLE_EMP_BALANCE_FAIL",

  GET_COMPANY_DETAILS_REQUEST: "GET_COMPANY_DETAILS_REQUEST",
  GET_COMPANY_DETAILS_SUCCESS: "GET_COMPANY_DETAILS_SUCCESS",
  GET_COMPANY_DETAILS_FAIL: "GET_COMPANY_DETAILS_FAIL",

  UPDATE_COMPANY_PROFILE_REQUEST: "UPDATE_COMPANY_PROFILE_REQUEST",
  UPDATE_COMPANY_PROFILE_SUCCESS: "UPDATE_COMPANY_PROFILE_SUCCESS",
  UPDATE_COMPANY_PROFILE_FAIL: "UPDATE_COMPANY_PROFILE_FAIL",

  UPDATE_COMPANY_ACCOUNT_REQUEST: "UPDATE_COMPANY_ACCOUNT_REQUEST",
  UPDATE_COMPANY_ACCOUNT_SUCCESS: "UPDATE_COMPANY_ACCOUNT_SUCCESS",
  UPDATE_COMPANY_ACCOUNT_FAIL: "UPDATE_COMPANY_ACCOUNT_FAIL",

  SPEND_POLICY_HISTORY_LIST_REQUEST: "SPEND_POLICY_HISTORY_LIST_REQUEST",
  SPEND_POLICY_HISTORY_LIST_SUCCESS: "SPEND_POLICY_HISTORY_LIST_SUCCESS",
  SPEND_POLICY_HISTORY_LIST_FAIL: "SPEND_POLICY_HISTORY_LIST_FAIL",

  SPEND_POLICY_CATEGORY_LIST_REQUEST: "SPEND_POLICY_CATEGORY_LIST_REQUEST",
  SPEND_POLICY_CATEGORY_LIST_SUCCESS: "SPEND_POLICY_CATEGORY_LIST_SUCCESS",
  SPEND_POLICY_CATEGORY_LIST_FAIL: "SPEND_POLICY_CATEGORY_LIST_FAIL",

  SPEND_POLICY_OUTLETS_LIST_REQUEST: "SPEND_POLICY_OUTLETS_LIST_REQUEST",
  SPEND_POLICY_OUTLETS_LIST_SUCCESS: "SPEND_POLICY_OUTLETS_LIST_SUCCESS",
  SPEND_POLICY_OUTLETS_LIST_FAIL: "SPEND_POLICY_OUTLETS_LIST_FAIL",

  SPEND_POLICY_UPDATE_REQUEST: "SPEND_POLICY_UPDATE_REQUEST",
  SPEND_POLICY_UPDATE_SUCCESS: "SPEND_POLICY_UPDATE_SUCCESS",
  SPEND_POLICY_UPDATE_FAIL: "SPEND_POLICY_UPDATE_FAIL",

  FAQS_LIST_REQUEST: "FAQS_LIST_REQUEST",
  FAQS_LIST_SUCCESS: "FAQS_LIST_SUCCESS",
  FAQS_LIST_FAIL: "FAQS_LIST_FAIL",

  FAQS_ITEMS_LIST_REQUEST: "FAQS_ITEMS_LIST_REQUEST",
  FAQS_ITEMS_LIST_SUCCESS: "FAQS_ITEMS_LIST_SUCCESS",
  FAQS_ITEMS_LIST_FAIL: "FAQS_ITEMS_LIST_FAIL",

  TOPUP_HISTORY_LIST_REQUEST: "TOPUP_HISTORY_LIST_REQUEST",
  TOPUP_HISTORY_LIST_SUCCESS: "TOPUP_HISTORY_LIST_SUCCESS",
  TOPUP_HISTORY_LIST_FAIL: "TOPUP_HISTORY_LIST_FAIL",

  CARDS_LIST_REQUEST: "CARDS_LIST_REQUEST",
  CARDS_LIST_SUCCESS: "CARDS_LIST_SUCCESS",
  CARDS_LIST_FAIL: "CARDS_LIST_FAIL",

  SAVE_CARD_REQUEST: "SAVE_CARD_REQUEST",
  SAVE_CARD_SUCCESS: "SAVE_CARD_SUCCESS",
  SAVE_CARD_FAILURE: "SAVE_CARD_FAILURE",

  DELETE_LIST_REQUEST: "DELETE_LIST_REQUEST",
  DELETE_LIST_SUCCESS: "DELETE_LIST_SUCCESS",
  DELETE_LIST_FAIL: "DELETE_LIST_FAIL",

  TOP_UPS_DETAILS_REQUEST: "TOP_UPS_DETAILS_REQUEST",
  TOP_UPS_DETAILS_SUCCESS: "TOP_UPS_DETAILS_SUCCESS",
  TOP_UPS_DETAILS_FAIL: "TOP_UPS_DETAILS_FAIL",

  TOP_UPS_WITH_CORPORATE_REQUEST: "TOP_UPS_WITH_CORPORATE_REQUEST",
  TOP_UPS_WITH_CORPORATE_SUCCESS: "TOP_UPS_WITH_CORPORATE_SUCCESS",
  TOP_UPS_WITH_CORPORATE_FAIL: "TOP_UPS_WITH_CORPORATE_FAIL",

  TOPUP_MESSAGE_RESET: "TOPUP_MESSAGE_RESET",

  GET_DASHBOARD_DETAILS_REQUEST: "GET_DASHBOARD_DETAILS_REQUEST",
  GET_DASHBOARD_DETAILS_SUCCESS: "GET_DASHBOARD_DETAILS_SUCCESS",
  GET_DASHBOARD_DETAILS_FAIL: "GET_DASHBOARD_DETAILS_FAIL",

  GET_CREDITS_INFO_REQUEST: "GET_CREDITS_INFO_REQUEST",
  GET_CREDITS_INFO_SUCCESS: "GET_CREDITS_INFO_SUCCESS",
  GET_CREDITS_INFO_FAIL: "GET_CREDITS_INFO_FAIL",

  USER_SETTING_CHANGE_PASSWORD_REQUEST: "USER_SETTING_CHANGE_PASSWORD REQUEST",
  USER_SETTING_CHANGE_PASSWORD_SUCCESS: "USER_SETTING_CHANGE_PASSWORD_SUCCESS",
  USER_SETTING_CHANGE_PASSWORD_FAILURE: "USER_SETTING_CHANGE_PASSWORD_FAILURE",

  GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",

  EMPLOYEE_IMPORT_REQUEST: "EMPLOYEE_IMPORT_REQUEST",
  EMPLOYEE_IMPORT_SUCCESS: "EMPLOYEE_IMPORT_SUCCESS",
  EMPLOYEE_IMPORT_FAILURE: "EMPLOYEE_IMPORT_FAILURE",

  GET_ORDER_DETAIL_REQUEST: "GET_ORDER_DETAIL_REQUEST",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_FAILURE: "GET_ORDER_DETAIL_FAILURE",

  UPDATE_CREDIT_ALLOCATION_REQUEST: "UPDATE_CREDIT_ALLOCATION_REQUEST",
  UPDATE_CREDIT_ALLOCATION_SUCCESS: "UPDATE_CREDIT_ALLOCATION_SUCCESS",
  UPDATE_CREDIT_ALLOCATION_FAILURE: "UPDATE_CREDIT_ALLOCATION_FAILURE",

  SP_CREDIT_ALLOCATION_HISTORY_REQUEST: "SP_CREDIT_ALLOCATION_HISTORY_REQUEST",
  SP_CREDIT_ALLOCATION_HISTORY_SUCCESS: "SP_CREDIT_ALLOCATION_HISTORY_SUCCESS",
  SP_CREDIT_ALLOCATION_HISTORY_FAILURE: "SP_CREDIT_ALLOCATION_HISTORY_FAILURE",

  SP_COMPANY_MODES_REQUEST: "SP_COMPANY_MODES_REQUEST",
  SP_COMPANY_MODES_SUCCESS: "SP_COMPANY_MODES_SUCCESS",
  SP_COMPANY_MODES_FAILURE: "SP_COMPANY_MODES_FAILURE",

  GET_CONFIGURATION_REQUEST: "GET_CONFIGURATION_REQUEST",
  GET_CONFIGURATION_SUCCESS: "GET_CONFIGURATION_SUCCESS",
  GET_CONFIGURATION_FAILURE: "GET_CONFIGURATION_FAILURE",

  EMAIL_VERIFY_REQUEST: "EMAIL_VERIFY_REQUEST",
  EMAIL_VERIFY_SUCCESS: "EMAIL_VERIFY_SUCCESS",
  EMAIL_VERIFY_FAILURE: "EMAIL_VERIFY_FAILURE",

  CLEAR_ERROR: "CLEAR_ERROR",

  DELIVERY_PICKUP_GRAPH_REQUEST: "DELIVERY_PICKUP_GRAPH_REQUEST",
  DELIVERY_PICKUP_GRAPH_SUCCESS: "DELIVERY_PICKUP_GRAPH_SUCCESS",
  DELIVERY_PICKUP_GRAPH_FAILURE: "DELIVERY_PICKUP_GRAPH_FAILURE",

  CREDIT_USAGE_GRAPH_REQUEST: "CREDIT_USAGE_GRAPH_REQUEST",
  CREDIT_USAGE_GRAPH_SUCCESS: "CREDIT_USAGE_GRAPH_SUCCESS",
  CREDIT_USAGE_GRAPH_FAILURE: "CREDIT_USAGE_GRAPH_FAILURE",

  USER_FREQUENCY_GRAPH_REQUEST: "USER_FREQUENCY_GRAPH_REQUEST",
  USER_FREQUENCY_GRAPH_SUCCESS: "USER_FREQUENCY_GRAPH_SUCCESS",
  USER_FREQUENCY_GRAPH_FAILURE: "USER_FREQUENCY_GRAPH_FAILURE",

  TOP_FIVE_GRAPH_REQUEST: "TOP_FIVE_GRAPH_REQUEST",
  TOP_FIVE_GRAPH_SUCCESS: "TOP_FIVE_GRAPH_SUCCESS",
  TOP_FIVE_GRAPH_FAILURE: "TOP_FIVE_GRAPH_FAILURE",

  VERIFY_UEN_REQUEST: "VERIFY_UEN_REQUEST",
  VERIFY_UEN_SUCCESS: "VERIFY_UEN_SUCCESS",
  VERIFY_UEN_FAILURE: "VERIFY_UEN_FAILURE",

  VERIFY_COMPANY_REQUEST:"VERIFY_COMPANY_REQUEST",
  VERIFY_COMPANY_SUCCESS:"VERIFY_COMPANY_SUCCESS",
  VERIFY_COMPANY_FAILURE:"VERIFY_COMPANY_FAILURE",

  USER_DETAILS_REQUEST: "USER_DETAILS_REQUEST",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILURE: "USER_DETAILS_FAILURE",

  KEEP_ME_LOGGED_IN: "KEEP_ME_LOGGED_IN",
};

import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import FormLabel from "@mui/material/FormLabel";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import StrConstent from "../../common/StrConstent";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomCheckbox from "../../component/CustomCheckbox/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
// STRIPE_KEY=pk_test_UnCuQWCZW7gKXOxSBR30vsdu
// STRIPE_SECRET=sk_test_oB6AI9BDvqF4BOiIXRJnHLW2
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  topUpsCorporateHandler,
  topUpsHandler,
} from "../../redux/actions/Credits/CreditAction";
import { validateAmount } from "../../common/utils/utils";
import AlertMessage from "../AlertMessage/AlertMessage";

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      color: "#3F4444",
      fontFamily: "Noto Serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CreditTopUpForm = (props) => {
  const {
    classes,
    cancelHandler,
    card_id,
    paymentOption,
    amount,
    creditSuccess,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = useState(false);
  const [inValid, setInValid] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const [card_discription, setCardDiscription] = useState("");
  const { scusses, error } = useSelector((state) => state?.messageShow);
  const { scusses: corporateSuccess } = useSelector(
    (state) => state?.topupwithcorporate
  );
  const dispatch = useDispatch();
  const [isProcess, setIsProcess] = useState(true);
  const onChangeHandler = (inputValue) => {
    setSaveCard(inputValue);
  };

  const handleChange = (event) => {
    console.log("event:", event);
  };

  let buttonClick = true;
  useEffect(() => {
    if (error !== undefined) {
      if (error) {
        cancelHandler(false);
        buttonClick = true;
      }
    }
    if (scusses || corporateSuccess) {
      cancelHandler(true);
      creditSuccess(true);
      buttonClick = true;
    }
  }, [error, scusses, corporateSuccess]);

  const paymentHandler = async (event) => {
    // console.log("paymentOption", paymentOption,validateAmount(amount),card_id);
    event.preventDefault();
    if (buttonClick) {
      buttonClick = false;
      if (paymentOption === 3 && !validateAmount(amount)) {
        if (card_id === "add") {
          const card = elements.getElement(CardNumberElement);
          const result = await stripe.createToken(card);
          const tok_en = result.token;
          if (result.error) {
            console.log(result.error.message);
            setIncomplete(result.error);
            return setInValid(true);
          } else {
            // Send the token to your server.
            // console.log("carddis0",card_discription);
            const stripeToken = { token: tok_en.id };
            let payload_1 = {
              total_amount: amount,
              stripe_token: stripeToken.token,
              card_name:card_discription,
              save_card: saveCard,
            };
            let payload = {
              total_amount: amount,
              stripe_token: stripeToken.token,
              card_name:card_discription,
              save_card: false,
            };
            if (saveCard) {
              // console.log("payload",payload_1,card_discription);
              dispatch(topUpsHandler(payload_1),
               setIsProcess(false)
               );
            } else {
              dispatch(topUpsHandler(payload), setIsProcess(false));
            }
          }
        } else {
          let payload = {
            total_amount: amount,
            card_name:card_discription,
            card_id: card_id.card_id,
          };
          dispatch(topUpsHandler(payload), setIsProcess(false));
        }
      } else {
        console.log("amount", amount);
        let payload = {
          total_amount: amount,
        };
        buttonClick = true;
        dispatch(topUpsCorporateHandler(payload), setIsProcess(false));
      }
    }
  };
  console.log("card_name",card_id);
  return (
    <>
      {" "}
      {validateAmount(amount) && (
        <AlertMessage
          handleClose={() => setOpen((pre) => !pre)}
          description={error}
          title="The credit amount should be <$10 & >$500"
          type="Error"
          isopen={open}
          url="./images/error.svg"
        />
      )}
      <div className={classes.formOuter}>
        <div className={classes.formBox}>
          <Heading
            heading={StrConstent.CreditTopUpForm.headerTitle}
            description={paymentOption !== 2?StrConstent.CreditTopUpForm.headerDesc:StrConstent.CreditTopUpForm.headerDesc1}
          />
           {/* <Heading
            heading={StrConstent.CreditTopUpForm.headerTitle}
            description={StrConstent.CreditTopUpForm.headerDesc1}
          /> */}
          {card_id === "add" && paymentOption === 3 ? (
            <div className={classes.listBox}>
              <p>Add a new card and pay</p>
              <form id="payment-form" onSubmit={paymentHandler}>
                <div className={classes.formInner}>
                  <div className={classes.formControl}>
                    <FormLabel>Card Number*</FormLabel>
                    <CardNumberElement
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={handleChange}
                    />
                    {inValid && incomplete?.code === "incomplete_number" ? (
                      <small className="errorMessage">
                        This filed is required.
                      </small>
                    ) : null}
                  </div>
                  <div className={classes.dFlex}>
                    <div className={classes.width50}>
                      <div className={classes.formControl}>
                        <FormLabel>Expiration*</FormLabel>
                        <CardExpiryElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChange}
                        />
                        {inValid && incomplete?.code === "incomplete_expiry" ? (
                          <small className="errorMessage">
                            This filed is required.
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className={classes.width50}>
                      <div className={classes.formControl}>
                        <FormLabel>CVC*</FormLabel>
                        <CardCvcElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChange}
                        />
                        {inValid && incomplete?.code === "incomplete_cvc" ? (
                          <small className="errorMessage">
                            This filed is required.
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.formControl}>
                    <FormLabel>Card description</FormLabel>
                    <input
                      type="text"
                      name="company_name"
                      value={card_discription}
                      onChange={(e) => setCardDiscription(e.target.value)}
                      placeholder="Card description (if any)"
                    />
                  </div>
                  <div className={classes.checkboxContent}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          defaultChecked
                          onChange={onChangeHandler}
                        />
                      }
                      label="Save card for future payments"
                    />
                  </div>
                </div>
              </form>
              <p>Credits purchase summary</p>
              <ul>
                <li>
                  <span>Number of credits</span>
                  <b>{amount}</b>
                </li>
                <li>
                  <span>Per credit</span>
                  <b>S$ 1</b>
                </li>
                <li>
                  <span>Total cost</span>
                  <b>S$ {amount}</b>
                </li>
              </ul>
            </div>
          ) : (
            <div className={classes.listBox}>
              {paymentOption === 2 ? (
                <>
                  <p>PAY BY CORPORATE BILLING</p>
                  <p>CREDIT PURCHASE SUMMARY</p>
                </>
              ) : (
                <>
                  {card_id?.brand ? <p>PAY BY COMPANY CREDIT CARD</p> : null}
                  {card_id?.brand ? (
                    <div className={classes.imgBox}>
                      <img
                        src={`./images/${card_id.brand}.svg`}
                        alt="Better Coffee"
                      />
                      <div className={classes.txtContent}>
                        {card_id?.card_name!=""?<>
                        {card_id?.card_name.slice(0,50)}
                        </>:<>
                        {card_id.brand == "Visa" ? (
                          <span>Visa Credit Card </span>
                        ) : (
                          <span>
                            Mastercard Credit Card
                          </span>
                        )}
                        </>}
                       
                        <span>{`....${card_id?.last4}`}</span>
                      </div>
                    </div>
                  ) : null}
                  <p>Credits purchase summary</p>
                </>
              )}
              <ul>
                <li>
                  <span>Number of credits</span>
                  <b>{amount}</b>
                </li>
                <li>
                  <span>Per credit</span>
                  <b>S$ 1</b>
                </li>
                <li>
                  <span>Total cost</span>
                  <b>S$ {amount}</b>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className={classes.btnContent}>
          <BorderBtn name={"cancel"} clickHandler={cancelHandler} />
          <YellowBtn
            name={"confirm"}
            clickHandler={paymentHandler}
            disabled={!stripe || !isProcess}
          />
        </div>
      </div>
    </>
  );
};

export default withStyles(Styles)(CreditTopUpForm);

import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import reduxThunk from "redux-thunk";
import rootReducer from "./RootReducers";
import { composeWithDevTools } from "redux-devtools-extension";
const middleware = [reduxThunk];
if (process.env.REACT_APP_STAGING_MODE === "true") {
  // const { logger } = require(`redux-logger`);
  middleware.push(logger);
}
const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;

// import { applyMiddleware, createStore } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
// import reducers from "./reducers";

// import { persistReducer, persistStore } from "redux-persist";

// import storage from "redux-persist/lib/storage";
// import { useSelector } from "react-redux";

// const persistConsif = {
//   key: "root",
//   storage: storage,
//   blacklist: ["token"],
// };

// const persistedReducer = persistReducer(persistConsif, reducers);

// export const store = createStore(
//   persistedReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );

// export const persistor = persistStore(store);

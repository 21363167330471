export const Styles = (Theme) => ({
  p0: {
    padding: "0 !important",
  },
  empOuter: {
    padding: "20px 25px",
  },
  topBox1: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 0 40px",
    justifyContent: "space-between",
  },
  dFlex: {
    display: "flex",
    "& :nth-child(2)": {
      paddingLeft: "20px",
    },
  },
  width70: {
    width: "70%",
  },
  width50: {
    width: "50%",
  },
  width30: {
    width: "30%",
  },
  btnContent: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tabsBox: {
    marginTop: "20px",
    "& span": {
      display: "none",
    },
    "& button": {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#3F4444",
      fontFamily: "'Bebas Neue', serif",
      textTransform: "uppercase",
      padding: "12px 24px",
      textAlign: "center",
      minWidth: "150px",
      background: "#F1EEDB",
      "@media (max-width: 900px)": {
        fontSize: "20px",
        lineHeight: "20px",
      },
      "&[aria-selected=true]": {
        color: "#3F4444",
        background: "#ffc200",
        borderRadius: "32px",
      },
    },
  },
  tabsInner: {
    "& > div > div": {
      borderRadius: "30px",
      overflow: "hidden",
      display: "table",
      backgroundColor: "#F1EEDB",
    },
  },
  tabsContent: {
    padding: "25px 0",
    borderRadius: "0 20px 20px 20px",
    display: "table",
    width: "100%",
    height: "100%",
  },
  formInner: {
    padding: "10px 0",
  },
  formControl: {
    margin: "0 0 35px",
  },
  errMessage: {
    color: "#C80000",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    fontFamily: "'Noto Serif', serif",
    display: "block",
    paddingTop: "2px",
  },
  proBox: {
    display: "flex",
    padding: "10px 15px",
    background: "#fff",
    borderRadius: "20px",
    alignItems: "center",
    "@media (max-width: 900px)": {
      display: "block",
    },
  },
  proLeft: {
    display: "flex",
    alignItems: "flex-start",
    width: "70%",
    "@media (max-width: 900px)": {
      width: "100%",
      marginBottom: "15px",
    },
    "& proImg": {
      width: "auto",
    },
    "& imgBox": {
      height: "130px",
      width: "130px",
      borderRadius: "20px",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },

  proInfo: {
    padding: "6px 15px",
    "& h4": {
      color: "#ffc200",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      fontFamily: "'Bebas Neue', serif",
      margin: "5px 0",
    },
    "& p": {
      color: "#3F4444",
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "24px",
      fontFamily: "'Bebas Neue', serif",
      margin: "0 0 2px",
    },
    "& span": {
      color: "#3F444480",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      fontFamily: "'Noto Serif', serif",
      margin: "0 0 5px",
    },
  },
  socialBox: {
    width: "30%",
    "@media (max-width: 900px)": {
      width: "100%",
    },
    "& ul": {
      display: "table",
      margin: "auto",
      "@media (max-width: 900px)": {
        margin: "0",
      },
      "& li": {
        display: "flex",
        paddingBottom: "12px",
        "& imgContent": {
          height: "auto",
          maxWidth: "20px",
          "& img": {
            height: "auto",
            width: "100%",
          },
        },
        "& p": {
          color: "#3F444480",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "19px",
          fontFamily: "'Noto Serif', serif",
          margin: "0",
          paddingLeft: "10px",
        },
      },
    },
  },
  errorMes: {
    fontSize: "14px",
    lineHeight: "19px",
    color: "#DF3F51",
    fontWeight: "400",
    fontFamily: "'Noto Serif', serif",
    margin: "5px 0px 0px 0px",
  },
});

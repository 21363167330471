export const Styles = Theme => ({
    repRemove: {
        "& button": {
            background: "transparent !important",
        },
    },
    actionBox: {
        "& button": {
            background: "transparent",
            padding: "0",
            minWidth: "auto",
            marginRight: "5px",
        },
    },
    bottomBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& p": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#464A53",
            margin: "0",
        },
    },
    customTooltip: {
        position: "relative",
        display: "flex",
        fontWeight: "700 !important",
        "& img": {
            marginLeft: "5px",
        },
    },
    transTableeOuter: {
        padding: "8px",
        background: "#fafafa",
    },
    expandBox: {
        padding: "0 !important",
        width: "100%",
        "& tr": {
            "&:nth-child(odd) td": {
                background: "#FFC20033",
                borderRadius: "8px",
            },
            "&:nth-child(even) td": {
                background: "transparent",
            },
            "& td": {
                borderBottom: "none",
                padding: "12px !important",
                display: "block",
            },
        },

    },
    ltBox: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
    },
    txtBox: {
        width: "100%",
        "& ul": {
            margin: "0",
        },
        "& li": {
            width: "100%",
            display: "flex",
            "& div": {
                width: "50%",
                "& :nth-child(2)": {
                    paddingRight: "10px",
                },
            }
        },
        "& span": {
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            display: "block",
            padding: "0 0 5px",
        },
        "& h4": {
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: "400",
            margin: "0 0 8px",
        },
    },
})
import React from "react";
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
const Paginator = (props) => {
  const { classes, pageData, currentPage, handleChangeValuePage } = props;
  return (
    <div className={classes.bottomBox}>
      <div className={classes.txtBox}>
        {" "}
        <p>{`Showing ${pageData?.currentPage ? pageData?.currentPage : 0} - ${
          pageData?.lastPage ? pageData?.lastPage : 0
        } of ${pageData?.lastPage ? pageData?.lastPage : 0} results`}</p>
      </div>
      <div className={classes.pageBox}>
        {" "}
        <Pagination
          page={currentPage}
          count={pageData?.lastPage}
          onChange={handleChangeValuePage}
        />
      </div>
    </div>
  );
};
export default withStyles(Styles)(Paginator);

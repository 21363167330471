import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import StrConstent from "../../common/StrConstent";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import DeleteIcon from "@mui/icons-material/Delete";
import DrawerRightCredits from "../../component/DrawerRightCredits/DrawerRightCredits";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-spinner-loader";
import CustomPopover from "../../component/CustomPopover/CustomPopover";
import {
  deleteCard,
  getCardsList,
} from "../../redux/actions/Credits/CreditAction";
import { useNavigate } from "react-router-dom";
import ModalBox from "../../component/Modal/ModalBox";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
import { ActionType } from "../../redux/ActionTypes";
import { validateAmount } from "../../common/utils/utils";
const Credits = (props) => {
  const { classes } = props;
  const [cardId, setCardId] = useState("");
  const [amount, setAmount] = useState();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [refreshWallet, setRefreshWallet] = useState(false);
  const [paymentOption, setPaymentOption] = useState(1);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [isFormValidated, setIsFormValidated] = useState(false);
  const { cards } = useSelector((state) => state?.cardList);
  const [isopendelete, setisopendelete] = useState(false)
  const [isopencorporate, setisopencorporate] = useState(false);
  const [isopenerror, setisopenerror] = useState(false);
  const { messages, scusses, loading, error } = useSelector(
    (state) => state?.messageShow
  );
  const { messageDlt, scusses: deleteSuccess } = useSelector(
    (state) => state?.messageDelete
  );
  const { messageCorporate, scusses: corporateSuccess } = useSelector(
    (state) => state?.topupwithcorporate
  );
  const [snackdata, setsnackdata] = useState('')
  console.log("snackdata", messages, scusses, loading, error)
  useEffect(() => {
    dispatch(getCardsList());
    // handleFormValidation()
  }, []);

  // useEffect(() => {
  //   if (scusses || deleteSuccess || error || corporateSuccess) {
  //     setIsOpen(true);
  //     // setTimeout(() => {
  //     //   setIsOpen(false);
  //     //   dispatch(
  //     //     { type: ActionType.TOPUP_MESSAGE_RESET },
  //     //     setRefreshWallet(true)
  //     //   );
  //     // }, 3000);
  //   }
  // }, [scusses, deleteSuccess, error, corporateSuccess]);
  let check;
  const handleChangeOption = (event) => {
    check = event?.target?.value
    console.log("event?.target?.value", event?.target?.value);
    setPaymentOption(event?.target?.value);
    if (event.target.value === 1) {
      setCardId("");
      setIsFormValidated(false);
    } else if (event.target.value === 2) { setIsFormValidated(false); }
    else {
      setIsFormValidated(false);
    }
  };

  const removeCard = (cid) => {
    setOpen(true);
    if (open)
      return dispatch(
        deleteCard(cid),
        setOpen(false),
        setCardId(""),
        setIsFormValidated(false)
        // setIsOpen(true)
      );
  };

  const discardChanges = () => {
    setPaymentOption(1);
    setAmount("");
    setCardId("");
    setIsFormValidated(false);
  };

  const creditSuccess = (status) => {
    if (status) {
      setCardId("");
      setIsFormValidated(false);
      setAmount();
      setPaymentOption(1);
    }
  };

  const handleFormValidation = () => {
    // let isValid = true;
    if (
      amount === "" ||
      (paymentOption === 3 && cardId === "") ||
      amount === null ||
      amount == undefined ||
      paymentOption === 1 ||
      validateAmount(amount) ||
      (paymentOption === 3 &&
        cardId === "" &&
        amount == undefined &&
        amount === "" &&
        validateAmount(amount))
    ) {
      return setIsFormValidated(true);
    } // return setIsFormValidated(false);
    else {
      return setIsFormValidated(false);
    }


    // if (
    //   amount == undefined ||
    //   amount === null ||
    //   amount === "" ||
    //   cardId === "" ||
    //   validateAmount(amount)
    // ) {
    //   isValid = false;
    // } else {
    //   setIsFormValidated(true);
    // }
    // if (paymentOption === 1) {
    //   isValid = false;
    // } else {
    //   setIsFormValidated(true);
    // }
    // if (paymentOption === 3 && cardId === "") {
    //   isValid = false;
    // } else {
    //   setIsFormValidated(true);
    // }
  };
  useEffect(() => {
    if (amount > 1000) {
      setPaymentOption(1);
    } else if (amount < 10) setPaymentOption(1);
  }, [amount]);
  // console.log("cards?.data?.cards", cards?.data?.cards);
  const setInputNumber = (value) => {
    const regex = "[+-]?[0-9]+[0-9]+";
    if (regex.search(regex, value) && value?.length <= 7) {
      if (value > 0 || value === "") {
        let setVal = value;
        // if (isFloat(value)) {
        //   console.log("setVal -- ");
        //   const floatValue = value.split(".");
        //   if (floatValue[1].length > 3) {
        //     setVal = parseFloat(value).toFixed(3);
        //   }
        // }
        if (isFloat(value)) {
          const floatValue = value.split(".");
          setVal = floatValue[0];
        }
        setAmount(setVal);
      }
    } else {
      console.log("Not a Floating point number");
    }
  };
  // const isInt = (n) => {
  //   return Number(n) === n && n % 1 === 0;
  // };

  const isFloat = (n) => {
    return !!(n % 1);
  };

  const preventHandler = (e) => {
    if (e.keyCode === 69) {
      e.preventDefault();
    }
  };

  const snackHandler = () => {
    setisopenerror(false)
    setisopencorporate(false)
    setisopendelete(false)
    dispatch(
      { type: ActionType.TOPUP_MESSAGE_RESET },
      setRefreshWallet(true),
      setIsOpen(false)
    );
  };

  useEffect(() => {
    if (messages?.success) {
      setisopenerror(false)
      setIsOpen(true);
      setsnackdata(messages?.message);
      dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
    }

  }, [scusses])


  useEffect(() => {
    if (messageDlt) {
      setisopenerror(false)
      setisopendelete(true);
      setsnackdata(messageDlt?.message);
      dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
    }

  }, [deleteSuccess])


  useEffect(() => {
    // console.log("snackbardata1",messageCorporate)
    if (messageCorporate) {
      console.log("snackbardata1", messageCorporate)
      setisopenerror(false)
      setisopencorporate(true);

      setsnackdata(messageCorporate?.message);
      dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
    }

  }, [corporateSuccess])

  useEffect(() => {
    if (error) {
      console.log("vikalper")
      setisopendelete(false);
      setIsOpen(false);
      setisopencorporate(false);
      setisopenerror(true)
      setsnackdata(error || messages?.message)
      dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
    }
  }, [error])
  console.log("snackbardata", cards)

  return (
    <>
      {loading && (
        <Loader
          Style={{ zIndex: "9999" }}
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {isOpen && (
        <AlterMessage
          handleClose={snackHandler}
          description={snackdata}
          title="Success"
          type="Success"
          isopen={isOpen}
          url="./images/success.svg"
        />
      )}
      {isopendelete && (
        <AlterMessage
          handleClose={snackHandler}
          description={snackdata}
          title="Success"
          type="Success"
          isopen={isopendelete}
          url="./images/success.svg"
        />
      )}
      {isopencorporate && (
        <AlterMessage
          handleClose={snackHandler}
          description={snackdata}
          title="Success"
          type="Success"
          isopen={isopencorporate}
          url="./images/success.svg"
        />
      )}
      {isopenerror && (
        <AlterMessage
          handleClose={snackHandler}
          description={snackdata}
          title="Error"
          type="Error"
          isopen={isopenerror}
          url="./images/error.svg"
        />
      )}
      <div className={classes.empOuter}>
        <div className={classes.topBox1}>
          <Heading
            heading={StrConstent.Credits.headerTitle}
            description={StrConstent.Credits.headerDesc}
          />
          <CreditsInfo refreshWallet={refreshWallet} />
        </div>
        <div className={classes.creditsOuter}>
          <div className={classes.headline}>
            <h3 className={classes.creditField}>
              Credit top-up{" "}
              <CustomPopover
                description={
                  "Credits will expire 12 months after your purchase date."
                }
              />
            </h3>
            <a
              onClick={() => navigation("/credit-history")}
              style={{ cursor: "pointer" }}
            >
              View credit top-up history
            </a>
          </div>
          <div className={classes.fromBox}>
            <div className={classes.formControl}>
              <FormLabel>
                Enter Amount<span className="req">*</span>
              </FormLabel>
              <div className={classes.creditsField}>
                <div className={classes.fieldContainer}>
                  <input
                    type="number"
                    name="amount"
                    placeholder="Input top-up amount"
                    value={amount || ""}
                    maxLength={7}
                    onKeyDown={preventHandler}
                    onChange={(e) => setInputNumber(e.target.value)}
                  // // onInput={(e) => {
                  // //   e.target.value = Math.max(0, e.target.value)
                  // //     .toString()
                  // //     .slice(0, 7);
                  // // }}
                  // onInput={(e) => {
                  //   e.target.value =
                  //     /^(?=.*[0-9])(?=.*\d)(?=.*[.])[0-9\d.]{7,}$/.test(
                  //       e.target.value
                  //     ) && e.target.value;
                  // }}
                  />
                  <span className={classes.firstTxt}>S$</span>
                  <span className={classes.lastTxt}>{`= S$ ${amount == "" || amount == undefined ? 0 : `${amount}`
                    } credits `}</span>
                </div>
                {isFormValidated && (amount === "" || amount == undefined) && (
                  <p className="errorMessage">This field is required.</p>
                )}
                {amount != null && amount !== "" && validateAmount(amount) ? (
                  <p className="errorMessage">
                    {amount < 10 && StrConstent.Credits.creditAmountCheck}
                    {/* {amount > 500 && StrConstent.Credits.creditMaxAmountCheck} */}
                  </p>
                ) : null}
              </div>
            </div>
            <div className={classes.heading}>
              {" "}
              <h3>Pay by</h3>
            </div>
            <div className={classes.formControl}>
              <FormLabel>
                Pay with<span className="req">*</span>
              </FormLabel>
              <Select
                value={paymentOption}
                onChange={handleChangeOption}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={1}>
                  <span className={classes.titleTxt}>
                    Choose payment option
                  </span>
                </MenuItem>
                {amount > 1000 ? (
                  <MenuItem value={2}>Corporate billing</MenuItem>
                ) : (
                  <MenuItem value={3}>Company credit card</MenuItem>
                )}
              </Select>
              {isFormValidated && paymentOption === 1 && (
                <p className="errorMessage">This field is required.</p>
              )}
            </div>
            {paymentOption === 3 ? (
              <div className={classes.fromControl}>
                <FormLabel>
                  Select Card<span className="req">*</span>
                </FormLabel>
                <Select
                  className={classes.selectBox}
                  value={cardId}
                  onChange={(event) => setCardId(event.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <span className={classes.titleTxt}>
                      Choose Card / Add new card
                    </span>
                  </MenuItem>
                  {cards?.data?.cards !== "No Stripe Id found" &&
                    cards?.data?.cards?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        <div className={classes.dbBox}>
                          <div className={classes.lt}>
                            <div className={classes.imgBox}>
                              <img
                                src={`./images/${item?.brand}.svg`}
                                alt="Bettr Coffee"
                              />
                            </div>
                            <div className={classes.txtContent}>
                              {item?.card_name!=""?<>
                             <span>{item?.card_name.slice(0,50)}</span> 
                              </>:<>
                              {item?.brand == "Visa" ? (
                                <span>Visa Credit Card </span>
                              ) : (
                                <span>
                                  Mastercard Credit Card 
                                </span>
                              )}
                              </>}
                             
                              <span>{`....${item?.last4}`}</span>
                            </div>
                          </div>
                          <DeleteIcon
                            onClick={(e) => removeCard(item?.card_id)}
                          />
                        </div>
                      </MenuItem>
                    ))}
                  <MenuItem value="add">
                    <span className={classes.dFlex}>
                      <img src={`./images/plus.svg`} alt="Bettr Coffee" />{" "}
                      Add&nbsp;
                      <span> new card</span>
                    </span>
                  </MenuItem>
                </Select>
                {isFormValidated && cardId === "" && (
                  <p className="errorMessage">This field is required.</p>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className={classes.btnContent}>
              <BorderBtn name={"Cancel"} clickHandler={discardChanges} />
              <DrawerRightCredits
                creditSuccess={creditSuccess}
                amount={amount}
                card_id={cardId}
                isValidated={isFormValidated}
                paymentOption={paymentOption}
                handleValidation={handleFormValidation}
              />
            </div>
          </div>
        </div>
        {open && (
          <ModalBox
            open={open}
            handleClose={() => setOpen((pre) => !pre)}
            handleDelete={() => removeCard(cardId.card_id)}
            heading="Delete Card"
            paragraph="Are you sure want to delete save card?"
          />
        )}
      </div>
    </>
  );
};
export default withStyles(Styles)(Credits);

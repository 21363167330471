const serverURL = {
  login: "/api/v1/b2b/users/signin",
  signup: "/api/v1/b2b/users/signup",
  forgotpassword: "/api/v1/b2b/users/forgot-password",
  resend: "/api/v1/b2b/users/resend-email-verify",
  setpassword: "/api/v1/b2b/users/set-password",
  verifyemail: "/api/v1/verify-email",
  verifymobile: "/api/v1/verify-mobile",
  logout: "/api/v1/b2b/users/signout",
  company: `/api/v1/b2b/users/company`,
  employees: "/api/v1/b2b/users/company",
  faqs: "/api/v1/b2b/faq/categories",
  faqsItem: "/api/v1/b2b/faqs",
  setting: "/api/v1/b2b/users/settings",
  dashboard: "/api/v1/b2b/users/company-dashboard",
  creditsInfo: "/api/v1/b2b/users/company/credits",
  addCard: "/api/v1/b2b/users/cards",
  getTransactions: "/api/v1/b2b/users/company/",
  employeeImport: "/api/v1/b2b/users/company/1/employee-import",
  getOrderDetail: "api/v1/b2b/users/company/orders/",
  companyModes: "/api/v1/b2b/users/company/",
  configurations: "/api/v1/configurations/b2b",
  deliveryPickup: "/api/v1/b2b/users/dashboard/delivery-pickup-graph",
  creditUsage: "/api/v1/b2b/users/dashboard/credit-usage-graph",
  userFrequency: "/api/v1/b2b/users/dashboard/user-frequency-graph",
  topFive: "/api/v1/b2b/users/dashboard/top-five-graph",
  verifyUen: "/api/v1/b2b/verify-uen",
  details: "/api/v1/b2b/users/details",
  companyname:"/api/v1/verify-company",
};
export default serverURL;

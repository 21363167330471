import * as moment from "moment";
import CryptoJS from "crypto-js";
import constant from "../constant";
export const encryptValue = (normalString) => {
  var key = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  var iv = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  var encrypted = CryptoJS.AES.encrypt(normalString, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const decryptValue = (normalString) => {
  var key = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  var iv = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(normalString.toString(), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText.toString();
};

export const convertDDMMYYY = (response) => {
  const yourDate = new Date(response)?.toString();
  const dmyDate = moment(yourDate)?.format("DD MMM YYYY h:mm A");
  return dmyDate;
};

export const convertDDMMYYYDate = (response) => {
  const yourDate = new Date(response)?.toString();
  const dmyDate = moment(yourDate)?.format("DD MMM YYYY");
  return dmyDate;
};
export const convertDDMMYYYDateDay = (response) => {
  const yourDate = new Date(response)?.toString();
  const dmyDate = moment(yourDate)?.format("DD MMM YYYY dddd");
  return dmyDate;
};

export const debounce = function (fn, delay) {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, arguments);
    }, delay);
  };
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!email || regex?.test(email) === false) {
    return true;
  }
  return false;
};
export const validatePhone = (phone) => {
  const toCheck = phone?.charAt(0);
  const allowedNumber = ["6", "8", "9"];
  const found = allowedNumber?.filter((number) => number == toCheck);
  if (phone?.length < 8 || phone?.length > 8 || found?.length === 0) {
    return true;
  } else return false;
};
export const validateName = (name) => {
  if (name?.length < 3) return true;
  else return false;
};
export const validateUEN = (uen) => {
  if (uen?.length < 9 || uen?.length > 10) return true;
  else return false;
};
export const validatePassword = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#$@!%&*?^()_+|])[A-Za-z\d#$@!%&*?()_+|~^]{8,}$/;
  if (!password || regex.test(password) === false) {
    return true;
  }
  return false;
};
export const validateConfirmPassword = (password, confirmPassword) => {
  if (confirmPassword !== password) return true;
  else return false;
};
export const validateAmount = (amount) => {
  if (amount < 10) return true;
  else return false;
};
export const validateRequired = (value) => (value === "" ? true : false);

export const validatePostalCode = (code) => {
  if (code.length < 6 || code.length > 6) return true;
  else return false;
};

// export const validateOptional = (optional) => {
//   if (optional !== "" && optional) return true;
//   else return false;
// };

export const supplyDateFormat = (date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  date = yyyy + "-" + mm + "-" + dd;
  return date;
};

export const unauthenticatedInterceptors = (date) => {
  console.log("date", date);
};

export const reloadHandler = (access) => {
  console.log("access", access);
  if (access === 401) {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }
};

export const displayAmountFormat=(value)=>{
  return typeof value =="number" ? (value?.toString()?.includes(".0")?value?.toString()?.split(".")[0]:value):(value?.includes(".0")?value?.split(".")[0]:value)

  }
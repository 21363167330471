import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paginator from "../../component/Paginator/Paginator";
import TableRow from "@mui/material/TableRow";
import StrConstent from "../../common/StrConstent";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpendPolicyHistory,
  spendPolicyCreditAllocationHistory,
} from "../../redux/actions/SpendPolicy/SpendPolicyAction";
import { convertDDMMYYY } from "../../common/utils/utils";
import Loader from "react-spinner-loader";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SpendPolicyRowTable from "../../component/SpendPolicyRowTable/SpendPolicyRowTable";
const SpendPolicyHistory = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [pageSpendPolicy, setPageSpendPolicy] = useState(1);
  const [pageCreditAllocation, setPageCreditAllocation] = useState(1);
  const spendHistory = useSelector((state) => state.spendPolicyHistory);
  const { spendPolicyHistory, loading } = spendHistory;
  const { spendPolicyCreditAllocation, loading: loadingCredit } = useSelector(
    (state) => state?.spendPolicyCreditAllocatedHistory
  );
  const [value, setValue] = useState("1");
  const tabsHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pageCreditAllocationHandle = (event, value) => {
    setPageCreditAllocation(value);
  };

  const pageSpendPolicyHandle = (event, value) => {
    setPageSpendPolicy(value);
  };
  useEffect(() => {
    dispatch(getSpendPolicyHistory(pageSpendPolicy));
  }, [pageSpendPolicy]);

  useEffect(() => {
    dispatch(spendPolicyCreditAllocationHistory(pageCreditAllocation));
  }, [pageCreditAllocation]);

  return (
    <>
      {(loading || loadingCredit) && (
        <Loader
          Style={{ zIndex: "9999" }}
          show={loading || loadingCredit}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <div className={classes.empOuter}>
        <div className={classes.topBox1}>
          <Heading
            heading={StrConstent.SpendPolicyHistory.headerTitle}
            description={StrConstent.SpendPolicyHistory.headerDesc}
          />
          <CreditsInfo />
        </div>
        <div className={classes.tabsBox}>
          <TabContext value={value}>
            <TabList
              onChange={tabsHandleChange}
              aria-label="lab API tabs example"
              className={classes.tabsList}
            >
              <Tab label="Credit allocation" value="1" />
              <Tab label="Spend Policy" value="2" />
            </TabList>

            <TabPanel value="1" className={classes.pr0}>
              <div className={classes.tableOuter}>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell> Type </TableCell>
                          <TableCell> Amount </TableCell>
                          <TableCell> Time </TableCell>
                          <TableCell> Employee Count</TableCell>
                          <TableCell className={classes.txtCenter}>
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {spendPolicyCreditAllocation?.data?.allocated_credits
                        ?.length !== 0 ? (
                        <TableBody>
                          {spendPolicyCreditAllocation?.data?.allocated_credits?.map(
                            (data, index) => {
                              return (
                                <SpendPolicyRowTable
                                  key={index}
                                  rowData={data}
                                />
                              );
                            }
                          )}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div className={classes.noData}>
                                <h2>No data</h2>
                                <p>Oops, there's nothing to show here.</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Paper>
                <Paginator
                  pageData={spendPolicyCreditAllocation?.data?.pagination}
                  handleChangeValuePage={pageCreditAllocationHandle}
                  currentPage={pageCreditAllocation}
                />
              </div>
            </TabPanel>
            <TabPanel value="2" className={classes.pr0}>
              <div className={classes.tableOuter}>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table" className="tableLayout">
                      <TableHead>
                        <TableRow>
                          <TableCell> Created </TableCell>
                          <TableCell> Categories </TableCell>
                          <TableCell> Modes </TableCell>
                          <TableCell> Days </TableCell>
                        </TableRow>
                      </TableHead>
                      {spendPolicyHistory?.data?.spend_policy.length !== 0 ? (
                        <TableBody>
                          {spendPolicyHistory?.data?.spend_policy?.map(
                            (data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {convertDDMMYYY(data?.date_created)}
                                </TableCell>
                                <TableCell>
                                  {data?.allowed_categories}
                                </TableCell>
                                <TableCell>{data?.allowed_mode}</TableCell>
                                <TableCell>{data?.allowed_days.replaceAll(",",", ")}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={4}>
                              <div className={classes.noData}>
                                <h2>No data</h2>
                                <p>Oops, there's nothing to show here.</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Paper>
                <Paginator
                  pageData={spendPolicyHistory?.data?.pagination}
                  handleChangeValuePage={pageSpendPolicyHandle}
                  currentPage={pageSpendPolicy}
                />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(SpendPolicyHistory);

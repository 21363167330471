import React, { useEffect, useState } from "react";
import Navigation from "./Navigation/Navigation";
import Message from "./component/Message/Message";
const App = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const mobileWidth = () => {
    setDesktop(window.innerWidth > 768);
  };
  useEffect(() => {
    window.addEventListener("resize", mobileWidth);
    return () => window.removeEventListener("resize", mobileWidth);
  }, []);
  return <div className="App">{isDesktop ? <Navigation /> : <Message />}</div>;
};

export default App;

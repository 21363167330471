export const Styles = Theme => ({
    borderBtn: {
        "& button": {
            padding: "12px 24px",
            background: "#E3DDD2",
            color: "#3F4444",
            fontSize: "20px",
            lineHeight: "20px",
            fontWeight: "400",
            fontFamily: "'Bebas Neue', serif",
            margin:"0 0 0 15px",
            letterSpacing:"0",
            whiteSpace: "nowrap",
            borderRadius: "32px",
            // minWidth:"150px",
            "&:hover": {
                background: "#E3DDD2",
            },
            "@media (max-width: 900px)": {
                fontSize: "18px",
                lineHeight: "18px",
            },
        },
    }
})
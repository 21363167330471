export const Styles = (theme) => ({
  alertSuccess: {
    "& > div > div": {
      backgroundColor: "#fff",
      margin: "0 10px",
      boxShadow: "0px 5px 10px 5px rgba(0, 0, 0, 0.05)",
      borderLeft: "4px solid #3CB44B",
      borderRadius: "5px",
      padding: "0 10px",
    },
  },
  alertInfo: {
    "& > div > div": {
      backgroundColor: "#fff",
      margin: "0 10px",
      boxShadow: "0px 5px 10px 5px rgba(0, 0, 0, 0.05)",
      borderLeft: "4px solid #3699FF",
      borderRadius: "5px",
      padding: "0 10px",
    },
  },
  alertUnSuccess: {
    "& > div > div": {
      backgroundColor: "#fff",
      margin: "0 10px",
      boxShadow: "0px 5px 10px 5px rgba(0, 0, 0, 0.05)",
      borderLeft: "4px solid #FF4C25",
      borderRadius: "5px",
      padding: "0 10px",
    },
  },
  alertInner: {
    "& > div": {
      "&:nth-child(1)": {
        display: "none",
      },
    },
    "& svg": {
      color: "#000",
    },
  },
  alertBox: {
    display: "flex",
    alignItems: "center",
    minWidth: "300px",
  },
  imgContent: {
    maxHeight: "30px",
    maxWidth: "30px",
    objectFit: "cover",
    "& img": {
      height: "auto",
      width: "auto",
    },
  },
  txtContent: {
    padding: "0 10px",
    "& h3": {
      color: "#3F4444",
      fontSize: "13px",
      lineHeight: "15px",
      fontWeight: "500",
      fontFamily: "'Bebas Neue', serif",
      margin: "5px 0",
    },
    "& p": {
      color: "#3F444480",
      fontSize: "13px",
      lineHeight: "14px",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif !important",
      margin: "5px 0",
    },
  },
});

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import YellowBtn from '../YellowBtn/YellowBtn';
const ModalBox = (props) => {
    const { classes, open, handleClose, handleDelete, heading, paragraph } = props
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box className={classes.modalContent}>
                    <div className={classes.modalHeader}>
                        <h2 id="modal-modal-title">{heading}</h2>
                        {/* <Button onClick={handleClose}><CloseIcon /></Button> */}
                    </div>
                    <DialogContent className={classes.p0}>
                        {/* <DialogContentText id="alert-dialog-description"> */}
                        <div id="modal-modal-description" className={classes.modalBody}>
                            <p>{paragraph}</p>
                        </div>
                        {/* </DialogContentText> */}
                    </DialogContent>
                    <div
                        className={classes.buttonContent}>
                        {/* <Button onClick={handleClose} className={classes.cancelBtn}>Cancel</Button> */}
                        <BorderBtn clickHandler={handleClose}  name={"Cancel"} />
                        <YellowBtn clickHandler={handleDelete} name={"CONFIRM"}></YellowBtn>
                        {/* <Button onClick={handleDelete}>CONFIRM</Button> */}
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default withStyles(Styles)(ModalBox);
export const Styles = Theme => ({
    p0: {
        padding: "0 !important"
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        background: '#fff !important',
        borderRadius: "10px",
        padding: "25px",
        border: "none",
        outline: "none",
    },
    modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "5px",
        "& h2": {
            color: "#3F4444",
            fontSize: "30px",
            lineHeight: "30px",
            fontWeight: "400",
            fontFamily: "'Bebas Neue', serif",
            margin: "0"
        },
    },
    modalBody: {
        padding: "10px 0",
        "& p": {
            color: "#3F4444BF",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "400",
            fontFamily: "'Noto serif', serif",
            margin: "0",
        }
    },
    buttonContent: {
        padding: "0 !important",
        display:"flex !important",
        justifyContent:"center !important",
        "& > div ": {
            width: "100%",
            minWidth:"150px",
        },
        "& :nth-child(2)":{
            marginLeft:"15px",
        },
        "& button": {
            width: "100%",
            margin: "0",
        },
    },
    cancelBtn: {
        background: "#e3ddd2",
        "& :hover": {
            background: "#e3ddd2",
        }
    }
})

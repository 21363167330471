import { ActionType } from "../../ActionTypes";

export const topUpHistoryListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.TOPUP_HISTORY_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.TOPUP_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        scusses: true,
        topuphistory: action.payload,
      };
    case ActionType.TOPUP_HISTORY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cardsListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CARDS_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.CARDS_LIST_SUCCESS:
      return { ...state, loading: false, scusses: true, cards: action.payload };
    case ActionType.CARDS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const topupCardReducer = (state = {}, action) => {
  switch (action.type) {
      case ActionType.TOP_UPS_DETAILS_REQUEST:
      return { loading: true };
    case ActionType.TOP_UPS_DETAILS_SUCCESS:
      return {loading: false, scusses: true, messages: action.payload};
    case ActionType.TOP_UPS_DETAILS_FAIL:
      return {loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {}
    default:
      return state;
  }
};

export const topupCardWithCorporateReducer = (state = {}, action) => {
  switch (action.type) {
      case ActionType.TOP_UPS_WITH_CORPORATE_REQUEST:
      return { loading: true };
    case ActionType.TOP_UPS_WITH_CORPORATE_SUCCESS:
      return {loading: false, scusses: true, messageCorporate: action.payload};
    case ActionType.TOP_UPS_WITH_CORPORATE_FAIL:
      return {loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {}
    default:
      return state;
  }
};

export const deleteCardReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.DELETE_LIST_REQUEST:
      return {loading: true };
    case ActionType.DELETE_LIST_SUCCESS:
      return {loading: false, scusses: true, messageDlt: action.payload };
    case ActionType.DELETE_LIST_FAIL:
      return {loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {}
    default:
      return state;
  }
};


export const saveCardReducer = (state = {}, action) => {
  switch (action.type) {
      case ActionType.SAVE_CARD_REQUEST:
      return { loading: true };
    case ActionType.SAVE_CARD_SUCCESS:
      return {loading: false, scusses: true, messages: action.payload};
    case ActionType.SAVE_CARD_FAILURE:
      return {loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {}
    default:
      return state;
  }
};

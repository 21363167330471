import React from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Home/Dashboard";
import Employees from "../pages/Employees/Employees";
import Transactions from "../pages/Transactions/Transactions";
import TransactionsOrderDetail from "../pages/TransactionsOrderDetail/TransactionsOrderDetail";
import Credits from "../pages/Credits/Credits";
import CreditHistory from "../pages/CreditHistory/CreditHistory";
import SpendPolicyPage from "../pages/SpendPolicy/Spendpolicy";
import SpendPolicyHistory from "../pages/SpendPolicyHistory/SpendPolicyHistory";
import CompanyProfilePage from "../pages/CompanyProfile/CompanyProfile";
import LoginPage from "../pages/Login/Login";
import Forgot from "../pages/Forgot/Forgot";
import ThankYouMessage from "../pages/ThankYouMessage/ThankYouMessage";
import SignUpPage from "../pages/SignUp/SignUp";
import SetPassword from "../pages/SetPassword/SetPassword";
import Setting from "../pages/Setting/Setting";
import PrivateRoutes from "../common/PrivateRoutes";
import Faqs from "../pages/Faqs/Faqs";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import EmailVerify from "../pages/VerifyPage/EmailVerify";
const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
          exact
        ></Route>
        <Route path="/login" element={<LoginPage />} exact />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/thankYou" element={<ThankYouMessage />} />
        <Route path="/email-verify/:id" element={<EmailVerify />} />
        <Route path="/set-password/:password_token" element={<SetPassword />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route
          path="/settings"
          element={
            <PrivateRoutes>
              <Setting />
            </PrivateRoutes>
          }
        />
        <Route
          path="/employees"
          element={
            <PrivateRoutes>
              <Employees />
            </PrivateRoutes>
          }
        />
        <Route
          path="/transactions"
          element={
            <PrivateRoutes>
              <Transactions />
            </PrivateRoutes>
          }
        />
        <Route
          path="/order-detail"
          element={
            <PrivateRoutes>
              <TransactionsOrderDetail />
            </PrivateRoutes>
          }
        />
        <Route
          path="/credits"
          element={
            <PrivateRoutes>
              <Credits />
            </PrivateRoutes>
          }
        />
        <Route
          path="/credit-history"
          element={
            <PrivateRoutes>
              <CreditHistory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/spend-policy"
          element={
            <PrivateRoutes>
              <SpendPolicyPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="/spend-policy-history"
          element={
            <PrivateRoutes>
              <SpendPolicyHistory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/faqs"
          element={
            <PrivateRoutes>
              <Faqs />
            </PrivateRoutes>
          }
        />
        <Route
          path="/company-profile"
          element={
            <PrivateRoutes>
              <CompanyProfilePage />
            </PrivateRoutes>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default Navigation;

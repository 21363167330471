export const Styles = Theme => ({
    creditsBox: {
        width: "auto",
        display: "table",
        marginLeft: "auto",
        "& ul": {
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            boxSizing: "border-box",
            padding: "12px 0",
            borderRadius: "10px",
            marginLeft: "auto",
            background:"#F1EEDB",
        },
        "& li": {
            "& > div": {
                padding: "0 10px",
                position: "relative",
                "& ::after": {
                    content: "''",
                    height: "40px",
                    width: "1px",
                    display: "block",
                    background: "#DDDDDD",
                    position: "absolute",
                    right: "0",
                    top: "5px",
                },
            },
            "&:last-child > div": {
                "& ::after": {
                    content: "''",
                    display: "none",
                },
            },
            "& h3": {
                fontSize: "18px",
                lineHeight:"18px",
                margin: "0",
            },
        },
    },

    btBox: {
        display: "flex",
        alignItems: "flex-start",
        "& img": {
            maxWidth: "15px",
            height: "auto",
            marginRight: "5px",
        },
        "& p": {
            fontSize: "12px",
            lineHeight: "12px",
            color: "rgba(63, 68, 68, 0.5)",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            margin: "0 0 5px",
            textTransform:"uppercase",
            // whiteSpace: "nowrap",
        },
    },
})
export const Styles = Theme => ({
    empOuter: {
        padding: "20px 25px",
    },
    topBox: {
        display: "flex",
        alignItems: "center",
        padding: "0 0 40px",
        justifyContent:"space-between",
    },
    formInner: {
        padding: "10px 0",
    },
    formControl: {
        margin: "0 0 20px",
    },
    dFlex: {
        display: "flex",
        "& > div": {
            width: "50%",
            "&:nth-child(2)":{
                paddingLeft:"20px",
            },
        },
    },
    errMessage: {
        color: "#C80000",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontFamily: "'Noto Serif', serif",
        display: "block",
        paddingTop: "2px",
    },
    btnContent: {
        margin:"20px 0 10px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    yellowBtn: {
        "& button": {
            padding: "12px 24px",
            background: "#FFC200",
            color: "#3F4444",
            fontSize: "20px",
            lineHeight: "20px",
            fontWeight: "400",
            fontFamily: "'Bebas Neue', serif",
            margin:"0 0 0 15px",
            letterSpacing:"0",
            whiteSpace: "nowrap",
            borderRadius: "32px",
            // minWidth:"150px",
            "&:hover": {
                background: "#FFC200",
            },
        },
    },
      passwordBox: {
        position: "relative",
        "& svg": {
          position: "absolute",
          right: "10px",
          top: "50%",
          color: "#3F444480",
          height: "20px",
          width: "25px",
          cursor: "pointer",
          transform: "translate(-50%, -50%)",
        },
        "& input":{
            paddingRight:"55px",
          }
      },
})


export const Styles = Theme => ({
    root: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
    },
    container: {
        padding: "0 2rem",
        height: "auto",
        width: "100%",
        display: "table",
        "& h1": {
            fontWeight: "400",
            margin: 0,
            color: "#3F4444",
            fontSize: '35px',
            lineHeight: '35px',
            textTransform: "uppercase",
            fontFamily: "'Bebas Neue', serif",
            textAlign: "center",
            "@media (max-width: 420px)": {
                fontSize: '25px',
                lineHeight: '25px',
            },
        },
    },
    imgContent: {
        height: "auto",
        maxWidth: "120px",
        margin:"0 auto 25px",
        "@media(max-width:475px)":{
            maxWidth: "80px",
        },
        "& img": {
            height: "100%",
            width: "100%",
        }
    }
})

import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import AlertMessage from "../AlertMessage/AlertMessage";
import { sendEmailResendEmail, userForGotPassword } from "../../redux/actions/UserActions";
import {
  encryptValue,
  validateEmail,
  validateRequired,
} from "../../common/utils/utils";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import Loader from "react-spinner-loader";
import { ActionType } from "../../redux/ActionTypes";
import StrConstent from "../../common/StrConstent";
import LogoContainer from "../LogoContainer/LogoContainer";
const ForgotForm = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const { forgotData, success, loading, error } = useSelector(
    (state) => state.forgot
  );
  const [isOpenres,setIsopenres]=useState(false)
  const resendEmailLink=useSelector(state=>state?.resendEmailLink)
  const forg = useSelector(
    (state) => state.forgot
  );
  const handleCloseresend=()=>{
   
    setIsopenres(false)
dispatch({type:ActionType.TOPUP_MESSAGE_RESET})

  }
  
  useEffect(() => {
    if (success || error) {
      setIsOpen(true);
      // setTimeout(() => {
      //   setIsOpen(false);
      //   dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
      // }, 30000);
    }
  }, [success, error]);
console.log("resendEmailLink",resendEmailLink?.resetData?.message
)
  const handleForgotPassword = () => {
    if (validateEmail(email) || validateRequired(email))
      return setInvalid(true);
    else {
      let payLoad = {
        email: encryptValue(email),
      };
      dispatch(userForGotPassword(payLoad), setIsOpen(true), setInvalid(false));
    }
  };

  const handleClose = () => {
    dispatch({ type: ActionType.TOPUP_MESSAGE_RESET }, setIsOpen(false));
  };
  console.log("error", forg,forgotData)
  useEffect(() => {
    if (success) return setEmail("");
  }, [forgotData]);

  const handleclick=()=>{
    dispatch(
      sendEmailResendEmail({ email: email })                
    )
  }
  useEffect(()=>{
    setIsopenres(true)
  },[resendEmailLink?.resetData?.message])

  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {
        resendEmailLink?.resetData?.message
        && (
          <AlertMessage
          handleClose={handleCloseresend}
          description={resendEmailLink?.resetData?.message}
          title="Success"
          type="Success"
          isopen={isOpenres}
          url="./images/success.svg"
        />
        )
      }
      {success && (
        <AlertMessage
          handleClose={handleClose}
          description={forgotData?.message}
          title="Success"
          type="Success"
          isopen={isOpen}
          url="./images/success.svg"
        />
      )}
      {error && error !== "This email is not verified. Please verify your email first." && (
        <AlertMessage
          handleClose={handleClose}
          description={error}
          title="Error"
          type="Error"
          isopen={isOpen}
          url="./images/error.svg"
        />
      )}
      <form action="#" className={classes.form} onSubmit={handleForgotPassword}>
        <div className={classes.logoContainer}>
          <LogoContainer
            heading={StrConstent.Forgot.heading}
            goUrl={"/login"}
            urlTxt={"Back to Login"}
          />
        </div>
        <p className={classes.m0}>Enter your registered email address below to reset your password.</p>
        <div className={classes.formControl}>
          <FormLabel>Email Address</FormLabel>
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Enter your email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <p className="errorMessage">
            {!email && email == ""
              ? ""
              : email && email != ""
              ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
                ? ""
                : "Information entered is invalid. Please try again."
              : ""}
          </p> */}
          {error == "This email is not verified. Please verify your email first." && (
            <p className={classes.errorMessage}>
              This email is not verified. Please verify your email first. <br />
             <span 
              onClick={()=>handleclick()}
              >Resend verification email</span>           
            </p>
          )}
          {invalid && email === "" ? (
            <p className="errorMessage">The field is required.</p>
          ) : (
            <div></div>
          )}
          {email !== "" && validateEmail(email) ? (
            <p className="errorMessage">
              Information entered is invalid. Please try again.
            </p>
          ) : null}
        </div>
        <div className={classes.buttonCls}>
          <div className={classes.yellowBtn}>
            <Button onClick={handleForgotPassword}>SUBMIT</Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default withStyles(Styles)(ForgotForm);

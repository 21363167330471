export const Styles = Theme => ({
    empOuter: {
        padding: "20px 25px",
    },
    topBox1: {
        display: "flex",
        alignItems: "flex-start",
        padding: "0 0 40px",
        justifyContent: "space-between",
    },
    topBox2: {
        display: "flex",
        alignItems: "flex-start",
        padding: "0 0 30px",
        justifyContent: "space-between",
        "@media (max-width: 991px)": {
            flexDirection: "column",
        },
    },
    actionBox: {
        "& button": {
            background: "transparent",
            padding: "0",
            minWidth: "auto",
            marginRight: "5px",
        },
    },
    txtCenter: {
        textAlign: "center !important",
    },
    customTooltip: {
        position: "relative",
        display: "flex",
        fontWeight: "700 !important",
        "& img": {
            marginLeft: "5px",
        },
    },
    tooltipBox: {
        "& div:nth-child(2)": {
            overflowY: "unset",
            overflowX: "unset",
            background: "red",
        }
    },
    tooltiptext: {
        background: "#fff",
        padding: "12px",
        borderRadius: "8px",
        position: "relative",
        maxWidth: "250px",
        overflowY: "unset",
        overflowX: "unset",
        "&:after": {
            content: "''",
            position: "absolute",
            top: "100%",
            left: "10%",
            marginLeft: "-5px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "#fff transparent transparent transparent",
        },
        "& b": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444",
            display: "block",
        },
        "& span": {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444B2",
            display: "block",
            padding: "5px 0 0",
        },
    },
    toolTipcontent: {
        bacakgroundColor: "#fff",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
    },

    btnContent: {
        width: "25%",
        display: "flex",
        justifyContent: "flex-end",
        "@media (max-width: 991px)": {
            width: "100%",
            margin: "20px 0 0 auto",
        },
    },
    downloadBtn: {
        padding: "12px 24px",
        background: "#FFC200",
        color: "#3F4444",
        fontSize: "20px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Bebas Neue', serif",
        margin: "0 0 0 15px",
        letterSpacing: "0",
        whiteSpace: "nowrap",
        borderRadius: "32px",
        textDecoration: 'none',
        // minWidth:"150px",
        "&:hover": {
            background: "#FFC200",
            color: "#3F4444 !important",
        },
        "@media (max-width: 900px)": {
            fontSize: "18px",
            lineHeight: "18px",
        },
    },
    noData: {
        height: "100%",
        minHeight: "450px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "750px",
        margin: "auto",
        padding: "0 20px",
        "@media(max-width:991px)": {
            minHeight: "250px",
        },
        "& h2": {
            fontSize: "44px",
            lineHeight: "48px",
            fontWeight: "400",
            marginBottom: "0",
            "@media(max-width:991px)": {
                fontSize: "35px",
                lineHeight: "40px",
            },
        },
        "& p": {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "400",
            color: "#3F444480",
            wordBreak: "break-word",
            textAlign:"center",
        }
    },
})
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
import { CountdownTimer } from "../../View/CountdownTimer";
import sucesssss from './success.svg';
import {
  emailVerify,
  sendEmailResendEmail,
} from "../../redux/actions/UserActions";
function EmailVerify(props) {
  const { classes } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const TWO_MIN_IN_MS = 120000;
  const NOW_IN_MS = new Date().getTime();
  const [disable, setDisable] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const TimeAfterTowMins = NOW_IN_MS + TWO_MIN_IN_MS;
  const [isVerified, setIsVerified] = useState(false);
  const data = useSelector((state) => state?.emailVerify);
  const resendEmail = useSelector((state) => state?.resendEmailLink);
  useEffect(() => {
    dispatch(emailVerify(id));
  }, [id]);
  console.log("token:", data?.emailVerify?.data?.user_data?.password_token);
  console.log("🚀 ( ͡◉ ͜ʖ ͡◉) data", data?.error?.message);
  useEffect(() => {
    if (resendEmail?.error) return setIsVerified(true);
  }, [resendEmail]);
  console.log("isVerified",data?.success,data?.error?.message)
  return (
    <div className={classes.thankOuter}>
      {/* {data?.success && (
        <AlterMessage
          description={data?.emailVerify?.message}
          handleClose={() => setIsEmail(false)}
          url={"../images/success.svg"}
          title={"Success"}
          type={"Success"}
          isopen={isEmail}
        />
      )} */}

      {/* {data?.error && (
        <AlterMessage
          handleClose={() => setIsEmail(false)}
          description={data?.error?.message}
          url={"../images/error.svg"}
          isopen={isEmail}
          title={"Error"}
          type={"Error"}
        />
      )} */}

      {resendEmail?.success && (
        <AlterMessage
          description={resendEmail?.resetData?.message}
          handleClose={() => setIsResend(false)}
          url={"../images/success.svg"}
          isopen={isResend}
          title={"Success"}
          type={"Success"}
        />
      )}
      {resendEmail?.error && !isVerified && (
        <AlterMessage
          handleClose={() => setIsResend(false)}
          description={resendEmail?.error}
          url={"../images/error.svg"}
          isopen={isResend}
          title={"Error"}
          type={"Error"}
        />
      )}
      {data?.success ? (
        <div className={classes.thankBox}>
          <div className={classes.content}>
            <CheckCircleIcon />
          </div>
          {/* <h2>{data?.emailVerify?.message}</h2> */}
          <h2>YOUR EMAIL HAS BEEN VERIFIED</h2>
          <p>
          You're one step closer to getting your coffee fix!
          </p>
          <YellowBtn
            name="Set password"
            clickHandler={() =>
              navigation(
                `/set-password/${data?.emailVerify?.data?.user_data?.password_token}`
              )
            }
          />
        </div>
      ) : null}

      {isVerified ? (
        <div className={classes.thankBox}>
          <div className={classes.content}>
            <CheckCircleIcon />
          </div>
          {/* <h2>YOUR EMAIL HAS BEEN VERIFIED</h2>
          <p>You're good to go to getting your coffee fix!</p> */}
          <h2>YOU'RE GOOD TO GO!</h2>
          <p>Let's get your coffee fix.</p>
          <YellowBtn
            name="LOGIN"
            clickHandler={() => navigation("/login")}
          />
        </div>
      ) : null}

      {
        data?.error && (data?.error?.message=="User email already verified."||data?.error?.message=="Your email has been verified. Please login.") &&(
          <div className={classes.thankBox}>
            <img src={sucesssss} alt="bettr coffee"/>
          <h2>YOU'RE GOOD TO GO!</h2>
          <p>
           Let's get your coffee fix.
          </p>
          <YellowBtn
            name="LOGIN"
            disabled={disable}
            clickHandler={() =>
              navigation(`/login`)
            }
          />
          {disable ? (
            <CountdownTimer targetDate={TimeAfterTowMins} />
          ) : (
            <div></div>
          )}
        </div>
        )
      }
      {data?.error && ( data?.error?.message=="The link is valid for 60 minutes so please complete your account set up within this time. Request a new link below."||data?.error?.message=="Invalid token, token already used. Generate a new token."||data?.error?.message=="Expired token.") && !isVerified ? (
        <div className={classes.thankBox}>
          <h2>THIS LINK HAS EXPIRED</h2>
          <p>
            The link is valid for 60 minutes so please complete your account set up within this time. Request a new link below.
          </p>
          <YellowBtn
            name="RESEND EMAIL VERIFICATION"
            disabled={disable}
            clickHandler={() =>
              dispatch(
                sendEmailResendEmail({ email: data?.error?.data?.email }),
                setIsResend(true),
                setDisable(true),
                setTimeout(() => setDisable(false), 120000)
              )
            }
          />
          {disable ? (
            <CountdownTimer targetDate={TimeAfterTowMins} />
          ) : (
            <div></div>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default withStyles(Styles)(EmailVerify);

import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { convertDDMMYYY, displayAmountFormat } from "../../common/utils/utils";
function SpendPolicyRowTable({ rowData, classes }) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {rowData?.credit_type.substring(0, rowData?.credit_type.length - 2)}
        </TableCell>
        <TableCell>{displayAmountFormat(parseFloat(rowData?.total_credits).toFixed(1))}</TableCell>
        <TableCell>
          {convertDDMMYYY(rowData?.employees[0]?.date_created)}
        </TableCell>
        <TableCell>{rowData?.employees?.length}</TableCell>

        <TableCell className={classes.repRemove}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <div className={classes.actionBox}>
                <Tooltip title="View details" arrow toolTipcontent>
                  <Button>
                    <img src="./images/uparrow.svg" alt="Better coffee" />
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <div className={classes.actionBox}>
                <Tooltip title="View details" arrow toolTipcontent>
                  <Button>
                    <img src="./images/downarrow.svg" alt="Better coffee" />
                  </Button>
                </Tooltip>
              </div>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.expandBox} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.transTableeOuter}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.historyText}>
                      <div className={classes.creditsBox}>
                        <div className={classes.ltBox}>
                          <div className={classes.txtBox}>
                            <ul>
                              {rowData?.employees?.map((data, index) => (
                                <>
                                  <li key={index}>
                                    <div>
                                      {index === 0 && <h4>Employee Name</h4>}
                                      <span>{`${data?.first_name} ${data?.last_name}`}</span>
                                    </div>
                                    <div>
                                      {index === 0 && (
                                        <h4>
                                          {data?.credit_type.substring(
                                            0,
                                            rowData?.credit_type.length - 2
                                          )}{" "}
                                          Amount{" "}
                                        </h4>
                                      )}
                                      <span>
                                        {displayAmountFormat(parseFloat(
                                          data?.total_credits
                                        ).toFixed(1))}
                                      </span>
                                    </div>
                                  </li>
                                </>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default withStyles(Styles)(SpendPolicyRowTable);

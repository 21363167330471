export const Styles = Theme => ({
    searchBox: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "70%",
        marginRight: "25px",
        "@media(max-width:991px)": {
            width: "100%",
        },
        "& img": {
            height: "15px",
            position: "absolute",
            left: "15px",
        },
        "& input": {
            border: "1px solid #fff",
            padding: "14px 130px 14px 40px",
            outline: "none",
            borderRadius: "32px",
            width: "100%",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#3F4444",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            border: "1px solid #fff",
            transition: ".5s ease",
            minHeight:"50px",
            "&::placeholder": {
                fontSize: "12px",
                lineHeight: "12px",
                color: "#3F444480",
                fontWeight: "400",
                fontFamily: "'Noto Serif', serif",
                // textTransform: "uppercase",
            },
            "&:hover": {
                transition: ".5s ease",
                borderColor: "rgba(63, 68, 68, 0.12)",
            },
            "&:active": {
                transition: ".5s ease",
                borderColor: "rgba(63, 68, 68, 0.12)",
            },
        },
        "& button": {
            position: "absolute",
            right: "0",
            padding: "15px 25px 15px",
            background: "#E3DDD2",
            color: "#3F4444",
            fontSize: "18px",
            lineHeight: "20px",
            fontWeight: "400",
            borderRadius: "32px",
            fontFamily: "'Bebas Neue', serif",
            "&:hover": {
                background: "#E3DDD2",
            },
            "@media (max-width: 900px)": {
                fontSize: "18px",
                lineHeight: "18px",
            },
        },
    },

})
export const Styles = (Theme) => ({
  empOuter: {
    padding: "20px 25px",
  },
  topBox1: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 0 40px",
    justifyContent: "space-between",
  },
  creditsOuter: {
    padding: "15px 0",
  },
  headline: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  heading: {
    marginTop: "30px",
  },
  formControl: {
    margin: "0 0 30px",
  },
  txt: {
    "& span": {
      fontSize: "16px",
      fontFamily: "",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#3F4444",
    },
  },
  fieldContainer: {
    position: "relative",
    "& input": {
      padding: "12px 110px 12px 55px",
    },
    "& > span": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#333539",
      fontFamily: "'Bebas Neue', serif",
      "@media (max-width: 900px)": {
        fontSize: "18px",
        lineHeight: "18px",
      },
    },
  },
  firstTxt: {
    left: "30px",
  },
  lastTxt: {
    right: "20px",
    paddingLeft: "5px",
  },
  btnContent: {
    display: "flex",
    width: "100%",
    padding: "60px 0 ",
    justifyContent: "flex-end",
  },
  lt: {
    display: "flex",
    alignItems: "center",
  },
  dbBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "& svg": {
      fontSize: "16px",
      color: "#3F4444",
      margin: "10px 5px 0",
    },
  },
  imgBox: {
    maxWidth: "100px",
    height: "auto",
    padding: "0 5px",
    borderRadius: "5px",
    "& img": {
      height: "auto",
      width: "100%",
    },
  },
  txtContent: {
    paddingLeft: "10px",
    "& span": {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "400",
      color: "#3F4444",
      fontFamily: "'Noto Serif', serif",
      display: "block",
    },
  },
  titleTxt: {
    color: "#3F444480 !important",
    "& span": {
      color: "#3F444480 !important",
    },
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    "& img": {
      paddingRight: "5px",
    },
  },
  creditField: {
    display: "flex",
    alignItems: "center",
  },
});

import { ActionType } from "../../ActionTypes";

export const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_COMPANY_DETAILS_REQUEST:
      return {...state, loading: true };
    case ActionType.GET_COMPANY_DETAILS_SUCCESS:
      return {...state, loading: false, CompanyDetails: action.payload };
    case ActionType.GET_COMPANY_DETAILS_FAIL:
      return {...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.UPDATE_COMPANY_PROFILE_REQUEST:
      return {loading: true };
    case ActionType.UPDATE_COMPANY_PROFILE_SUCCESS:
      return {loading: false, success: true, updateComp:action.payload};
    case ActionType.UPDATE_COMPANY_PROFILE_FAIL:
      return {loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
        return {}
    default:
      return state;
  }
};
import axios from "axios";
import constant from "../common/constant";
export default axios.create({
  baseURL: constant.baseUrl,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Credentials": true,
    Authorization:
      localStorage.getItem("auth_token") == null
        ? ""
        : `Bearer ${localStorage.getItem("auth_token")}`,
  },
});

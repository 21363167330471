export const Styles = (theme) => ({
  m0:{
    margin:"0",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  logoContainer:{
    "& > div":{
      marginBottom:"15px",
    }
  },
  h1: {
    marginTop: "10",
    textTransform: "uppercase",
    fontSize: "44px",
    lineHeight: "48px",
    color: "#111827",
    fontWeight: "500",
    fontFamily: "'Bebas Neue', serif",
  },
  haveAccount: {
    paddingLeft: "5px",
  },
  dFlex: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  logoBettr: {
    width: "90px",
    margin: "0 0 25px",
  },
  formControl: {
    margin: "15px 0",
  },
  form: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    display: "inline-block",
    justifyContent: "left",
    flexDirection: "column",
    padding: "2rem",
    height: "100%",
    borderRadius: "12px",
    width: "100%",
    boxSizing: "border-box",
  },
  yellowBtn: {
    width: "100%",
    "& button": {
      padding: "12px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "0 0 0 0px",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      width: "100%",
      "&:hover": {
        background: "#FFC200",
      },
    },
  },
  errorMessage: {
    fontSize: "14px",
    lineHeight: "19px",
    color: "#DF3F51",
    fontWeight: "400",
    fontFamily: "'Noto Serif', serif",
    margin: "5px 10px 0",
    "& span": {
      textDecorationColor: "#DF3F51 !important",
      textDecoration:"underline",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Noto Serif', serif",
      display: "inline-block",
      cursor: "pointer",
      color: "#DF3F51 !important",
    },
  },
});

export const Styles = Theme => ({
    sidebarOuter: {
        "& > div": {
            // width: "auto !important",
            overflow: "hidden",
            border: "none",
        },
    },
    tabbar: {
        display: "none !important",
        "@media (max-width: 1199px)": {
            display: "block !important",
        },
    },
})
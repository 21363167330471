export const Styles = (Theme) => ({
  thankOuter: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  thankBox: {
    height: "auto",
    width: "100%",
    maxWidth: "500px",
    padding: "0 20px",
    margin: "auto",
    textAlign: "center",
    "& h2": {
      color: "#3F4444",
      fontSize: "30px",
      lineHeight: "30px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "0px 0 0",
      textAlign: "center",
      display: "block",
    },
    "& p": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      fontFamily: "'Noto serif', serif",
      margin: "10px 0 0",
      textAlign: "center",
    },
    "& button": {
      margin: "20px auto 0",
    },
    "& img": {
      height:"50px",
      marginBottom: "10px",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& h4": {
      color: "#3F4444",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: "700",
      fontFamily: "'Noto serif', serif",
      margin: "10px 0 10px 10px",
      textAlign: "center",
    },
    "& svg": {
      color: "#56B55C",
      fontSize: "60px",
      marginBottom: "20px",
    },
  },
});

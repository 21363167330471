import TextField from "@mui/material/TextField";
import {
  validateEmail,
  validatePhone,
  validateRequired,
  validateUEN,
} from "../../common/utils/utils";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StrConstent from "../../common/StrConstent";
import LogoContainer from "../LogoContainer/LogoContainer";
import {
  register,
  sendEmailResendEmail,
  verifyUenAction,
  verifycompanyname,
} from "../../redux/actions/UserActions";
import { Link, useNavigate } from "react-router-dom";
import AlterMessage from "../AlertMessage/AlertMessage";
import { encryptValue } from "../../common/utils/utils";
import Loader from "react-spinner-loader";
import { ActionType } from "../../redux/ActionTypes";
import { verifyCompanyname } from "../../redux/reducers/userReducer";
function SignUpForm(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.removeItem("isSuccess");
  const [steps, setSteps] = useState(1);
  const [isTrue, setIsTrue] = useState(false);
  const [creds, setCreds] = useState({
    company_name: "",
    license_number: "",
    company_size: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });

  const [showPlaceholder, setShowPlaceholder] = useState(
    creds.company_size === "" ? false : true
  );
  const userSignin = useSelector((state) => state.userSignin);
  const { userIn, success, loading, error } = useSelector(
    (state) => state.userRegister
  );

  const { success: resetdataSucces, error: resetdataError } = useSelector(
    (state) => state.resendEmailLink
  );
  const data = useSelector((state) => state?.verifyUen);
  const companynamedata=useSelector((state)=>state?.verifycompany)
  const { userInfo } = userSignin;
  const [emailData, setEmailData] = useState();
  const [inValid, setInValid] = useState(false);

  useEffect(() => {
    if (success || error || resetdataSucces || resetdataError) {
      if (resetdataSucces) {
        navigate("/login");
      } else {
        setIsOpen(true);
        // setTimeout(() => {
        //   setIsOpen(false);
        //   dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
        // }, 30000);
      }
    }
  }, [success, error, resetdataSucces, resetdataError]);

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    dispatch({ type: ActionType.CLEAR_ERROR }, setIsOpen(false));
    // if (reason === "clickaway")
    dispatch({ type: ActionType.TOPUP_MESSAGE_RESET }, setIsOpen(false));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 69) {
      event.preventDefault();
    }
  };
console.log("companynamedata",companynamedata)
  const handlerChangeInput = (e) => {
    const { name, value } = e.target;
    setCreds((pre) => ({ ...pre, [name]: value }));
  };
  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const handleSignSubmit = (e) => {
    e.preventDefault();
    setIsOpen(false);
    dispatch({ type: ActionType.CLEAR_ERROR });
    if (
      creds.first_name === "" ||
      creds.last_name === "" ||
      validateEmail(creds.email) ||
      validatePhone(creds.mobile_number)
    )
      setInValid(true);
    else {
      let payload = {
        company_name: encryptValue(creds.company_name),
        license_number: encryptValue(creds.license_number),
        company_size: encryptValue(creds.company_size),
        first_name: encryptValue(creds.first_name),
        last_name: encryptValue(creds.last_name),
        mobile_number: encryptValue(creds.mobile_number.replace(/ /g, "")),
        email: encryptValue(creds.email),
      };
      dispatch(register(payload));
    }
  };

  const nextHandler = async () => {
    setInValid(false);
    if (creds.company_name === "") setInValid(true);
    else if (validateUEN(creds.license_number)) setInValid(true);
    else if (validateRequired(creds.company_size)) setInValid(true);
    else {
      setInValid(false);
      dispatch(
        verifyUenAction({ license_number: creds.license_number }),
        setIsOpen(true)
      );
      dispatch(
        verifycompanyname({company_name:creds.company_name}),
        setIsOpen(true)
      )
    }
  };

  const resendEmailHandler = () => {
    let payLoad = {
      email: encryptValue(creds.email),
    };
    dispatch(sendEmailResendEmail(payLoad));
    setIsTrue(true);
  };

  const moveToCustomMenu = (url) => {
    // window.location.href = url
    // cancelHandler();
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (userIn?.success) return navigate("/login");
  }, [userIn]);

  useEffect(() => {
    if (data?.userUen?.success && companynamedata?.userUen?.success)
      return dispatch({ type: ActionType.CLEAR_ERROR }, setSteps(2));
  }, [data,companynamedata]);

  return (
    <>
      {loading ||
        (data?.loading && (
          <Loader
            show={loading || data?.loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        ))}
      {success && (
        <AlterMessage
          handleClose={handleClose}
          description={userIn?.message}
          title="Success"
          type="Success"
          isopen={isOpen}
          url="./images/success.svg"
        />
      )}
      {error && (
        <AlterMessage
          handleClose={handleClose}
          description={error}
          title="Error"
          type="Error"
          isopen={isOpen}
          url="./images/error.svg"
        />
      )}
      {resetdataError && (
        <AlterMessage
          handleClose={handleClose}
          description={error}
          title="Error"
          type="Error"
          isopen={isOpen}
          url="./images/error.svg"
        />
      )}
      {data?.error && companynamedata?.error ?(
        <AlterMessage
          handleClose={handleClose}
          description={companynamedata?.error}
          url="./images/error.svg"
          isopen={isOpen}
          title="Error"
          type="Error"
        />
      ):data?.error ?(
        <AlterMessage
        handleClose={handleClose}
        description={data?.error}
        url="./images/error.svg"
        isopen={isOpen}
        title="Error"
        type="Error"
      />
      ):companynamedata?.error ?(
        <AlterMessage
        handleClose={handleClose}
        description={companynamedata?.error}
        url="./images/error.svg"
        isopen={isOpen}
        title="Error"
        type="Error"
      />
      ):null }
      <form action="#" className={classes.form} onSubmit={handleSignSubmit}>
        <LogoContainer
          heading={StrConstent.SignUp.heading}
          goUrl={"/login"}
          urlTxt={"Back to Login"}
        />
        {/* <div className={classes.dFlex}>
          <h1 className={classes.h1}>Sign up</h1>
          <a
            href="#"
            className={classes.haveAccount}
            onClick={() => navigate("/login")}
          >
            I have an account
          </a>
        </div> */}
        {steps == 2 && (
          <div onClick={() => setSteps(1)} className={classes.stepper}>
            <div className={classes.stepperBoxComplete}>
              <span className={classes.stepperCheck}></span>
            </div>
            <div className={classes.stepperBoxActive}>
              <span className={classes.stepperCheck}></span>
            </div>
          </div>
        )}
        {steps === 1 && (
          <div className={classes.stepper}>
            <div className={classes.stepperBoxDefault}>
              <span className={classes.stepperCheck}></span>
            </div>
            <div className={classes.stepperBoxActive}>
              <span className={classes.stepperCheck}></span>
            </div>
          </div>
        )}
        <div className={classes.stepperText}>
          <span onClick={() => setSteps(1)}> Company</span>
          <span> Administrator</span>
        </div>
        {steps === 1 && (
          <>
            <div className={classes.fromControl}>
              <FormLabel>
                Company name<span className="req">*</span>{" "}
              </FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                required
                type="text"
                name="company_name"
                value={creds.company_name}
                placeholder="Enter your company's name"
                onChange={handlerChangeInput}
              />
              {inValid && creds.company_name === "" ? (
                <p className="errorMessage"> This field is required.</p>
              ) : (
                <div></div>
              )}
            </div>
            <div className={classes.fromControl}>
              <FormLabel>
                Unique Entity Number (UEN)<span className="req">*</span>
              </FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                name="license_number"
                value={creds.license_number}
                placeholder="Enter UEN"
                onChange={handlerChangeInput}
              />
              {inValid && validateRequired(creds.license_number) ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <div></div>
              )}
              {inValid &&
              creds.license_number !== "" &&
              validateUEN(creds.license_number) ? (
                <p className="errorMessage">
                  Information entered is invalid. Please try again.
                </p>
              ) : null}
            </div>
            <div className={classes.fromControl}>
              <FormControl fullWidth error>
                <FormLabel>
                  Company size<span className="req">*</span>
                </FormLabel>
                <Select
                  required
                  name="company_size"
                  value={creds.company_size}
                  onChange={handlerChangeInput}
                  onFocus={(e) => setShowPlaceholder(true)}
                  onClose={(e) =>
                    setShowPlaceholder(e.target.value === undefined)
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem
                    value=""
                    className={showPlaceholder && classes.menuItemHidden}
                  >
                    <span>Company size</span>
                  </MenuItem>
                  <MenuItem value={"50"}>{"< 50"}</MenuItem>
                  <MenuItem value={"51-250"}>51-250</MenuItem>
                  <MenuItem value={"251-500"}>251-500</MenuItem>
                  <MenuItem value={"551-1000"}>501-1000</MenuItem>
                  <MenuItem value={"1000"}>{"> 1000"}</MenuItem>
                </Select>
                {inValid && validateRequired(creds.company_size) && (
                  <p className="errorMessage">This field is required.</p>
                )}
              </FormControl>
            </div>
          </>
        )}
        {steps === 2 && (
          <>
            <div className={classes.fromControl}>
              <FormLabel>
                {" "}
                Name<span className="req">*</span>{" "}
              </FormLabel>
              <div className={classes.dFlex}>
                <div className={classes.wid50}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    type="text"
                    name="first_name"
                    value={creds.first_name}
                    placeholder="First name"
                    onChange={handlerChangeInput}
                  />
                  {inValid && validateRequired(creds.first_name) ? (
                    <p className="errorMessage">This field is required.</p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={`${classes.wid50} ${classes.ml15}`}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    type="text"
                    name="last_name"
                    value={creds.last_name}
                    placeholder="Last name"
                    onChange={handlerChangeInput}
                  />
                  {inValid && creds.last_name === "" ? (
                    <p className="errorMessage">This field is required.</p>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.fromControl}>
              <FormLabel>
                Work email<span className="req">*</span>
              </FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                required
                type="email"
                name="email"
                value={creds.email}
                placeholder="Enter your work email address"
                onChange={handlerChangeInput}
              />
              {inValid && validateRequired(creds.email) ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <></>
              )}
              {creds.email !== "" && validateEmail(creds.email) ? (
                <p className="errorMessage">
                  Information entered is invalid. Please try again.{" "}
                </p>
              ) : null}
            </div>
            <div className={classes.fromControl}>
              <FormLabel>
                Contact Number<span className="req">*</span>
              </FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                required
                type="number"
                name="mobile_number"
                value={creds.mobile_number}
                placeholder="Enter your contact number"
                onChange={handlerChangeInput}
                onKeyDown={handleKeyDown}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 8);
                }}
              />
              {inValid && creds.mobile_number === "" ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <div></div>
              )}
              {creds.mobile_number !== "" &&
              validatePhone(creds.mobile_number) ? (
                <p className="errorMessage">
                  Information entered is invalid. Please try again.{" "}
                </p>
              ) : null}
            </div>
          </>
        )}
        <div className={classes.buttonCls}>
          <div
            component="span"
            className={`${classes.spreadBox} ${classes.box}`}
          >
            {steps === 1 ? (
              <div className={classes.yellowBtn}>
                <Button onClick={nextHandler}>Next</Button>
              </div>
            ) : (
              <div className={classes.yellowBtn}>
                {emailData?.data.status == 0 && (
                  <>
                    <p className="errorMessage">{emailData?.data.message}</p>
                    <Button onClick={() => navigate("/login")}>
                      Go to Login
                    </Button>
                  </>
                )}
                {emailData?.data.status == 1 && (
                  <>
                    <p className="errorMessage">{emailData?.data.message}</p>
                    <Button disabled={isTrue} onClick={resendEmailHandler}>
                      Resend Link
                    </Button>
                  </>
                )}
                {emailData?.data.status == 2 && (
                  <p className="errorMessage">
                    Account blocked please contact to admin
                  </p>
                )}
                {emailData?.data.status !== 2 &&
                  emailData?.data.status !== 1 &&
                  emailData?.data.status !== 0 && (
                    <Button onClick={handleSignSubmit}>Sign Up</Button>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className={classes.btTxt}>
          <p>By submitting, you agree with Bettr Group’s</p>
          <div className={classes.policyTxt}>
            {/* <Link to="https://www.order.bettr.coffee/terms-of-service/">Terms of Use</Link>& <Link to="https://www.order.bettr.coffee/privacy-policy/">Privacy Policy</Link> */}
            <Link
              to=""
              onClick={() =>
                moveToCustomMenu(
                  "https://www.order.bettr.coffee/terms-of-service"
                )
              }
            >
              Terms of Use
            </Link>{" "}
            &
            <Link
              to=""
              onClick={() =>
                moveToCustomMenu(
                  "https://www.order.bettr.coffee/privacy-policy"
                )
              }
            >
              Privacy Policy.
            </Link>
          </div>
        </div>
      </form>
    </>
  );
}

export default withStyles(Styles)(SignUpForm);

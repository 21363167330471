export const Styles = (Theme) => ({
  empOuter: {
    padding: "20px 25px",
  },
  topBox: {
    display: "flex",
    alignItems: "center",
    padding: "0 0 40px",
    justifyContent: "space-between",
  },
  traDel: {
    borderBottom: "1px solid #00000026",
    padding: "10px 0",
    margin: "5px 0",
  },
  traDelInner: {
    paddingLeft: "10px",
    margin: "8px 0",
    "& li": {
      display: "flex",
      justifyContent: "space-between",
      "& span": {
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
        color: "#3F444480",
        textAlign: "left",
        display: "inline-block",
      },
    },
  },
  ltTxt: {
    textAlign: "left",
    color: "#3F444480",
  },
  rtTxt: {
    textAlign: "right",
    paddingLeft: "10px",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h2": {
      fontSize: "30px",
      lineHeight: "30px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      color: "#3F4444",
      margin: "5px 0",
    },
    "& h3": {
      margin: "5px 0",
    },
    "& h4": {
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      color: "#333539",
      margin: "5px 0",
      textTransform: "uppercase",
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
      color: "#3F4444",
      margin: "0",
      paddingLeft: "10px",
      "& span": {
        textTransform: "uppercase",
        display: "inline-block",
        padding: "0 10px",
        color: "#3F4444 !important",
      },
    },
  },
  redColor: {
    color: "#DF3F51 !important",
  },
  totalTxt: {
    fontFamily: "'Noto Serif', serif !important",
    fontWeight: "400 !important",
    fontSize: "28px !important",
  },
});

import React, { useEffect, useMemo, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import SearchBox from "../../component/SearchBox/SearchBox";
import StrConstent from "../../common/StrConstent";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-spinner-loader";
import constant from "../../common/constant";
import {
  getFaqCategoryList,
  getFaqListItemCategory,
} from "../../redux/actions/Faqs/FaqAction";
import AlertMessage from "../../component/AlertMessage/AlertMessage";
const Faqs = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const ItemTypingHandler = (searchText) => setSearchOnly(searchText);
  const { faqsItems, error } = useSelector((state) => state.faqItem);
  const { loading, faqs, scusses } = useSelector((state) => state.faqs);
  const [value, setValue] = useState("0");
  const [faqItemloading,setfaqitemloading]=useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [searchOnly, setSearchOnly] = useState("");
  const [showCategory, setShowCategory] = useState(true);
  const [refreshWallet, setRefreshWallet] = useState(false);


useEffect(()=>{
if(faqsItems==undefined){
  setfaqitemloading(true)
}else{
  setfaqitemloading(false) 
}
},[faqsItems])

  const getURLParam = (isCancel) => {
    setRefreshWallet(true);
    return `${constant.baseUrl}/api/v1/b2b/faqs/${value}?search_by=${isCancel ? "" : searchOnly
      }`;
  };

  const handleSubmitSearch = (value, isCancel) => {
    if (value === "0") return;
    else if (searchOnly?.length >= 3)
      return dispatch(
        getFaqListItemCategory(getURLParam(isCancel), setShowCategory(isCancel))
      );
    else if (searchOnly === "" || isCancel)
      return dispatch(
        getFaqListItemCategory(getURLParam(isCancel)),
        setShowCategory(isCancel)
      );
  };

  const cancelHandler = (isCancel) => {
    if (isCancel) {
      handleSubmitSearch(
        faqs?.data?.faq_categories[0]?.faq_categorie_id.toString(),
        isCancel
      );
    } else return;
  };

  useEffect(() => {
    if (scusses && faqs?.data?.faq_categories?.length > 0)
      return setValue(
        faqs?.data?.faq_categories[0]?.faq_categorie_id.toString()
      );
  }, [scusses]);

  useMemo(() => {
    if (value === "0") return;
    else return dispatch(getFaqListItemCategory(getURLParam()));
  }, [value]);

  useEffect(() => {
    dispatch(getFaqCategoryList());
  }, []);
  return (
    <>
      {loading && (
        <Loader
          Style={{ zIndex: "9999" }}
          message="Loading..."
          stack="vertical"
          show={loading}
          type="body"
        />
      )}
      {faqsItems==undefined ? (
        <Loader
          Style={{ zIndex: "9999" }}
          message="Loading..."
          stack="vertical"
          show={faqItemloading}
          type="body"
        />
      )
    :null
    }
      {error && (
        <AlertMessage
          handleClose={() => setIsOpen(false)}
          url="./images/error.svg"
          description={error}
          isopen={isOpen}
          title="Error"
          type="Error"
        />
      )}
      <div className={classes.empOuter}>
        <div className={classes.topBox1}>
          <Heading
            heading={StrConstent.Faqs.headerTitle}
            description={StrConstent.Faqs.headerDesc}
          />
          <CreditsInfo refreshWallet={refreshWallet} />
        </div>
        <div className={classes.topBox}>
          <SearchBox
            styleProps={{ width: "100%", marginRight: 0 }}
            handleSubmitSearch={handleSubmitSearch}
            // placeholderText="TOPICS OR QUESTIONS"
            placeholderText="Search Topic or Qusetion"
            onTypingEvent={ItemTypingHandler}
            onCancel={cancelHandler}
          />
        </div>

        <TabContext value={value}>
          {showCategory ? (
            <div className={classes.tabsBox}>
              {
                faqs?.data?.faq_categories?.length > 0 &&

                <TabList
                  onChange={(event, newValue) => setValue(newValue)}
                  aria-label="lab API tabs example"
                >

                  {value === "0" && <Tab label={""} value={value} />}
                  {faqs?.data?.faq_categories?.map((item, index) => {
                    return (
                      <Tab
                        label={item?.faq_categorie_name}
                        value={item?.faq_categorie_id.toString()}
                        key={index}
                      />
                    );
                  })}
                </TabList>
              }
            </div>
          ) : (
            <div>
              {searchOnly !== "" ? (
                <p>
                  Search results for <b> {searchOnly}</b>
                </p>
              ) : null}
            </div>
          )}
          <TabPanel value={value} className={classes.p0}>
            <div className={classes.accordionBox}>
              {faqsItems?.data?.faqs?.length > 0 ? (
                <>
                  {faqsItems?.data?.faqs?.map((data, index) => (
                    <Accordion
                      expanded={expanded === `panel1aHeader${index}`}
                      onChange={(event, isExpanded) =>
                        setExpanded(
                          isExpanded ? `panel1aHeader${index}` : false
                        )
                      }
                      key={data?.faq_id}
                    >
                      <AccordionSummary
                        className={classes.heading}
                        expandIcon={
                          expanded === `panel1aHeader${index}` ? (
                            <ClearIcon />
                          ) : (
                            <AddIcon />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h3>{data?.faq_title}</h3>
                      </AccordionSummary>
                      <AccordionDetails className={classes.description}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.faq_description,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              ) : (
                <>
                
                {
                  faqsItems!==undefined?<>
               <div className={classes.noData}>
                  <h2>No Data</h2>
                  <p>Oops, there's nothing to show here.</p>
                </div>
                  </>:null
                }
                </>
               
              )}
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default withStyles(Styles)(Faqs);

export const Styles = Theme => ({
    switchBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "150px",
        "& span":{
            color: "#3F4444", 
        },
        "& label":{
            margin:"0 0 !important",
        }
    },
})

export const Styles = (Theme) => ({
  empOuter: {
    padding: "20px 25px",
  },
  topBox1: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 0 40px",
    justifyContent: "space-between",
  },
  dFlex: {
    display: "flex",
  },
  actionBox: {
    display: "flex",
    "& button": {
      background: "transparent",
      padding: "0",
      minWidth: "auto",
      marginLeft: "5px",
    },
  },
  statusBtn: {
    "& button": {
      padding: "6px 8px",
      color: "#3F4444",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
      textTransform: "uppercase",
      borderRadius: "4px",
    },
  },

  toolTipcontent: {
    backgroundColor: "#fff",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    fontFamily: "'Noto Serif', serif",
  },

  noData: {
    height: "100%",
    minHeight: "450px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "750px",
    margin: "auto",
    padding: "0 20px",
    "@media(max-width:991px)": {
      minHeight: "250px",
    },
    "& h2": {
      fontSize: "44px",
      lineHeight: "48px",
      fontWeight: "400",
      marginBottom: "0",
      "@media(max-width:991px)": {
        fontSize: "35px",
        lineHeight: "40px",
      },
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#3F444480",
      wordBreak: "break-word",
      textAlign: "center",
    },
  },
  forCenter: {
    paddingLeft: "22px",
  },
});

import React, { Fragment, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import StrConstent from "../../common/StrConstent";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetail } from "../../redux/actions/Transactions/TransactionAction";
import Loader from "react-spinner-loader";
import { convertDDMMYYYDateDay, displayAmountFormat } from "../../common/utils/utils";

const TransactionsOrderDetail = (props) => {
  const dispatch = useDispatch();
  const orderID = localStorage.getItem("orderId");
  const { loading, orderDetail } = useSelector((state) => state.getOrderDetail);
  // const totalBill =
  //   parseInt(orderDetail?.data?.grand_total) -
  //   parseInt(orderDetail?.data?.discounted_total);
  useEffect(() => {
    dispatch(getOrderDetail(orderID));
  }, []);
  const { classes } = props;

  const htmlString =
    orderDetail?.data?.address?.address_tag +
    "<br/>" +
    orderDetail?.data?.address?.address_line1 +
    " " +
    orderDetail?.data?.address?.address_line2 +
    " " +
    orderDetail?.data?.address?.unit_number +
    " " +
    orderDetail?.data?.address?.postal_code;
  const theObj = { __html: htmlString };

  return (
    <>
      {loading && (
        <Loader
          Style={{ zIndex: "9999" }}
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {!loading && (
        <div className={classes.empOuter}>
          <div className={classes.topBox}>
            <Heading
              heading={StrConstent.TransactionsOrderDetail.headerTitle}
              description={StrConstent.TransactionsOrderDetail.headerDesc}
            />
            <CreditsInfo />
          </div>
          <div className={classes.traDel}>
            <div className={classes.dFlex}>
              <h3>Order</h3>
              <p>{`${orderDetail?.data?.user?.first_name} ${orderDetail?.data?.user?.last_name} ${orderDetail?.data?.order_number} `}</p>
            </div>
            <div className={classes.dFlex}>
              <h4>{orderDetail?.data?.order_mode}</h4>
            </div>
            <ul className={classes.traDelInner}>
              <li>
                <span className={classes.ltTxt}>Address</span>

                {orderDetail?.data?.order_mode === "delivery"?
                  <span style={{textAlign:'right'}} className={classes.rtTxt} dangerouslySetInnerHTML={theObj} ></span>:
                  <span className={classes.rtTxt}>
                    {
                     orderDetail?.data?.outlet_name
                    }</span>}

              </li>
              <li>
                <span className={classes.ltTxt}>Date</span>
                <span className={classes.rtTxt}>
                  {convertDDMMYYYDateDay(orderDetail?.data?.order_date)}
                </span>
              </li>
              <li>
                <span className={classes.ltTxt}>Time slot</span>
                <span className={classes.rtTxt}>
                  {orderDetail?.data?.order_time_slot}
                </span>
              </li>
            </ul>
          </div>

          <div 
          // className={classes.traDel}
          >
            <div className={classes.dFlex}>
              <h3>Food & beverages</h3>
            </div>
            {orderDetail?.data?.items?.map((data, index) => (
              <div key={index} className={classes.traDel} >
                <div className={classes.dFlex}>
                  <h4>{data.item_name}</h4>
                  <p>{data.item_total} Credits</p>
                </div>

                <ul className={classes.traDelInner}>
                  {data?.hide_size === 0 ? (
                    <li>
                      <span className={classes.ltTxt}>{data?.item_size}</span>
                      <span className={classes.rtTxt}>
                        {data.unit_price === 0
                          ? "0 Credits"
                          : displayAmountFormat(data.unit_price )+ " Credits"}
                      </span>
                    </li>
                  ) : null}
                  {data.modifiers?.map((child, index) => (
                    <li key={Math.round()}>
                      <span className={classes.ltTxt}>
                        {child.modifier_name}
                      </span>
                      <span className={classes.rtTxt}>
                        {child.modifier_price === 0
                          ? "0 Credits"
                          : displayAmountFormat(child.modifier_price) + " Credits"}
                      </span>
                    </li>
                  ))}

                  {/* <li>
                  <span className={classes.ltTxt}>+ Extra shot</span>
                  <span className={classes.rtTxt}>+ 2 credits</span>
                </li>
                <li>
                  <span className={classes.ltTxt}>+ Low fat milk</span>
                  <span className={classes.rtTxt}>0 credits</span>
                </li> */}
                </ul>
                <div className={classes.dFlex}>
                  <p>Quantity</p>
                  <p>{data.item_quantity}</p>
                </div>
              </div>
            ))}
          </div>

          <div className={classes.traDel}>
            <div className={classes.dFlex}>
              <h4>Delivery fee</h4>
              <p>
                {orderDetail?.data?.delivery_fee === "0.00"
                  ? "0 Credits"
                  : displayAmountFormat(orderDetail?.data?.delivery_fee) + " Credits"}
              </p>
            </div>
            <div className={classes.dFlex}>
              <h4>Promo Code</h4>
              <p className={classes.redColor}>
                <span>
                  {orderDetail?.data?.coupon_used === ""
                    ? ""
                    : orderDetail?.data?.coupon_used}
                </span>{" "}
                {orderDetail?.data?.discount_availed === 0
                  ? "0 Credits"
                  : - displayAmountFormat(orderDetail?.data?.discount_availed)  + " Credits"}
              
              </p>
            </div>
          </div>
          <div className={classes.traDel}>
            <div className={classes.dFlex}>
              <h2>Total bill</h2>
              <h2 className={classes.totalTxt}>
                {orderDetail?.data?.grand_total} credits
              </h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default withStyles(Styles)(TransactionsOrderDetail);

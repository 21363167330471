import { ActionType } from "../../ActionTypes";
export const faqListReducer = (state = {}, action) => {
    switch (action.type) {
      case ActionType.FAQS_LIST_REQUEST:
        return {...state, loading: true };
      case ActionType.FAQS_LIST_SUCCESS:
        return {...state, loading: false, scusses: true, faqs: action.payload };
      case ActionType.FAQS_LIST_FAIL:
        return {...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const faqListitemReducer = (state = {}, action) => {
    switch (action.type) {
      case ActionType.FAQS_ITEMS_LIST_REQUEST:
        return {loading: true };
      case ActionType.FAQS_ITEMS_LIST_SUCCESS:
        return {loading: false, scusses: true, faqsItems: action.payload };
      case ActionType.FAQS_ITEMS_LIST_FAIL:
        return {loading: false, error: action.payload };
      default:
        return state;
    }
  };
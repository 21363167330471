import { ActionType } from "../../ActionTypes";

const initialState = {
  employees: [],
  employee: {},
};
export const employeeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.EMPLOYEES_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.EMPLOYEES_LIST_SUCCESS:
      return { ...state, loading: false, employees: action.payload };
    case ActionType.EMPLOYEES_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ActionType.GET_SINGLE_EMP_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_SINGLE_EMP_SUCCESS:
      return { ...state, loading: false, employee: action.payload };
    case ActionType.GET_SINGLE_EMP_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addEmpReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.EMPLOYEES_ADD_REQUEST:
      return { loading: true };
    case ActionType.EMPLOYEES_ADD_SUCCESS:
      return { loading: false, scusses: true, addMess: action.payload };
    case ActionType.EMPLOYEES_ADD_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteEmpReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.DELETE_SINGLE_EMP_REQUEST:
      return { loading: true };
    case ActionType.DELETE_SINGLE_EMP_SUCCESS:
      return { loading: false, scusses: true, deleteMess: action.payload };
    case ActionType.DELETE_SINGLE_EMP_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const updateEmpReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.UPDATE_SINGLE_EMP_REQUEST:
      return { loading: true };
    case ActionType.UPDATE_SINGLE_EMP_SUCCESS:
      return { loading: false, scusses: true, updateMess: action.payload };
    case ActionType.UPDATE_SINGLE_EMP_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const updateSingleEmployeeBalanceReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.UPDATE_SINGLE_EMP_BALANCE_REQUEST:
      return { loading: true };
    case ActionType.UPDATE_SINGLE_EMP_BALANCE_SUCCESS:
      return { loading: false, success: true, updateBalMess: action.payload };
    case ActionType.UPDATE_SINGLE_EMP_BALANCE_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const employeeImportReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.EMPLOYEE_IMPORT_REQUEST:
      return { loading: true };
    case ActionType.EMPLOYEE_IMPORT_SUCCESS:
      return { loading: false, scusses: true, updateMess: action.payload };
    case ActionType.EMPLOYEE_IMPORT_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

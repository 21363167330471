export const Styles = Theme => ({
    creditsBox: {
        "@media (max-width: 1075px)": {
            margin:"20px 0 0 auto",
        },
        "& ul": {
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            boxSizing: "border-box",
            padding: "12px 20px",
            borderRadius: "56px",
            marginLeft: "auto",
        },
        "& li": {
            display: "inline-block",
            "& > div": {
                padding: "0 10px",
                position: "relative",
                "& ::after": {
                    content: "''",
                    height: "40px",
                    width: "1px",
                    display: "block",
                    background: "#DDDDDD",
                    position: "absolute",
                    right: "0",
                    top: "5px",
                },
            },
            "&:last-child > div": {
                "& ::after": {
                    content: "''",
                    display:"none",
                },
            },
            "& h3": {
                margin: "0 0 3px",
                whiteSpace: "nowrap",
            },
        },
    },

    btBox: {
        display: "flex",
        "& img": {
            width: "18px",
            height: "auto",
            marginRight: "5px",
        },
        "& p": {
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgba(63, 68, 68, 0.5)",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            margin: "0",
        },
    },
})
export const Styles = (Theme) => ({
  headingBox: {
    // width:"70%",
    paddingRight: "15px",
    "& h2": {
      fontSize: "44px",
      lineHeight: "48px",
      color: "#3F4444",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      textTransform: "uppercase",
      margin: "0 0 5px",
      "@media (max-width: 1024px)": {
        fontSize: "35px",
        lineHeight: "42px",
      },
      "@media (max-width: 767px)": {
        fontSize: "25px",
        lineHeight: "30px",
      },
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      color: "rgba(63, 68, 68, 0.5)",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
      margin: "0",
      "@media (max-width: 767px)": {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
  },
  forHere: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "#3F444480",
    wordBreak: "break-word",
    textAlign: "center",
    marginTop: "0",
  },
});

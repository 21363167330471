import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreditsInfo } from "../../redux/actions/Dashboard/DashboardAction";
import { displayAmountFormat } from "../../common/utils/utils";
const CreditsInfo = (props) => {
  const { classes, refreshWallet = false } = props;
  const dispatch = useDispatch();
  const { creditsInfo } = useSelector((state) => state.creditsData);
  // const refreshWallet = window.localStorage.getItem("isRefreshWallet");
  useEffect(() => {
    dispatch(getCreditsInfo());
    // window.localStorage.setItem("isRefreshWallet", false);
  }, []);
  useEffect(() => {
    if (refreshWallet)
      return setTimeout(() => dispatch(getCreditsInfo()), 4000);
  }, [refreshWallet]);
  return (
    <div className={classes.creditsBox}>
      <ul>
        <li>
          <div>
            <h3>
              {displayAmountFormat(parseFloat(creditsInfo?.data.total_credits).toFixed(1))} Credits
            </h3>
            <div className={classes.btBox}>
              <img src="./images/balance.svg" alt="Better cloud" />
              <p>Balance</p>
            </div>
          </div>
        </li>
        <li>
          <div>
            <h3>
              {displayAmountFormat(parseFloat(creditsInfo?.data.allocated_credits).toFixed(1))}{" "}
              Credits
            </h3>
            <div className={classes.btBox}>
              <img src="./images/allocated.svg" alt="Better cloud" />
              <p>Allocated</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default withStyles(Styles)(CreditsInfo);

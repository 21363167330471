import { useState, Fragment, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import FormLabel from "@mui/material/FormLabel";
import { supplyDateFormat } from "../../common/utils/utils";
const Calander = (props) => {
  const { classes, start, end } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState([null, null]);
  const openDatePopup = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    if (values[0]) start(supplyDateFormat(values[0]));
    if (values[1]) end(supplyDateFormat(values[1]));
  }, [values]);

  return (
    <>
      <div className={classes.datapickerOuter}>
        <div className={classes.dFlex}>
          <img
            onClick={openDatePopup}
            src="./images/date-picker-icon.svg"
            alt="Better Coffee"
          />
          <div className={classes.datapickerBox}>
            <div className={classes.dFlex} onClick={openDatePopup}>
              <FormLabel onClick={openDatePopup}>DATE</FormLabel>
              <img
                onClick={openDatePopup}
                src="./images/down-arrow.svg"
                alt="Better Coffee"
                className={classes.arrow}
              />
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns} className={classes.innerDate}>
              <MobileDateRangePicker
                open={isOpen === null ? false : isOpen}
                startText=""
                showToolbar={false}
                value={values}
                okText={"Save"}
                monthsShown={2}
                onClose={() => {
                  setIsOpen(false);
                }}
                inputFormat={"dd MMM yyyy"}
                onChange={(newValue) => {
                  //   console.log("newValue", newValue);
                  setValues(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <Fragment>
                    <TextField
                      {...startProps}
                      onClick={() => setIsOpen(true)}
                      className={classes.firstDate}
                    />
                    <span>-</span>
                    <TextField
                      {...endProps}
                      onClick={() => setIsOpen(true)}
                      label=""
                      className={classes.secondDate}
                    />
                  </Fragment>
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(Calander);

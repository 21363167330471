import axios from "axios";
import { ActionType } from "../../ActionTypes";
import constant from "../../../common/constant";
import { getCreditsInfo } from "../Dashboard/DashboardAction";
import { reloadHandler } from "../../../common/utils/utils";

export const getEmployeeList = (empUrl) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.EMPLOYEES_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`${empUrl}`, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
      },
    });
    dispatch({ type: ActionType.EMPLOYEES_LIST_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({ type: ActionType.EMPLOYEES_LIST_FAIL, payload: error.message });
  }
};

export const getEmployeeDataList = () => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.EMPLOYEES_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.EMPLOYEES_LIST_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({ type: ActionType.EMPLOYEES_LIST_FAIL, payload: error.message });
  }
};

export const addEmployees = (emp) => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.EMPLOYEES_ADD_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee`,
      emp,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    console.log("data", data?.data?.employee_data?.email);
    dispatch(
      { type: ActionType.EMPLOYEES_ADD_SUCCESS, payload: data },
      localStorage.setItem("email", data?.data?.employee_data.email)
    );
    dispatch(getEmployeeDataList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.EMPLOYEES_ADD_FAIL, payload: message });
  }
};

export const DeleteEmployee = (empId) => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.DELETE_SINGLE_EMP_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.delete(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee/${empId}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.DELETE_SINGLE_EMP_SUCCESS, payload: data });
    dispatch(getEmployeeDataList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.DELETE_SINGLE_EMP_FAIL,
      payload: message,
    });
  }
};

export const getSignleEmployees = (id) => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({ type: ActionType.GET_SINGLE_EMP_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee/${id}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({
      type: ActionType.GET_SINGLE_EMP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    reloadHandler(err?.response?.status);
    const message =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: ActionType.GET_SINGLE_EMP_FAIL,
      payload: message,
    });
  }
};

export const updateSinglEmployees = (emp, id) => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.UPDATE_SINGLE_EMP_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.patch(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee/${id}`,
      emp,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.UPDATE_SINGLE_EMP_SUCCESS, payload: data });
    dispatch(getEmployeeDataList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.UPDATE_SINGLE_EMP_FAIL,
      payload: message,
    });
  }
};

export const updateSingleEmployeeBalance =
  (blc_id, wallet_balance) => async (dispatch, getState) => {
    const company_id = localStorage.getItem("company_id");
    dispatch({
      type: ActionType.UPDATE_SINGLE_EMP_BALANCE_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.patch(
        `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee/${blc_id}/balance`,
        wallet_balance,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.UPDATE_SINGLE_EMP_BALANCE_SUCCESS,
        payload: data,
      });
      dispatch(getEmployeeDataList());
      dispatch(getCreditsInfo());
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.UPDATE_SINGLE_EMP_BALANCE_FAIL,
        payload: message,
      });
    }
  };

export const employeeImportHandler =
  (payload) => async (dispatch, getState) => {
    const company_id = localStorage.getItem("company_id");
    dispatch({
      type: ActionType.EMPLOYEE_IMPORT_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.post(
        `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee-import`,
        payload,
        {
          headers: {
            "Content-type": "multipart/form-data",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );

      dispatch({ type: ActionType.EMPLOYEE_IMPORT_SUCCESS, payload: data });
      dispatch(getEmployeeDataList());
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.EMPLOYEE_IMPORT_FAILURE,
        payload: message,
      });
    }
  };

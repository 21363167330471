import { ActionType } from "../ActionTypes";
import serverURL from "../../APIHandler/serverURL";
import callAPI from "../../APIHandler/callAPI";
import { encryptValue, reloadHandler } from "../../common/utils/utils";
import { cookieCreator } from "../../common/cookieCreator";
import axios from "axios";
import constant from "../../common/constant";

export const signin = (email, password, remember) => async (dispatch) => {
  const param = {
    email: encryptValue(email),
    password: encryptValue(password),
  };
  dispatch({
    type: ActionType.USER_SIGNIN_REQUEST,
    payload: param,
  });
  try {
    // const keepMeLoggedIn = localStorage.getItem("keepMeLoggedIn");
    const { data } = await callAPI.postRequest(serverURL.login, param);

    if (remember) {
      let data = {
        email,
        password,
      };
      cookieCreator("token", JSON.stringify(data), 5);
      // if (remember) {
      //   dispatch({
      //     type: ActionType.KEEP_ME_LOGGED_IN,
      //     payload: param,
      //   });
      // }
    }
    dispatch({ type: ActionType.USER_SIGNIN_SUCCESS, payload: data });
    // keepMeLoggedIn === "YES"
    //   ? window.localStorage.setItem("userInfo", JSON.stringify(data))
    //   : null;
    window.localStorage.setItem("userInfo", JSON.stringify(data));

    window.sessionStorage.setItem("status", "active")
    window.localStorage.setItem("company_id", data.data.user_data.company_id);
    window.localStorage.setItem("pwd", encryptValue(password));
    window.localStorage.setItem("isRefreshWallet", true);
    window.localStorage.setItem("user_id", data.data.user_data.user_id);
    window.localStorage.setItem("auth_token", data.data.user_data.auth_token);
    window.localStorage.setItem("full_name", data.data.user_data.full_name);
    window.localStorage.setItem("last_name", data.data.user_data.last_name);
    window.localStorage.setItem(
      "email",
      encryptValue(data.data.user_data.email)
    );
    window.localStorage.setItem(
      "date_of_birth",
      encryptValue(data.data.user_data.date_of_birth)
    );
    window.localStorage.setItem(
      "stripe_customer_id",
      encryptValue(data.data.user_datastripe_customer_id)
    );
    window.localStorage.setItem(
      "gender",
      encryptValue(data.data.user_data.gender)
    );
    window.localStorage.setItem(
      "contact_number",
      encryptValue(data.data.user_data.contact_number)
    );
    window.localStorage.setItem(
      "settings",
      encryptValue(data.data.user_data.settings)
    );
    window.localStorage.setItem(
      "email_verified",
      encryptValue(data.data.user_data.email_verified)
    );
    window.localStorage.setItem(
      "is_blocked",
      encryptValue(data.data.user_data.is_blocked)
    );
    window.localStorage.setItem(
      "status",
      encryptValue(data.data.user_data.status)
    );
  } catch (error) {
    dispatch({
      type: ActionType.USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const register = (creds) => async (dispatch) => {
  dispatch({
    type: ActionType.USER_REGISTER_REQUEST,
    payload: creds,
  });
  try {
    const { data } = await callAPI.postRequest(serverURL.signup, creds);
    dispatch({ type: ActionType.USER_REGISTER_SUCCESS, payload: data });
    // document.location.href = "/Login";
  } catch (error) {
    dispatch({
      type: ActionType.USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendEmailResendEmail = (email) => async (dispatch) => {
  dispatch({
    type: ActionType.EMAIL_RESEND_LINK_REQUEST,
    payload: email,
  });
  try {
    const { data } = await callAPI.postRequest(serverURL.resend, email);
    dispatch({ type: ActionType.EMAIL_RESEND_LINK_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ActionType.EMAIL_RESEND_LINK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout =
  (changed = false) =>
    async (dispatch, getState) => {
      dispatch({
        type: ActionType.USER_LOGOUT_REQUEST,
      });
      const {
        userSignin: { userInfo },
      } = getState();
      try {
        await axios.post(
          `${constant.baseUrl}/api/v1/b2b/users/signout`,
          {},
          {
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Credentials": true,
              Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
            },
          }
        );
        dispatch({ type: ActionType.USER_LOGOUT_SUCCESS });
        localStorage.clear();
        localStorage.setItem("isChanged", changed);
        document.location.reload();
      } catch (error) {
        localStorage.clear();
        // document.location.reload();
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ActionType.USER_LOGOUT_FAIL, payload: message });
      }
    };

export const updateUserPassword = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.USER_NEW_PASSWORD_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      `${constant.baseUrl}/api/v1/b2b/users/set-password`,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.USER_NEW_PASSWORD_SUCCESS, payload: data });
    localStorage.setItem("pwd", encryptValue(payload.new_password));
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.USER_NEW_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userSetPassword =
  (setPasswordCreds) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.SET_PASSWORD_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${constant.baseUrl}/api/v1/b2b/users/set-password`,
        setPasswordCreds,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      // await callAPI.postRequest(serverURL.setpassword, setPasswordCreds);
      dispatch({ type: ActionType.SET_PASSWORD_SUCCESS, payload: data });
      localStorage.setItem("isUpdate", true);
      setTimeout(() => (document.location.href = "/Login"), 50000);
    } catch (error) {
      reloadHandler(error?.response?.status);
      dispatch({
        type: ActionType.SET_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const userForGotPassword = (email) => async (dispatch) => {
  dispatch({
    type: ActionType.FORGOT_PASSWORD_REQUEST,
    payload: email,
  });
  try {
    const { data } = await callAPI.postRequest(serverURL.forgotpassword, email);
    dispatch({ type: ActionType.FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userSetting = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.USER_SETTING_CHANGE_PASSWORD_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.setting, payload, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
      },
    });
    dispatch({
      type: ActionType.USER_SETTING_CHANGE_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.USER_SETTING_CHANGE_PASSWORD_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const emailVerify = (id) => async (dispatch) => {
  dispatch({
    type: ActionType.EMAIL_VERIFY_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/email-verify/${id}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
    dispatch(
      {
        type: ActionType.EMAIL_VERIFY_SUCCESS,
        payload: data,
      },
      localStorage.setItem("email", data?.data?.user_data?.email)
    );
  } catch (error) {
    dispatch({
      type: ActionType.EMAIL_VERIFY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data
          : error.message,
    });
  }
};

export const verifyUenAction = (license_number) => async (dispatch) => {
  dispatch({
    type: ActionType.VERIFY_UEN_REQUEST,
    license_number,
  });
  try {
    const { data } = await callAPI.postRequest(
      serverURL.verifyUen,
      license_number
    );
    dispatch({ type: ActionType.VERIFY_UEN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ActionType.VERIFY_UEN_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const verifycompanyname = (company_name) => async (dispatch) => {
  dispatch({
    type: ActionType.VERIFY_COMPANY_REQUEST,
    company_name,
  });
  try {
    const { data } = await callAPI.postRequest(
      serverURL.companyname,
      company_name
    );
    dispatch({ type: ActionType.VERIFY_COMPANY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ActionType.VERIFY_COMPANY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const userDetailAction = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.USER_DETAILS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}${serverURL.details}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch(
      {
        type: ActionType.USER_DETAILS_SUCCESS,
        payload: data,
      },
      localStorage.setItem("email", data?.data?.user_data?.email)
    );
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.USER_DETAILS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data
          : error.message,
    });
  }
};

import axios from "axios";
import { ActionType } from "../../ActionTypes";
import constant from "../../../common/constant";
import callAPI from "../../../APIHandler/callAPI";
import { reloadHandler } from "../../../common/utils/utils";

export const listTransactionCategories = () => async (dispatch) => {
  dispatch({
    type: ActionType.TRANSACTION_DATA_REQUEST,
  });
  try {
    const { data } = await callAPI.getRequest(`url`);
    dispatch({ type: ActionType.TRANSACTION_DATA_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.TRANSACTION_DATA_FAIL,
      payload: error.message,
    });
  }
};

export const getTransactionList =
  (companyUrl) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_TRANSACTIONS_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.get(companyUrl, {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      });
      dispatch({ type: ActionType.GET_TRANSACTIONS_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.GET_TRANSACTIONS_FAILURE,
        payload: message,
      });
    }
  };

export const getOrderDetail = (orderId) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_ORDER_DETAIL_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company/orders/${orderId}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.GET_ORDER_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.GET_ORDER_DETAIL_FAILURE,
      payload: message,
    });
  }
};

import { ActionType } from "../../ActionTypes";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import moment from "moment";
import { reloadHandler } from "../../../common/utils/utils";

const clearHandler = () => {
  localStorage.clear();
  document.location.reload();
};

export const getDashboardDetails = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_DASHBOARD_DETAILS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.dashboard, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
      },
    });
    dispatch({ type: ActionType.GET_DASHBOARD_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.GET_DASHBOARD_DETAILS_FAIL, payload: message });
  }
};

export const getCreditsInfo = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_COMPANY_DETAILS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.creditsInfo, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
      },
    });
    dispatch({ type: ActionType.GET_CREDITS_INFO_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    error.response.status === 401
      ? clearHandler()
      : dispatch({ type: ActionType.GET_CREDITS_INFO_FAIL, payload: message });
  }
};

export const getConfiguration = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_CONFIGURATION_REQUEST,
  });
  try {
    const { data } = await callAPI.getRequest(serverURL.configurations, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    });
    dispatch({ type: ActionType.GET_CONFIGURATION_SUCCESS, payload: data });
    const { configuration } = getState();
    localStorage.setItem(
      "config",
      JSON.stringify(configuration?.DashboardDetail?.data?.config)
    );
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.GET_CONFIGURATION_FAILURE, payload: message });
  }
};

export const getDashboardPickUpAction =
  (
    start_date = moment().startOf("month").format("YYYY-MM-DD"),
    end_date = moment().endOf("month").format("YYYY-MM-DD")
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.DELIVERY_PICKUP_GRAPH_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.deliveryPickup.concat(
          `?start_date=${start_date}&&end_date=${end_date}`
        ),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.DELIVERY_PICKUP_GRAPH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.DELIVERY_PICKUP_GRAPH_FAILURE,
        payload: message,
      });
    }
  };

export const getCreditUsageAction =
  (
    start_date = moment().startOf("month").format("YYYY-MM-DD"),
    end_date = moment().endOf("month").format("YYYY-MM-DD")
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.CREDIT_USAGE_GRAPH_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.creditUsage.concat(
          `?start_date=${start_date}&&end_date=${end_date}`
        ),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.CREDIT_USAGE_GRAPH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.CREDIT_USAGE_GRAPH_FAILURE,
        payload: message,
      });
    }
  };

export const getUserFrequencyAction =
  (
    start_date = moment().startOf("month").format("YYYY-MM-DD"),
    end_date = moment().endOf("month").format("YYYY-MM-DD")
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.USER_FREQUENCY_GRAPH_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.userFrequency.concat(
          `?start_date=${start_date}&&end_date=${end_date}`
        ),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.USER_FREQUENCY_GRAPH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.USER_FREQUENCY_GRAPH_FAILURE,
        payload: message,
      });
    }
  };

export const getTopFiveAction =
  (start_date = "2022-10-01", end_date = "2022-11-01") =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.TOP_FIVE_GRAPH_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(serverURL.topFive, {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      });
      dispatch({
        type: ActionType.TOP_FIVE_GRAPH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.TOP_FIVE_GRAPH_FAILURE,
        payload: message,
      });
    }
  };

import { withStyles } from "@mui/styles";
import { Styles } from "./Styles";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import AddEmployeesForm from "../AddEmployeesForm/AddEmployeesForm";
import AddEmployeesVia from "../AddEmployeesVia/AddEmployeesVia";
const DrawerRight = (props) => {
  const { classes, parentCallback, onOpen, isCSV = false } = props;
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [pageType, setPageType] = useState("");

  const toggleDrawer = (anchor, open, pageType) => (event) => {
    console.log("anchor", anchor);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;
    setPageType(pageType);
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ maxWidth: anchor === "top" || anchor === "bottom" ? "auto" : 440 }}
      role="presentation"
      className={classes.boxOuter}
    >
      {pageType == "csv" ? (
        <AddEmployeesVia
          cancelHandler={toggleDrawer(anchor, false, "")}
          onOpen={onOpen}
        />
      ) : (
        <AddEmployeesForm
          cancelHandler={toggleDrawer(anchor, false, "")}
          parentCallback={parentCallback}
        />
      )}
    </Box>
  );
  return (
    <>
      <div className={classes.btnContent}>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div className={classes.dFlex}>
              {isCSV && (
                <BorderBtn
                  name={"Add employees via csv"}
                  clickHandler={toggleDrawer(anchor, true, "csv")}
                />
              )}

              <BorderBtn
                name={"Add employees manually"}
                clickHandler={toggleDrawer(anchor, true, "")}
              />
            </div>
            <Drawer anchor={anchor} open={state[anchor]}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default withStyles(Styles)(DrawerRight);

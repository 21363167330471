export const Styles = Theme => ({
    customPagination: {
        marginTop: "10px",
        "& ul": {
            display: "flex",
            margin: "8px 0",
            "& li": {
                "&:first-child": {
                    "& button": {
                        fontSize: "14px",
                        lineHeight: "14px",
                        color: "#3F4444",
                        fontWeight: "400",
                        fontFamily: "'Noto Serif', serif",
                        background: "transparent",
                        textTransform: "capitalize",
                        "&:before": {
                            content: "''",
                            background: "url(./images/pre-icon.svg)",
                            padding: "4px",
                            marginRight: "8px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                        },
                    },
                },
                "&:last-child": {
                    "& button": {
                        fontSize: "14px",
                        lineHeight: "14px",
                        color: "#3F4444",
                        fontWeight: "400",
                        fontFamily: "'Noto Serif', serif",
                        background: "transparent",
                        textTransform: "capitalize",
                        "&:after": {
                            content: "''",
                            background: "url(./images/next-icon.svg)",
                            padding: "4px",
                            marginLeft: "8px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                        },
                    },
                },
            },
        },
    },
    button: {
        border: "none",
        padding: "4px",
        fontSize: "14px",
        lineHeight: "35px",
        color: "#3F4444",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
        margin: "0 3px",
        minWidth: "35px",
        minHeight: "35px",
        borderRadius: "100%",
        background: "transparent",
        cursor: "pointer",
    },
    bottomBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "10px",
        "& p": {
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#464A53",
            margin: "0",
        },
    },
    txtBox: {
        width: "50%",
        paddingRight: "10px",
    },
    paginationBox: {
        width: "50%",
        "& ul": {
            float: "right",
        }
    }
})


import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchCalander from "../../component/SearchCalander/SearchCalander";
import RowTable from "../../component/Transactions/RowTable";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionList } from "../../redux/actions/Transactions/TransactionAction";
import StrConstent from "../../common/StrConstent";
import Loader from "react-spinner-loader";
import axios from "axios";
import constant from "../../common/constant";
import Paginator from "../../component/Paginator/Paginator";

const Transaction = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const inputHandler = (searchText) =>
    setSearchVal(searchText?.replace("+", "^^"));
  const [page, setPage] = useState(1);
  const [dataInCSV, setCsvData] = useState();
  const [searchVal, setSearchVal] = useState("");
  const [searchDate, setSearchDate] = useState({
    start_date: "",
    end_date: "",
  });
  const { loading, transactionList, success } = useSelector(
    (state) => state.getTransacList
  );

  const startDate = (start_date) => {
    if (start_date)
      return setSearchDate((pre) => ({ ...pre, start_date: start_date }));
    else return;
  };

  const endDate = (end_date) => {
    if (end_date)
      return setSearchDate((pre) => ({ ...pre, end_date: end_date }));
    else return;
  };

  const { company_id } = useSelector(
    (state) => state.userSignin?.userInfo?.data?.user_data
  );

  const getURLParam = (isCancel = false, start_date = "", end_date = "") => {
    let searchText = searchVal?.replace("#", "");
    let url = `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/orders?page=${page}`;
    url += `&start_date=${isCancel ? "" : start_date}&end_date=${
      isCancel ? "" : end_date
    }`;
    url += `&search_by=${isCancel ? "" : searchText}`;
    return url;
  };

  const downloadCsv = async () => {
    let searchText = searchVal?.replace("#", "");
    const company_id = localStorage.getItem("company_id");
    const token = localStorage.getItem("auth_token");
    try {
      // ?page=1&start_date=&end_date=&search_by=
      const { data } = await axios.get(
        `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/orders-export?page=1&start_date=${searchDate?.start_date}&end_date=${searchDate?.end_date}&search_by=${searchText}`,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("window",window.location);
      console.log("csvdata",data)
      window.location.href=`data:text/csv;charset=utf-8,${escape(data)}`
      setCsvData(data);
    } catch (error) {}
  };

  const handleSubmitSearch = (isCancel = false) => {
    if (isCancel === true) {
      isCancel = true;
    } else if (isCancel !== true) {
      isCancel = false;
    }
    if (
      searchVal?.length >= 3 ||
      searchDate?.start_date !== "" ||
      searchDate?.end_date !== ""
    )
      return dispatch(
        getTransactionList(
          getURLParam(isCancel, searchDate?.start_date, searchDate?.end_date)
        ),
        // downloadCsv()
      );
    else if (searchVal === "" || isCancel)
      return dispatch(
        getTransactionList(
          getURLParam(isCancel, searchDate?.start_date, searchDate?.end_date)
        ),
        // downloadCsv()
      );
  };

  const cancelHandler = (isCancel) => {
    if (isCancel) {
      handleSubmitSearch(isCancel);
    } else return;
  };

  const pageHandle = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getTransactionList(getURLParam()), 
    // downloadCsv()
    );
  }, [page]);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <div className={classes.empOuter}>
        <div className={classes.topBox1}>
          <Heading
            heading={StrConstent.Transaction.headerTitle}
            description={StrConstent.Transaction.headerDesc}
          />
          <CreditsInfo />
        </div>
        <div className={classes.topBox2}>
          <SearchCalander
            handleSubmitSearch={handleSubmitSearch}
            onTypingEvent={inputHandler}
            onCancel={cancelHandler}
            start={startDate}
            end={endDate}
          />
          <div className={classes.btnContent}>
            {transactionList?.data?.orders &&
                  transactionList?.data?.orders?.length > 0  && (
              <a
                className={classes.downloadBtn}
                style={{cursor:"pointer"}}
                onClick={()=>downloadCsv()}
                // href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                download="bettrbar.csv"
              >
                download csv
              </a>
            )}
          </div>
        </div>
        <div className={classes.tableOuter}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order No</TableCell>
                    <TableCell>Employee name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Delivery / Pick-up</TableCell>
                    <TableCell>Credits</TableCell>
                    <TableCell className={classes.txtCenter}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {!loading &&
                  transactionList?.data?.orders &&
                  (transactionList?.data?.orders?.length > 0 ? (
                    <TableBody>
                      {transactionList?.data?.orders?.map((data, index) => (
                        <RowTable key={data.id} rowData={data} count={index} />
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div className={classes.noData}>
                            <h2>No data</h2>
                            <p>Oops, there's nothing to show here.</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Paper>
          <Paginator
            pageData={transactionList?.data?.pagination}
            handleChangeValuePage={pageHandle}
            currentPage={page}
          />
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(Transaction);

import { Styles } from "./Styles";
import Box from "@mui/material/Box";
import { Fragment, useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import CreditTopUpForm from "../CreditTopUp/CreditTopUpForm";
import constant from "../../common/constant";
const stripePromise = loadStripe(constant.stripeKey);
const DrawerRightCredits = (props) => {
  const Drawerpos = "right";
  const {
    classes,
    card_id,
    paymentOption,
    amount,
    creditSuccess,
    handleValidation,
    isValidated,
  } = props;
  const [isSubmit, setIsSubmit] = useState(false);
  const [direction, setDirection] = useState(false);
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }}
      role="presentation"
      className={classes.boxOuter}
    >
      <Elements stripe={stripePromise}>
        <CreditTopUpForm
          creditSuccess={creditSuccess}
          amount={amount}
          card_id={card_id}
          paymentOption={paymentOption}
          cancelHandler={() => handleOpenClose()}
        />
      </Elements>
    </Box>
  );
  const handleOpenClose = () => {
    setIsSubmit(true);
    handleValidation();
    if (isSubmit) {
      setIsSubmit(false);
      
      // console.log("direction", direction,isSubmit);
    }
    // let isValid = false;
    // if (
    //   amount === null ||
    //   amount == undefined ||
    //   amount === "" ||
    //   validateAmount(amount)
    // ) {
    //   console.log("amount", amount);
    //   console.log("isValidated", isValidated);
    //   isValid = true;
    // }
    // if (paymentOption === 1) {
    //   isValid = true;
    // }
    // if (paymentOption === 3) {
    //   console.log("card_id", card_id);
    //   isValid = true;
    // }
    // if (
    //   (isValid &&
    //     paymentOption === 3 &&
    //     card_id !== "" &&
    //     amount != undefined) ||
    //   validateAmount(amount) ||
    //   (isValid && paymentOption === 2)
    // ) {
  };

  useEffect(() => {
    if (isSubmit) {
      // console.log("isValidated", isValidated);
      if (isValidated) {
        setDirection(false);
        setIsSubmit(false);
        // console.log("card_id", card_id);
      } else {
        // console.log("else");
        // console.log("card_id", card_id);
        setDirection(true);
      }
    } else setDirection(false);
  }, [isSubmit]);

  useEffect(()=>{
// console.log("direction", direction,isSubmit);
  },[direction])
  return (
    <Fragment key={Drawerpos}>
      <YellowBtn name={"next"} clickHandler={() => handleOpenClose()} />
      <Drawer anchor={Drawerpos} open={direction} transitionDuration={600}>
        {list(Drawerpos)}
      </Drawer>
    </Fragment>
  );
};
export default withStyles(Styles)(DrawerRightCredits);

import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import CustomPopover from "../../component/CustomPopover/CustomPopover";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import StrConstent from "../../common/StrConstent";
import { useDispatch, useSelector } from "react-redux";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
import TabPanel from "@mui/lab/TabPanel";
import {
  getCategoryList,
  getOutletsList,
  updateCreditAllocation,
} from "../../redux/actions/SpendPolicy/SpendPolicyAction";
import { getEmployeeList } from "../../redux/actions/Employees/EmployeesAction";
import CustomSwitch from "../../component/CustomSwitch/CustomSwitch";
import constant from "../../common/constant";

const CreditAllocation = (props) => {
  const { classes, onToggle } = props;
  const company_id = localStorage.getItem("company_id");
  const [filterValue] = useState();
  const [searchVal] = useState("");
  const [inValid, setInValid] = useState(false);
  const [cardType, setCardType] = useState(false);
  const [total_credits, setTotalCredits] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const getURLParam = () => {
    let url = `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/employee?filter_by=1`;
    url += filterValue ? `&filter_by=${filterValue}` : "";
    url += `&search_by=${searchVal}`;
    return url;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployeeList(getURLParam()));
    dispatch(getCategoryList());
    dispatch(getOutletsList());
  }, []);
  useEffect(() => {
    setTotalCredits("");
    setInValid(false);
    setSelectedEmployees([]);
  }, [onToggle]);
  const { employees } = useSelector((state) => state.employeeData);
  const update = useSelector(
    (state) => state.updateCreditAllocation?.updateCredit
  );
  const { error } = useSelector((state) => state.updateCreditAllocation);

  const employeeList = employees?.data?.employees;

  const handleChangeEmployees = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedEmployees(
        selectedEmployees?.length === formattedEmployee?.length
          ? []
          : formattedEmployee
      );
      return;
    }
    setSelectedEmployees(value);
  };
  const onChangeSelection = (selected) => {
    setCardType(selected);
  };
  useEffect(() => {
    setTotalCredits("");
    setSelectedEmployees([]);
  }, [cardType]);

  const filteredEmployee = employeeList?.filter((element) =>
    element?.status === 0 ? null : element
  );
  const formattedEmployee = filteredEmployee?.map(
    (element) =>
      `${element?.full_name} | ${element?.email} | ${element?.emp_id} `
  );
  const isAllEmployeeSelected =
    formattedEmployee?.length > 0 &&
    selectedEmployees?.length === formattedEmployee?.length;
  const updateCreditAllocationHandler = () => {
    if (total_credits === "") return setInValid(true);
    else if (selectedEmployees.length === 0) return setInValid(true);
    else if (total_credits) {
      const userID = [];
      selectedEmployees.map((data) => {
        const end = data.split("| ");
        const id = end[end.length - 1];
        const uIDs = employeeList?.filter((element) =>
          id.indexOf(element.emp_id) >= 0 ? element.user_id : null
        );
        if (uIDs.length > 0) {
          userID.push(uIDs[0].user_id);
        }
        return "";
      });
      const payload = {
        total_credits,
        credit_type: cardType ? 1 : 0,
        user_ids: userID,
      };
      dispatch(updateCreditAllocation(payload), setIsOpen(true));
      setTotalCredits("");
      setSelectedEmployees([]);
      setInValid(false);
      setIsOpen(true);
    } else setInValid(true);
  };
  // Check box css
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    background: "#E3DDD280",
    marginRight: "5px",
    "input:disabled ~ &": {
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#ffc200",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "url(./images/check.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffc200",
    },
  });
  // Inspired by blueprint's
  const BpCheckbox = (props) => {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  };
  const discardChanges = () => {
    setTotalCredits("");
    setInValid(false);
    setSelectedEmployees([]);
  };
  const setInputNumber = (value) => {
    const regex = "[+-]?[0-9]+.[0-9]+";
    if (regex.search(regex, value) && value?.length < 7) {
      setTotalCredits(value);
    } else {
      console.log("Not a Floating point number");
    }
  };
  const preventHandler = (e) => {
    if (e.keyCode === 69) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    setIsOpen(false);
  }, []);
  return (
    <>
      {(error || update?.message) && (
        <AlterMessage
          handleClose={() => setIsOpen(false)}
          description={error || update?.message}
          title={update?.message ? "Success" : error ? "Success" : null}
          type={update?.message ? "Success" : error ? "Success" : null}
          isopen={isOpen}
          url={
            update?.message
              ? "./images/success.svg"
              : error
              ? "./images/success.svg"
              : null
          }
        />
      )}
      <TabPanel value="1" className={classes.pr0}>
        <div className={classes.formInner}>
          <div className={classes.dFlexTxt}>
            <h3>Credit allocation</h3>
            <a
              href="/spend-policy-history"
              style={{ cursor: "pointer" }}
              className={classes.linktxt}
            >
              View Spend Policy History
            </a>
          </div>
          <div className={classes.formControl}>
            {" "}
            <CustomSwitch
              SwitchValue={cardType}
              onSelection={onChangeSelection}
            />
          </div>
          <div className={classes.formControl}>
            <FormLabel className={classes.creditsLabel}>
              Credits to be {cardType ? "deducted" : "assigned"} to each
              employee<span className="req">*</span>
              <CustomPopover
                // title={StrConstent.SpendPolicy.creditAllocationTitle}
                description={StrConstent.SpendPolicy.creditAllocationDes}
              />
            </FormLabel>
            <input
              type="number"
              value={total_credits}
              placeholder="Enter number of credits"
              onChange={(e) => setInputNumber(e.target.value)}
              onKeyDown={preventHandler}
            />
            {inValid && total_credits === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : null}
            {total_credits !== "" && total_credits <= 0 ? (
              <p className="errorMessage">This field is required.</p>
            ) : null}
          </div>
          <FormLabel className={classes.creditsLabel}>
            Credits to be {cardType ? "deducted" : "assigned"} to each of the
            selected employee <span className="req">*</span>
          </FormLabel>
          <div className={classes.customDd}>
            <Select
              labelId="multiple-select-label"
              multiple
              displayEmpty
              value={selectedEmployees}
              onChange={handleChangeEmployees}
              renderValue={(selectedEmployees) => {
                if (selectedEmployees.length === 0) {
                  return (
                    <div className={classes.titleTxt}>
                      <span>Employee Name | Email | Id</span>
                    </div>
                  );
                }
                return selectedEmployees.join(", ");
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllEmployeeSelected ? classes.selectedAll : "",
                }}
                className={classes.titleTxt}
              >
                <ListItemIcon className={classes.checkboxCustom}>
                  <BpCheckbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllEmployeeSelected}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.titleTxt}
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {formattedEmployee?.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  // onClick={() => getId(option)}
                >
                  <ListItemIcon>
                    <BpCheckbox
                      checked={selectedEmployees.indexOf(option) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option} className={classes.ddTxt} />
                </MenuItem>
              ))}
            </Select>
            {inValid && selectedEmployees.length === 0 ? (
              <p className="errorMessage">This field is required.</p>
            ) : null}
          </div>
        </div>
        <div className={classes.btnContent}>
          <BorderBtn name={"cancel"} clickHandler={discardChanges} />
          <YellowBtn
            name={"confirm"}
            clickHandler={updateCreditAllocationHandler}
          />
        </div>
      </TabPanel>
    </>
  );
};

export default withStyles(Styles)(CreditAllocation);

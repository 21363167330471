import React, { useState, useEffect } from "react";
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailResendEmail, signin } from "../../redux/actions/UserActions";
// import { Navigate } from "react-router-dom";
import AlterMessage from "../AlertMessage/AlertMessage";
import CustomCheckbox from "../../component/CustomCheckbox/CustomCheckbox";
import StrConstent from "../../common/StrConstent";
import {
  encryptValue,
  decryptValue,
  validateEmail,
  validatePassword,
  validateRequired,
} from "../../common/utils/utils";
// import Tooltip from "@mui/material/Tooltip";
import Loader from "react-spinner-loader";
import { ActionType } from "../../redux/ActionTypes";
import LogoContainer from "../LogoContainer/LogoContainer";
import { getCookie } from "../../common/cookieCreator";
function LoginForm(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const {
    userIn,
    success,
    error: registerMessError,
  } = useSelector((state) => state.userRegister);
  const { resetdata, success: resetdataSucces } = useSelector(
    (state) => state.resendEmailLink
  );
  const resetdatass= useSelector(state=>state?.resendEmailLink)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [stay, setStay] = useState(false);
  const [inValid, setInvalid] = useState(false);
  const isChanged = localStorage.getItem("isChanged");
  const checkBoxHandler = (check, name) => {
 
    localStorage.setItem("isremember",check)
    setStay((pre) => ({ ...pre, [name]: check }));
  };
useEffect(()=>{
  localStorage.setItem("isremember",false)
},[])
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: ActionType.CLEAR_ERROR });
    // if (!userSignin?.userInfo) return <Navigate to="/login" />;
    if (
      validateEmail(email) ||
      validateRequired(email) ||
      validateRequired(password) ||
      validatePassword(password)
    )
      return setInvalid(true);
    else return dispatch(signin(email, password, stay), setIsOpen(true));
  };

  useEffect(() => {
    if (success || registerMessError || resetdataSucces) {
      setIsOpen(true);
      // setTimeout(() => {
      //   setIsOpen(false);
      //   dispatch({ type: ActionType.TOPUP_MESSAGE_RESET });
      // }, 30000);
    }
  }, [success, registerMessError, resetdataSucces]);
console.log("resetdataSucces",resetdataSucces,resetdatass?.resetData?.message)
  useEffect(() => {
    if (stay) localStorage.setItem("keepMeLoggedIn", "YES");
    else return setStay(false);
  }, [stay]);

  useEffect(() => {
    if (userSignin?.userInfo) {
      navigate("/");
    }
  }, [navigate, userSignin?.userInfo]);

  const handleClose = () => {
    dispatch({ type: ActionType.CLEAR_ERROR }, setIsOpen(false));
    // if (reason === "clickaway")
    dispatch({ type: ActionType.TOPUP_MESSAGE_RESET }, setIsOpen(false));
  };
  const isChangedHandler = () => {
    localStorage.removeItem("isChanged");
    setIsOpen(false);
  };
  useEffect(() => {
    console.log("isChanged", isChanged);
    if (isChanged) return setIsOpen(true);
  }, [isChanged]);
  useEffect(() => {
    const persist = getCookie("token");
    console.log("persist", persist);
  }, []);
  console.log("userSignin",userSignin?.error)

  const handleresendlink=()=>{
     dispatch(
      sendEmailResendEmail({ email: email })                
    )
  }


  
  return (
    <>
      {userSignin?.loading && (
        <Loader
          show={userSignin?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {isChanged === "true" ? (
        <AlterMessage
          description={"Please login to continue."}
          handleClose={isChangedHandler}
          url="./images/success.svg"
          isopen={isOpen}
          title="Success"
          type="Success"
        />
      ) : null}
      {success && (
        <AlterMessage
          description={userIn?.message}
          url="./images/success.svg"
          handleClose={handleClose}
          isopen={isOpen}
          title="Success"
          type="Success"
        />
      )}
      {resetdataSucces && (
        <AlterMessage
          handleClose={handleClose}
          description={resetdatass?.resetData?.message}
          title="Success"
          type="Success"
          isopen={isOpen}
          url="./images/success.svg"
        />
      )}
      {/* {userSignin?.error && (
        <AlterMessage
          handleClose={handleClose}
          description={userSignin?.error}
          title="Error"
          type="Error"
          isopen={isOpen}
          url="./images/error.svg"
        />
      )} */}
      <form action="#" className={classes.form} onSubmit={handleSubmit}>
        <LogoContainer
          heading={StrConstent.Login.heading}
          goUrl={""}
          urlTxt={""}
        />
        {/* <img className={classes.logoBettr} src="/images/logo.png" alt="logoB" />
        <h1 className={classes.h1}>Login</h1> */}
        <div className={classes.formControl}>
          <FormLabel className={classes.dFlex}>
            {/* <div className={classes.dFlex}> */}
            <span>
              Email address<span className="req">*</span>
            </span>
            {/* {email !== "" && validateEmail(email) && (
              <Tooltip title="Invalid Email" arrow>
                <Button>
                  <img
                    className={classes.logoBettr}
                    src="/images/info-circle.svg"
                    alt="better"
                  />
                </Button>
              </Tooltip>
            )} */}
            {/* </div> */}
          </FormLabel>
          <input
            variant="outlined"
            margin="normal"
            id="loginInputEmail"
            type="email"
            placeholder="Enter your registered work email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {inValid && email === "" ? (
            <p className="errorMessage">This field is required.</p>
          ) : (
            <div></div>
          )}
          {email !== "" && validateEmail(email) ? (
            <p className="errorMessage">
              Information entered is invalid. Please try again.{" "}
            </p>
          ) : null}
        </div>
        <div className={classes.formControl}>
          <FormLabel className={classes.dFlex}>
            <span>
              Password<span className="req">*</span>
            </span>
            {/* <div>
              {password !== "" && validatePassword(password) && (
                <Tooltip title="Invalid Password" arrow>
                  <Button>
                    <img
                      className={classes.iIcon}
                      src="/images/info-circle.svg"
                      alt="better"
                    />
                  </Button>
                </Tooltip>
              )}
            </div> */}
          </FormLabel>
          <div
            className={
              validatePassword(password) && password !== ""
                ? classes.errorPasswordBox
                : classes.passwordBox
            }
          >
            <input
              id="loginInputPassword"
              value={password}
              margin="normal"
              variant="outlined"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </span>
          </div>
          {inValid && password === "" ? (
            <p className="errorMessage">This field is required.</p>
          ) : (
            <div></div>
          )}
          {password !== "" && validatePassword(password) && inValid ? (
            <p className="errorMessage">
              Information entered is invalid. Please try again.
            </p>
          ) : null}
        </div>
        <div className={classes.rememberMe}>
          <label>
            <CustomCheckbox onChange={checkBoxHandler} name={"stay"} />
            <span className={classes.textCheckbox}>Keep me logged in</span>
          </label>
          <a
            href
            className={classes.textForgot}
            onClick={() => navigate("/forgot-password")}
          >
            Forgot your password?
          </a>
        </div>
        {userSignin?.error &&userSignin?.error?.message!=="This email is not verified. Please verify your email first." ? (
          <p className={classes.errorMessage}>
            Your password is incorrect or this account doesn’t exist. Please{" "}
            <Link
              to="/forgot-password"
              onClick={() => navigate("/forgot-password")}
            >
              reset your password
            </Link>{" "}
            or{" "}
            <Link to="/sign-up" onClick={() => navigate("/sign-up")}>
              create a new account
            </Link>
            .
          </p>
        ): userSignin?.error &&userSignin?.error?.message=="This email is not verified. Please verify your email first." ? (
          <p className={classes.errorMessage}>{userSignin?.error?.message}
          <Link to=''onClick={()=>{handleresendlink()}}>
          Resend verification email
          </Link>
          </p>
        ):null
      
      }
        <div className={classes.btnBox}>
          <div
            component="span"
            className={`${classes.spreadBox} ${classes.box}`}
          >
            <div className={classes.yellowBtn}>
              <Button type="submit">Login</Button>
            </div>
            <div className={classes.donTxt}>
              {" "}
              <p>Don’t have an account?</p>
              <Link
                To=""
                className={classes.textForgot}
                onClick={() => navigate("/sign-up")}
              >
                {" "}
                Sign up here{" "}
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default withStyles(Styles)(LoginForm);

import { ActionType } from "../ActionTypes";

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_SIGNIN_REQUEST:
      return { ...state, loading: true };
    case ActionType.USER_SIGNIN_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case ActionType.USER_SIGNIN_FAIL:
      return { ...state, loading: false, error: action.payload };
    // case ActionType.KEEP_ME_LOGGED_IN:
    //   return {
    //     ...state,
    //     rememberMe: action.payload,
    //   };
    case ActionType.CLEAR_ERROR:
      return {};
    default:
      return state;
  }
};

export const userSignOutReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_LOGOUT_REQUEST:
      return { ...state, loading: true };
    case ActionType.USER_LOGOUT_SUCCESS:
      return { ...state, loading: false, success: true };
    case ActionType.USER_LOGOUT_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_REGISTER_REQUEST:
      return { loading: true };
    case ActionType.USER_REGISTER_SUCCESS:
      return { loading: false, userIn: action.payload, success: true };
    case ActionType.USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_ERROR:
      return {};
    default:
      return state;
  }
};

export const userUpdatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_NEW_PASSWORD_REQUEST:
      return { loading: true };
    case ActionType.USER_NEW_PASSWORD_SUCCESS:
      return { loading: false, success: true, updatePwd: action.payload };
    case ActionType.USER_NEW_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const userResendEmailLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.EMAIL_RESEND_LINK_REQUEST:
      return { loading: true };
    case ActionType.EMAIL_RESEND_LINK_SUCCESS:
      return { loading: false, success: true, resetData: action.payload };
    case ActionType.EMAIL_RESEND_LINK_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const userSetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SET_PASSWORD_REQUEST:
      return { loading: true };
    case ActionType.SET_PASSWORD_SUCCESS:
      return { loading: false, success: true, setpassMesg: action.payload };
    case ActionType.SET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case ActionType.FORGOT_PASSWORD_SUCCESS:
      return { loading: false, forgotData: action.payload, success: true };
    case ActionType.FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.TOPUP_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const userSettingReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_SETTING_CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case ActionType.USER_SETTING_CHANGE_PASSWORD_SUCCESS:
      return { loading: false, setting: action.payload, success: true };
    case ActionType.USER_SETTING_CHANGE_PASSWORD_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const emailVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.EMAIL_VERIFY_REQUEST:
      return { loading: true };
    case ActionType.EMAIL_VERIFY_SUCCESS:
      return { loading: false, emailVerify: action.payload, success: true };
    case ActionType.EMAIL_VERIFY_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const verifyUenReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VERIFY_UEN_REQUEST:
      return { loading: true };
    case ActionType.VERIFY_UEN_SUCCESS:
      return { loading: false, userUen: action.payload };
    case ActionType.VERIFY_UEN_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_ERROR:
      return {};
    default:
      return state;
  }
};

export const verifyCompanyname = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VERIFY_COMPANY_REQUEST:
      return { loading: true };
    case ActionType.VERIFY_COMPANY_SUCCESS:
      return { loading: false, userUen: action.payload };
    case ActionType.VERIFY_COMPANY_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_ERROR:
      return {};
    default:
      return state;
  }
};

export const userDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_DETAILS_REQUEST:
      return { loading: true };
    case ActionType.USER_DETAILS_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case ActionType.USER_DETAILS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

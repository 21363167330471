import { ActionType } from "../../ActionTypes";

export const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_DASHBOARD_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_DASHBOARD_DETAILS_SUCCESS:
      return { ...state, loading: false, DashboardDetail: action.payload };
    case ActionType.GET_DASHBOARD_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const creditsInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_CREDITS_INFO_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_CREDITS_INFO_SUCCESS:
      return { ...state, loading: false, creditsInfo: action.payload };
    case ActionType.GET_CREDITS_INFO_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const configurationReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_CONFIGURATION_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_CONFIGURATION_SUCCESS:
      return { ...state, loading: false, DashboardDetail: action.payload };
    case ActionType.GET_CONFIGURATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardPickUpReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.DELIVERY_PICKUP_GRAPH_REQUEST:
      return { ...state, loading: true };
    case ActionType.DELIVERY_PICKUP_GRAPH_SUCCESS:
      return { ...state, loading: false, pickUpGraph: action.payload };
    case ActionType.DELIVERY_PICKUP_GRAPH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const creditUsageReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CREDIT_USAGE_GRAPH_REQUEST:
      return { ...state, loading: true };
    case ActionType.CREDIT_USAGE_GRAPH_SUCCESS:
      return { ...state, loading: false, creditUsageGraph: action.payload };
    case ActionType.CREDIT_USAGE_GRAPH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userFrequencyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_FREQUENCY_GRAPH_REQUEST:
      return { ...state, loading: true };
    case ActionType.USER_FREQUENCY_GRAPH_SUCCESS:
      return { ...state, loading: false, userFrequencyGraph: action.payload };
    case ActionType.USER_FREQUENCY_GRAPH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const topFiveReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.TOP_FIVE_GRAPH_REQUEST:
      return { ...state, loading: true };
    case ActionType.TOP_FIVE_GRAPH_SUCCESS:
      return { ...state, loading: false, topFiveGraph: action.payload };
    case ActionType.TOP_FIVE_GRAPH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const Styles = Theme => ({
  boxOuter: {
    padding: "2rem 20px",
    height:"100%",
    background:"#F7F7F7",
    display:"table",
    width:"100%",
  },
  btnContent: {
    width: "40%",
  },
  dFlex: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
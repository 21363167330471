export const Styles = Theme => ({
  boxOuter: {
    padding: "2rem 20px",
    height: "100%",
    background: "#F7F7F7",
    display: "table",
    width: "100%",
    "@media(max-width:991px)": {
      width: "100%",
    },
  },
  btnContent: {
    "@media (max-width: 991px)": {
      margin: "20px 0 0 auto",
    },
  },
  dFlex: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
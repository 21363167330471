import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { getUserFrequencyAction } from "../../redux/actions/Dashboard/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
const UserFrequency = (props) => {
  const { classes, stateDate = null, endDate = null } = props;
  const dispatch = useDispatch();
  const [optionData, setOptionData] = useState({});
  const data = useSelector((state) => state?.userFrequency);
  const [optionValueData, setOptionValueData] = useState([]);
  /* const options = {
    colors: ["#FFC200"],
    border: [1],
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "category",
      categories: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      group: {
        style: {
          fontSize: "10px",
          fontWeight: 700,
        },
        groups: [
          {
            title: "TIME",
            cols: 2,
          },
        ],
      },
      title: {
        text: "TIME",
      },
    },
    yaxis: {
      title: {
        text: "Average number of orders",
      },
    },
  };
  const series = [
    {
      name: "sales",
      data: [0, 5, 10, 5, 18, 12, 20, 10, 13, 4, 16, 5],
    },
  ]; */

  useEffect(() => {
    const opData = {
      colors: ["#FFC200"],
      border: [1],
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        type: "category",
        categories: data?.userFrequencyGraph?.data?.name,
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
          groups: [
            {
              title: "TIME",
              cols: 2,
            },
          ],
        },
        title: {
          text: "TIME",
        },
      },
      yaxis: {
        title: {
          text: "Average number of orders",
        },
      },
    };

    setOptionData(opData);
    setOptionValueData([
      {
        name: "sales",
        data: data?.userFrequencyGraph?.data?.value,
      },
    ]);
  }, [data]);

  useEffect(() => {
    if (stateDate && endDate)
      return dispatch(getUserFrequencyAction(stateDate, endDate));
  }, [stateDate, endDate]);

  useEffect(() => {
    dispatch(getUserFrequencyAction());
  }, []);
  return (
    <>
      <div id="chart" className={classes.lineChart}>
        {data && optionValueData.length > 0 && (
          <Chart
            options={optionData}
            series={optionValueData}
            type="line"
            height={280}
          />
        )}
      </div>
    </>
  );
};

export default withStyles(Styles)(UserFrequency);

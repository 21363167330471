export const Styles = (Theme) => ({
  formOuter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  formBox: {
    padding: "0 0 20px",
    "& > div": {
      width: "100%",
      paddingRight: "0",
    },
  },
  marTop: {
    marginTop: "25px",
  },
  width50: {
    width: "50%",
  },
  width30: {
    width: "30%",
  },
  dFlex: {
    display: "flex",
    alignItems: "flex-start",
    "& > :nth-child(2)": {
      marginLeft: "15px",
    },
  },
  formControl: {
    margin: "0 0 20px",
  },
  imgBox: {
    maxWidth: "100%",
    height: "auto",
    padding: "5px",
    borderRadius: "5px",
    display: "flex",
    "& img": {
      height: "auto",
      width: "50px",
    },
  },
  txtContent: {
    paddingLeft: "10px",
    "& span": {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "400",
      color: "#3F4444",
      fontFamily: "'Noto Serif', serif",
      display: "block",
    },
  },
  listBox: {
    "& p": {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#333539",
      fontFamily: "'Bebas Neue', serif",
      margin: "15px 0",
    },
    "& ul": {
      "& li": {
        display: "flex",
        aligIitems: "center",
        justifyContent: "space-between",
        "& span": {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "400",
          color: " #3F4444",
          fontFamily: "'Noto Serif', serif",
        },
        "& b": {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "700",
          color: "#3F4444",
          fontFamily: "'Noto Serif', serif",
          paddingLeft: "5px",
        },
      },
    },
  },
  checkboxContent: {
    display: "flex",
    alignItems: "center",
    "& span": {
      "&:nth-child(2)": {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
        color: "#3F4444B2",
        fontFamily: "'Noto Serif', serif",
        marginBottom: "10px",
      },
    },
  },
  btnContent: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
});

import axios from "axios";
import { ActionType } from "../../ActionTypes";
import constant from "../../../common/constant";
import { userDetailAction } from "../UserActions";
import { reloadHandler } from "../../../common/utils/utils";

export const getCompanyDetails = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_COMPANY_DETAILS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    // const {data} = await callAPI.getRequest(serverURL.company);
    dispatch({ type: ActionType.GET_COMPANY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.GET_COMPANY_DETAILS_FAIL, payload: message });
  }
};

export const companyProfileUpdate =
  (companyDetails) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.UPDATE_COMPANY_PROFILE_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.post(
        `${constant.baseUrl}/api/v1/b2b/users/company`,
        companyDetails,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.UPDATE_COMPANY_PROFILE_SUCCESS,
        payload: data,
      });
      dispatch(getCompanyDetails());
      dispatch(userDetailAction());
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.UPDATE_COMPANY_PROFILE_FAIL,
        payload: message,
      });
    }
  };

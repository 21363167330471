import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import Heading from "../../component/Heading/Heading";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import { useDispatch, useSelector } from "react-redux";
import { updateSinglEmployees } from "../../redux/actions/Employees/EmployeesAction";
import StrConstent from "../../common/StrConstent";
import { encryptValue } from "../../common/utils/utils";
const EditEmployeesForm = (props) => {
  const { classes, cancleHandler, status, parentCallback } = props;
  let dispatch = useDispatch();
  const { employee } = useSelector((state) => state.employeeData);
  const { error, scusses } = useSelector((state) => state.updateEmpMsg);
  const [inValid, setInValid] = useState(false);
  const [addEmp, setEmp] = useState({
    first_name: "",
    last_name: "",
    email: "",
    emp_id: "",
    allocated: "",
    balance: "",
  });

  useEffect(() => {
    if (error !== undefined) {
      if (error) {
        cancleHandler(false);
      }
    }
    if (scusses) {
      cancleHandler(true);
    }
  }, [error, scusses]);

  const handlerChangeInput = (e) => {
    let { name, value } = e.target;
    setEmp({ ...addEmp, [name]: value });
  };

  const updateEmpHandler = () => {
    const userId = addEmp.user_id;
    if (addEmp.first_name === "" ||addEmp.last_name === "") return setInValid(true);
    else {
      const palyLoad = {
        first_name: encryptValue(addEmp.first_name),
        last_name: encryptValue(addEmp.last_name),
        emp_id: encryptValue(addEmp.emp_id),
        email: encryptValue(addEmp.email),
      };
      dispatch(updateSinglEmployees(palyLoad, userId));
      parentCallback(true);
    }
  };

  useEffect(() => {
    if (employee.data.employee_data) {
      setEmp({ ...employee.data.employee_data });
    }
  }, [employee.data.employee_data]);

  // const { first_name, last_name, emp_id, email, allocated, balance } = addEmp;
console.log("invalid",inValid,addEmp.last_name )
  return (
    <>
      <div className={classes.formOuter}>
        <div className={classes.formBox}>
          <Heading
            heading={StrConstent.EditEmployeesForm.headerTitle}
            description={StrConstent.EditEmployeesForm.headerDesc}
          />
          <div className={classes.formInner}>
            {status == 0 ? (
              <>
                <div className={classes.fromControl}>
                  <FormLabel>Name<span className="req">*</span></FormLabel>
                  <div className={classes.dFlex}>
                    <div>
                      <input
                        type="text"
                        name="first_name"
                        value={addEmp.first_name}
                        placeholder="First name"
                        onChange={handlerChangeInput}
                      />
                      {inValid && addEmp.first_name === "" ? (
                        <p className="errorMessage">
                      This field is required.
                        </p>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        name="last_name"
                        value={addEmp.last_name}
                        placeholder="Last name"
                        onChange={handlerChangeInput}
                        className={classes.mleft15}
                      />
                         {inValid && addEmp.last_name === "" ? (
                        <p className="errorMessage">
                      This field is required.
                        </p>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.fromControl}>
                  <FormLabel>Work email address<span className="req">*</span></FormLabel>
                  <input
                    type="text"
                    name="email"
                    value={addEmp.email}
                    placeholder="Enter work email address"
                    onChange={handlerChangeInput}
                  />
                </div>
                <div className={classes.fromControl}>
                  <FormLabel>Employee ID (optional)</FormLabel>
                  <input
                    type="text"
                    name="emp_id"
                    value={addEmp.emp_id}
                    placeholder="Enter employee ID"
                    onChange={handlerChangeInput}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={classes.formControl}>
                  <FormLabel>Name<span className="req">*</span></FormLabel>
                  <div className={classes.dFlex}>
                    <div>
                      <input
                        type="text"
                        name="first_name"
                        value={addEmp.first_name}
                        placeholder="First name"
                        onChange={handlerChangeInput}
                      />
                      {inValid && addEmp.first_name === "" ? (
                        <p className="errorMessage">
                         This field is required.
                        </p>
                      ) : null}
                    </div>
                    <div>
                    <input
                      type="text"
                      name="last_name"
                      value={addEmp.last_name}
                      placeholder="Last name"
                      onChange={handlerChangeInput}
                      className={classes.mleft15}
                    />
                    {inValid && addEmp.last_name === "" ? (
                        <p className="errorMessage">
                      This field is required.
                        </p>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.formControl}>
                  <FormLabel>Work email address<span className="req">*</span></FormLabel>
                  <input
                    style={{
                      background: "beige",
                      color: "grey",
                      pointerEvents: "none",
                    }}
                    disabled={true}
                    type="text"
                    name="email"
                    value={addEmp.email}
                    readOnly
                    placeholder="Enter work email address"
                  />
                </div>
                <div className={classes.formControl}>
                  <FormLabel>Employee ID (optional)</FormLabel>
                  <input
                    style={{
                      background: "beige",
                      color: "grey",
                      pointerEvents: "none",
                    }}
                    disabled={true}
                    type="text"
                    name="emp_id"
                    value={addEmp.emp_id}
                    readOnly
                    placeholder="Enter employee ID"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={classes.btnContent}>
          <BorderBtn name={"cancel"} clickHandler={cancleHandler} />
          <YellowBtn name={"Update"} clickHandler={updateEmpHandler} />
        </div>
      </div>
    </>
  );
};

export default withStyles(Styles)(EditEmployeesForm);

import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Styles } from "./Style";
import { displayAmountFormat } from "../../common/utils/utils";
const CreditsPurchased = (props) => {
  const { classes } = props;
  const data = useSelector((state) => state?.creditUsage);
  return (
    <div className={classes.creditsBox}>
      <ul>
        <li>
          <div>
            <div className={classes.btBox}>
              <img src="./images/subtract.svg" alt="Better cloud" />
              <div>
                <p numberoflines={1} adjustsfontsizetofit="true">
                  Spend on add-ons
                </p>
                <h3>
                  {displayAmountFormat(parseFloat(data?.creditUsageGraph?.data?.add_ons).toFixed(1))}
                </h3>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className={classes.btBox}>
              <img src="./images/subtract.svg" alt="Better cloud" />
              <div>
                <p>average order</p>
                <h3>
                  {displayAmountFormat(parseFloat(
                    data?.creditUsageGraph?.data?.average_order
                  ).toFixed(1))}
                </h3>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default withStyles(Styles)(CreditsPurchased);

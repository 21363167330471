import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
const Message = (props) => {
  const { classes } = props;
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.imgContent}>
            <img src="../images/logo.svg" alt="Better Coffee" />
          </div>
          <h1>Website only support desktop and tabs view</h1>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(Message);

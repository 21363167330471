export const Styles = (Theme) => ({
  empOuter: {
    padding: "20px 25px",
  },
  p0: {
    padding: "0 !important",
  },
  topBox1: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 0 40px",
    justifyContent: "space-between",
  },
  tabsBox: {
    background: "#f1eedb",
    width: "auto",
    borderRadius: "32px",
    margin: "20px 0 30px",
    "& span": {
      display: "none",
    },
    "& button": {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#3F4444",
      fontFamily: "'Bebas Neue', serif",
      textTransform: "uppercase",
      padding: "10px 20px",
      textAlign: "center",
      width: "20%",
      background: "#F1EEDB",
      borderRadius: "32px",
      whiteSpace: "nowrap",
      minWidth: "150px",
      "@media (max-width: 991px)": {
        fontSize: "20px",
        lineHeight: "20px",
      },
      "&[aria-selected=true]": {
        color: "#3F4444",
        background: "#ffc200",
        borderRadius: "32px",
      },
    },
  },
  accordionBox: {
    borderBottom: "1px solid #DDDDDD",
    "& > div": {
      boxShadow: "none",
      background: "transparent",
      "& > div": {
        padding: "0",
        minHeight: "auto !important",
      },
      "& h3": {
        marginBottom: "0 !important",
      },
    },
  },
  heading: {
    "& > div": {
      margin: "15px 0 !important",
    },
  },
  description: {
    padding: "8px 0 !important",
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "rgba(63, 68, 68, 0.6)",
      fontFamily: "'Noto Serif', serif",
      margin: "0",
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "750px",
    margin: "auto",
    minHeight: "200px",
    padding: "0 20px",
    "@media(max-width:991px)": {
      minHeight: "250px",
    },
    "& h2": {
      fontSize: "44px",
      lineHeight: "48px",
      fontWeight: "400",
      margin: "0",
      "@media(max-width:991px)": {
        fontSize: "35px",
        lineHeight: "40px",
      },
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#3F444480",
      wordBreak: "break-word",
      textAlign: "center",
      marginTop: "0",
    },
  },
});

import { ActionType } from "../../ActionTypes";
import constant from "../../../common/constant";
import axios from "axios";
import { getCreditsInfo } from "../Dashboard/DashboardAction";
import { reloadHandler } from "../../../common/utils/utils";

export const getSpendPolicyHistory = (page) => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.SPEND_POLICY_HISTORY_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/spend-policies?page=${page}`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    // const { data } = await callAPI.getRequest(`${serverURL.company}/${company_id}/spend-policies`);
    dispatch({
      type: ActionType.SPEND_POLICY_HISTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.SPEND_POLICY_HISTORY_LIST_FAIL,
      payload: message,
    });
  }
};

export const getCategoryList = () => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.SPEND_POLICY_CATEGORY_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/categories`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    // const { data } = await callAPI.getRequest(`${serverURL.company}/${company_id}/categories`);
    dispatch({
      type: ActionType.SPEND_POLICY_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.SPEND_POLICY_CATEGORY_LIST_FAIL,
      payload: message,
    });
  }
};

export const getOutletsList = () => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.SPEND_POLICY_OUTLETS_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/outlets`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    // const { data } = await callAPI.getRequest(`${serverURL.company}/${company_id}/outlets`);
    dispatch({
      type: ActionType.SPEND_POLICY_OUTLETS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.SPEND_POLICY_OUTLETS_LIST_FAIL,
      payload: message,
    });
  }
};

export const updateSpendPolicy = (spendList) => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.SPEND_POLICY_UPDATE_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/spend-policy`,
      spendList,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    // const { data } = await callAPI.postRequest(`${serverURL.company}/${company_id}/spend-policy`,spendList);
    dispatch({ type: ActionType.SPEND_POLICY_UPDATE_SUCCESS, payload: data });
    dispatch(getCreditsInfo());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.SPEND_POLICY_UPDATE_FAIL, payload: message });
  }
};

export const updateCreditAllocation =
  (allocationList) => async (dispatch, getState) => {
    const company_id = localStorage.getItem("company_id");
    dispatch({
      type: ActionType.UPDATE_CREDIT_ALLOCATION_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.post(
        `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/allocate-credit`,
        allocationList,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.UPDATE_CREDIT_ALLOCATION_SUCCESS,
        payload: data,
      });
      dispatch(getCreditsInfo());
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.UPDATE_CREDIT_ALLOCATION_FAILURE,
        payload: message,
      });
    }
  };

export const spendPolicyCreditAllocationHistory =
  (page) => async (dispatch, getState) => {
    const company_id = localStorage.getItem("company_id");
    dispatch({
      type: ActionType.SP_CREDIT_ALLOCATION_HISTORY_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.get(
        `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/credit-allocated?page=${page}`,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.SP_CREDIT_ALLOCATION_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.SP_CREDIT_ALLOCATION_HISTORY_FAILURE,
        payload: message,
      });
    }
  };

export const spendPolicyCompanyModes = () => async (dispatch, getState) => {
  const company_id = localStorage.getItem("company_id");
  dispatch({
    type: ActionType.SP_COMPANY_MODES_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `${constant.baseUrl}/api/v1/b2b/users/company/${company_id}/modes`,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo.data.user_data.auth_token}`,
        },
      }
    );
    dispatch({
      type: ActionType.SP_COMPANY_MODES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.SP_COMPANY_MODES_FAILURE,
      payload: message,
    });
  }
};

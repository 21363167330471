import React, { useState, Fragment, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { supplyDateFormat } from "../../common/utils/utils";
const SearchCalander = (props) => {
  const {
    classes,
    onTypingEvent,
    handleSubmitSearch,
    onCancel = false,
    start,
    end,
  } = props;
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [closeIcon, setCloseIcon] = useState(false);
  const [values, setValues] = useState([null, null]);

  const handleChange = (input) => {
    onTypingEvent(input);
    setValue(input);
    if (input !== "") {
      setCloseIcon(true);
      onCancel(false);
    } else {
      setValues([null, null]);
      setCloseIcon(false);
      onCancel(true);
    }
  };

  const enterHandler = (event) => {
    if (event?.which === 13) {
      handleSubmitSearch();
    }
  };

  useEffect(() => {
    if (values[0]) start(supplyDateFormat(values[0]));
    if (values[1]) end(supplyDateFormat(values[1]));
  }, [values]);

  return (
    <div className={classes.searchBox}>
      <div className={classes.innerSearchBox}>
        <img
          onClick={() => setIsOpen(true)}
          src="./images/search.svg"
          alt="Better Coffee"
        />
        <input
          name=""
          type="text"
          value={value}
          onKeyDown={enterHandler}
          // placeholder="Employee name, Order No, email"
          placeholder="Search Name, Email or ID"
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={classes.datapickerBox}>
          <MobileDateRangePicker
            open={isOpen === null ? false : isOpen}
            startText="Date"
            showToolbar={false}
            value={values}
            okText={"Save"}
            inputFormat={"dd MMM yyyy"}
            onClose={() => {
              setIsOpen(false);
              setCloseIcon(true);
            }}
            onChange={(newValue) => setValues(newValue)}
            renderInput={(startProps, endProps) => (
              <Fragment>
                <div className={classes.datepicker}>
                  <span className={classes.borderRight}></span>
                  <img
                    onClick={() => setIsOpen(true)}
                    src="./images/date-picker-icon.svg"
                    alt="Better Coffee"
                  />
                  <span
                    onClick={() => setIsOpen(true)}
                    className={classes.dateLabel}
                  >
                    Date
                  </span>
                  <TextField
                    {...startProps}
                    onClick={() => setIsOpen(true)}
                    label=""
                  />
                  <span>-</span>
                  <TextField
                    {...endProps}
                    onClick={() => setIsOpen(true)}
                    label=""
                  />
                </div>
              </Fragment>
            )}
          />
        </div>
      </LocalizationProvider>
      {closeIcon && (
        <Button
          disableRipple
          className="closeIcon"
          onClick={() => handleChange("")}
        >
          <CloseIcon />
        </Button>
      )}
      <Button onClick={handleSubmitSearch}>Search</Button>
    </div>
  );
};
export default withStyles(Styles)(SearchCalander);

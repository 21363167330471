import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import CreditsInfo from "../../component/CreditsInfo/CreditsInfo";
import StrConstent from "../../common/StrConstent";
import HomeContentBox from "../../component/HomeContentBox/HomeContentBox";
import DashboardContentBox from "../../component/DashboardContentBox/DashboardContentBox";
import { useDispatch, useSelector } from "react-redux";
// import AlterMessage from "../../component/AlertMessage/AlertMessage";
import {
  getConfiguration,
  getDashboardDetails,
} from "../../redux/actions/Dashboard/DashboardAction";
const Dashboard = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [graph, setGraph] = useState();
  const [counter, setCounter] = useState("");
  const { userInfo } = useSelector((state) => state?.userSignin);
  const { DashboardDetail, loading } = useSelector((state) => state.details);
  // const [isOpen, setIsOpen] = useState(false);

  // const snackHandler = (event, reason) => {
  //   if (reason === "clickaway") return;
  //   setIsOpen(false);
  // };
  useEffect(() => {
    dispatch(getConfiguration());
    dispatch(getDashboardDetails());
  }, [dispatch]);
  useEffect(() => {
    setGraph(DashboardDetail?.data?.graph);
    setCounter(DashboardDetail?.data?.counter);
  }, [DashboardDetail]);
  // useEffect(() => {
  //   const isFirst = localStorage.getItem("isFirstView");
  //   if (isFirst === null) {
  //     setIsOpen(true);
  //     localStorage.setItem("isFirstView", "Yes");
  //   }
  // }, []);
  return (
    <>
      {/* {isOpen ? (
        <AlterMessage
          description={`Logged in successfully`}
          handleClose={snackHandler}
          url="./images/success.svg"
          isopen={isOpen}
          title="Success"
          type="Success"
        />
      ) : null} */}
      <div className={classes.empOuter}>
        <div className={classes.topBox1}>
          {!loading ? (
            <Heading
              heading={
                DashboardDetail?.data?.graph === true
                  ? StrConstent.Dashboard.headerTitle
                  : `${StrConstent.Home.headerTitle}${userInfo?.data?.user_data?.first_name}`
              }
              description={
                DashboardDetail?.data?.graph === true
                  ? StrConstent.Dashboard.headerDesc
                  : StrConstent.Home.headerDesc
              }
              // description={StrConstent.Dashboard.headerDesc}
            />
          ) : (
            <></>
          )}
          <CreditsInfo />
        </div>
        {!loading ? (
          DashboardDetail?.data?.graph === true ? (
            <DashboardContentBox onGraph={graph} />
          ) : (
            <HomeContentBox onCounter={counter} />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default withStyles(Styles)(Dashboard);

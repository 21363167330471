import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Styles";
import SignUpForm from "../../component/Signup/SignUpForm";
import StrConstent from "../../common/StrConstent";
function SignUpPage(props) {
  const { classes } = props
  return (
    <div className={classes.root}>
      <div className={classes.container} id="container">
        <div className={classes.overlaycontainer}>
          <div className={`${classes.overlaypanel} ${classes.overlayright}`}>
            <h1>{StrConstent.MainHeading.heading} </h1>
            <p>{StrConstent.MainHeading.description}</p>
          </div>
        </div>
        <div className={`${classes.formcontainer} ${classes.logincontainer}`}>
          <SignUpForm />
        </div>
      </div>
    </div>
  );
}

export default withStyles(Styles)(SignUpPage);

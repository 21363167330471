export const Styles = Theme => ({
    navBar: {
        display: "table",
        padding: "10px 0px 0",
        height: "100%",
        background: "#F4F4F4",
    },
    navBarInner: {
        boxSizing: "border-box",
        maxWidth: "210px",
        background: "#F4F4F4",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        maxHeight: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            appearance: "none",
            width: "0",
        },
    },
    menu: {
        "& > ul": {
            padding: "0",
            margin: "0",
            "& li": {
                margin: "0 0 15px",
                padding: "0",
                backgroundColor: "transparent !important",
                borderRadius: "0px 60px 60px 0px",
                "& a": {
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    minHeight: "40px",
                    padding: "8px 15px",
                    textDecoration: "none",
                    transition: ".5s ease",
                    width: '100%',
                    borderRadius: "0px 60px 60px 0px",
                    "&:hover": {
                        backgroundColor: "#FFC200 !important",
                        color: "#0f121d !important",
                    },
                },
            },
        },
    },
    logoContent: {
        maxWidth: "150px",
        height: "auto",
        margin: "20px auto 50px",
        maxHeight: "200px",
        textAlign: "center",
        "@media (max-width: 1024px)": {
            maxWidth: "120px",
            height: "auto",
            margin: "20px auto 35px",
            maxHeight: "150px",
        },
        "& a": {
            display: "block",
        },
        "& img": {
            height: "auto",
            width: "100%",
            objectFit: "cover",
        },
    },
    items: {
        display: "block",
        alignItems: "center",
        cursor: "pointer",
        minHeight: "35px",
        textDecoration: "none",
        transition: ".5s ease",
    },
    iconContent: {
        paddingRight: "10px",
        minWidth: "30px",
        height: "30px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& img": {
            height: "auto",
            width: "auto",
            "@media (max-width: 991px)": {
                width: "18px",
            },
        },
    },
    txtContent: {
        "& span": {
            fontSize: "16px",
            lineHeight: "16px",
            color: "#3F4444",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            "@media (max-width: 991px)": {
                fontSize: "14px",
                lineHeight: "164x",
            },
        },
    },
    btBox: {
        borderTop: "1px solid #DDDDDD",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "15px",
        "& button": {
            width: "auto",
            minWidth: "auto",
            padding: "0 0 0 5px",
            backgroundColor: "#F4F4F4 !important",
            "& :hover": {
                backgroundColor: "#F4F4F4",
            },
            "& svg": {
                color: "#3F4444",
            },
        },
    },
    txtBox: {
        fontSize: "12px",
        lineHeight: "12px",
        color: "#3F4444",
        fontWeight: "700",
        fontFamily: "'Noto Serif', serif",
        textTransform: "capitalize",
        textAlign: "left",
        wordBreak: "break-word",
        textTransform: 'capitalize',
        "& span": {
            fontWeight: "400",
            display: "block",
            paddingTop: "5px",
        },
    },
    txtLimit: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box !important',
        lineClamp: 2,
        WebkitBoxOrient: 'vertical',
        lineHeight:'16px',
    },
    logoutSelectBox: {
        "& ul": {
            overflowY: "hidden",
            overflowX: "hidden",
            padding: "14px 0",
            borderRadius: "20px",
        },
        "& li": {
            padding: "12px 20px !important",
            width: "180px",
            display: "flex",
            justifyContent: "space-between",
            "& p": {
                fontSize: "12px ",
                lineHeight: "12px",
                color: "#3F4444",
                fontWeight: "400",
                fontFamily: "'Noto Serif', serif",
                margin: "0",
            },
            "& a": {
                textDecoration: "none",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            },
        },
        "& img": {
            height: "14px",
            width: "14px",
            marginLeft: "12px",
        }
    },
    staging: {
        background: "#FFC200 !important",
        fontSize: "16px !important",
        fontWeight: "700",
        textTransform: "uppercase",
        margin: "20px auto 0",
        "& span": {
            // fontFamily: '"Bebas Neue", serif',
        }
    }
})

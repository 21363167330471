import { DateTimeDisplay } from "./DateTimeDisplay";
export const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {/* <DateTimeDisplay value={days} type={'Days'}  />
          <p>:</p> 
      <DateTimeDisplay value={hours} type={"Hours"}  />
      <p>:</p>*/}
      <div className="errorMessage">
        {" "}
        Resend again in <DateTimeDisplay value={minutes} type={"Min"} />
        <span style={{ padding: "0 3px" }}>:</span>
        <DateTimeDisplay value={seconds} type={"Second"} />
      </div>
    </div>
  );
};

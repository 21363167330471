import { ActionType } from "../../ActionTypes";

const initialState = {
  loading: true, transactionDta: []
}
export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.TRANSACTION_DATA_REQUEST:
      return { loading: true };
    case ActionType.TRANSACTION_DATA_SUCCESS:
      return { loading: false, success:true, transactionDta: action.payload };
    case ActionType.TRANSACTION_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const getTransactions = (state = initialState, action ) => {
  switch (action.type) {
    case ActionType.GET_TRANSACTIONS_REQUEST:
      return { loading: true};
    case ActionType.GET_TRANSACTIONS_SUCCESS:
      return { loading: false, success:true, transactionList: action.payload };
    case ActionType.GET_TRANSACTIONS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getOrdreDetailReducer = (state = initialState, action ) => {
  switch (action.type) {
    case ActionType.GET_ORDER_DETAIL_REQUEST:
      return { loading: true};
    case ActionType.GET_ORDER_DETAIL_SUCCESS:
      return { loading: false, success:true, orderDetail: action.payload };
    case ActionType.GET_ORDER_DETAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
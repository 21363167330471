import React, { useState, useCallback, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Heading from "../../component/Heading/Heading";
import YellowBtn from "../../component/YellowBtn/YellowBtn";
import BorderBtn from "../../component/BorderBtn/BorderBtn";
import StrConstent from "../../common/StrConstent";
import { useDropzone } from "react-dropzone";
import AlterMessage from "../../component/AlertMessage/AlertMessage";
// import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { employeeImportHandler } from "../../redux/actions/Employees/EmployeesAction";
const AddEmployeesVia = (props) => {
  const { classes, onOpen, cancelHandler } = props;
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state?.employeeImport);
  const [errorMsg, setErrorMsg] = useState("");
  const [isBlank, setIsBlank] = useState(false);
  const [showFiles, setShowFiles] = useState(null);
  const [showIconExcel, setShowIconExel] = useState(true);
  const [dropAreaShow, setDropAreaShow] = useState(true);
  // const [uploadPercentage, setUploadPercentage] = useState(0);
  const [import_file, setImport_file] = useState();
  const imageMaxSize = 6000000; // bytes
  const acceptedFileTypes = ".csv";
  const addViaCsvError = useSelector((state) => state?.employeeImport?.error);
  const addViaCsvSuccess = useSelector(
    (state) => state?.employeeImport?.updateMess?.message
  );
  const success = useSelector(
    (state) => state?.updateEmpBalance?.updateBalMess
  );

  useEffect(() => {
    if (addViaCsvError || addViaCsvSuccess || success) {
      if (addViaCsvSuccess) {
        onOpen(false);
        cancelHandler();
      }
    }
  }, [addViaCsvError, addViaCsvSuccess, success]);

  const getWorkbookFromFile = async (excelFile) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.onload = (event) => {
        const text = event.target.result;
        // console.log("--- text", text);
        if (text.indexOf("First name,Last name,Email,Employee Id") < 0) {
          // setErrorMsg("No data on Excel");
          // return false
          resolve(false);
        } else {
          resolve(true);
        }

        // var data = event.target.result;

        // var workbook = xlsx.read(data, { type: 'binary' });

        // console.log(workbook.SheetNames);
        // resolve(workbook);
      };
      reader.readAsText(excelFile);
    });
  };
  const verifyFile = async (files) => {
    // const reader = new FileReader();
    // reader.onload = function (e) {
    //   const text = e.target.result;
    //   console.log("--- text", text);
    //   if (text.indexOf("First name,Last name,Email,Employee Id") < 0) {
    //     setErrorMsg("No data on Excel");
    //     return false
    //   }
    // };
    // reader.readAsText(files[0]);
    if (files && files.length > 0) {
      const currentFile = files[0];
      var workbook = await getWorkbookFromFile(currentFile);
      if (!workbook) {
        setIsBlank(true);
        return false;
      }
      console.log("currentFile", currentFile);
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        setErrorMsg(
          // `This file is not allowed. ${currentFileSize} bytes is too large`
          `The file size exceeds 6MB. Please re-upload a smaller file size.`
        );
        return false;
      }
      setErrorMsg("");
      return true;
    }
  };

  // const option = {
  //   onUploadProgress: (progressEvent) => {
  //     const { loaded, total } = progressEvent;
  //     let percent = Math.floor((loaded * 100) / total);
  //     if (percent < 100) {
  //       setUploadPercentage(percent);
  //     }
  //   },
  // };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    console.log("rejectedFiles", rejectedFiles);
    console.log("acceptedFiles", acceptedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      const currentFile = rejectedFiles[0];
      console.log("**file-invalid-type", currentFile?.errors[0]?.code);
      const currentFileType = currentFile.file.type;
      if (currentFile.errors[0].code === "file-too-large")
        return setErrorMsg(
          <b className="errorMessage">
            The file size exceeds 6MB. Please re-upload a smaller file size.
          </b>
        );
      else if (
        currentFile?.errors[0]?.code === "file-invalid-type" ||
        !acceptedFileTypes.includes(currentFileType)
      ) {
        setErrorMsg(
          <b className="errorMessage">
            This file format is not supported. Please upload only .csv files.
          </b>
        );
      } else {
        setErrorMsg("");
      }
    }
    if (acceptedFiles && acceptedFiles.length > 0) {
      setErrorMsg("");
      const isVerified = verifyFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles;
        let data = new FormData();
        data.append("file", currentFile[0]);
        setShowFiles(currentFile);
        setDropAreaShow(false);
        setImport_file(currentFile[0]);
      }
    }
  }, []);

  const formatBytesToMegaByte = (size) => {
    const num = parseInt(size) / 1000000;
    return parseFloat(num).toFixed(1);
  };

  const removeFile = (index) => {
    setDropAreaShow(true);
    const currentFiles = showFiles;
    currentFiles.splice(index, 1);
    setShowFiles(currentFiles);
    setErrorMsg("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxSize: imageMaxSize,
  });
  const uploadHandler = () => {
    if (import_file?.size < 55) return setIsBlank(true);
    else if (errorMsg === "") {
      const bodyFormData = new FormData();
      bodyFormData.append("import_file", import_file);
      dispatch(employeeImportHandler(bodyFormData));
      // props.onOpen(isOpen);
      // cancelHandler();
    }
  };
  useEffect(() => {
    onOpen(true);
    // console.log("import_file", import_file);
  }, [error]);
  return (
    <>
      {/* {(updateMess?.success || error) && (
        <AlterMessage
          handleClose={() => setIsOpen(false)}
          description={updateMess?.message || error}
          title={updateMess?.success ? "Success" : "Error"}
          type={updateMess?.success ? "Success" : "Error"}
          isopen={isOpen}
          url={updateMess?.success ? "./images/success.svg" : "./images/error.svg"}
        />
      )} */}
      <AlterMessage
        description={`The file has missing or mismatched data. Please try again.`}
        handleClose={() => setIsBlank(false)}
        url={"./images/error.svg"}
        isopen={isBlank}
        title={"Error"}
        type={"Error"}
      />
      <div className={classes.formOuter}>
        <div className={classes.formBox}>
          <Heading heading={StrConstent.AddEmployeesVia.headerTitle} />

          <p>
            Download
            <a
              className={classes.downloadLink}
              href={`https://bettr-pub-assets.s3.ap-southeast-1.amazonaws.com/sample_employee.csv`}
            >
              CSV template here
            </a>
            to bulk upload employees.
          </p>
          {dropAreaShow && (
            <div className={classes.formInner}>
              <div className={classes.uploadFile}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <img src="./images/file-upload.svg" alt="Better cloud" />
                  <b>Drag and drop your CSV file here</b>
                  <span>File size limit of 6MB</span>
                </div>
              </div>
            </div>
          )}
          <span className="errorMessage">{errorMsg}</span>
          {showFiles && (
            <div className={classes.fileContainer}>
              {showFiles.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "block",
                      padding: "10px",
                      border: "1px solid #DDDDDD",
                      borderTopLeftRadius: index === 0 ? "6px" : "0",
                      borderTopRightRadius: index === 0 ? "6px" : "0",
                      borderBottomLeftRadius:
                        index === showFiles.length - 1 ? "6px" : "0",
                      borderBottomRightRadius:
                        index === showFiles.length - 1 ? "6px" : "0",
                      marginBottom: "0",
                    }}
                  >
                    {!showIconExcel ? null : (
                      <div className={classes.csvIcon}>
                        <img
                          alt="ic_csv"
                          className={classes.image}
                          src="./images/ic_csv.svg"
                        />
                      </div>
                    )}
                    <div className={classes.loadFilesBox}>
                      <span className={classes.fileName} title={item.name}>
                        {item.name}
                      </span>
                      <span className={classes.sizeOfFileClr}>
                        ({formatBytesToMegaByte(item.size)} MB)
                      </span>
                      <div className={classes.deleteIcon}>
                        {/* <DeleteIcon
                          className={classes.dltIcon}
                          onClick={() => removeFile(index)}
                        /> */}
                        <img
                          src="./images/delete.svg"
                          alt="Bettr Coffee"
                          onClick={() => removeFile(index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={classes.btnContent}>
          <BorderBtn name={"cancel"} clickHandler={cancelHandler} />
          <YellowBtn
            disabled={dropAreaShow}
            name={"Confirm"}
            clickHandler={uploadHandler}
          />
        </div>
      </div>
    </>
  );
};

export default withStyles(Styles)(AddEmployeesVia);

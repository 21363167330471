export const Styles = Theme => ({
    boxContent: {
        display: "flex",
        "@media(max-width:991px)":{
         flexWrap:"wrap",
        },
        "& li": {
            display:"inline-block",
            width: "25%",
            paddingRight:"20px",
            marginBottom:"20px",
            "@media(max-width:991px)":{
                width: "50%",
                paddingRight:"20px",
            }
        },
        "& :nth-child(4n+4)":{
            paddingRight:"0", 
        },
        "& :nth-child(even)":{
            "@media(max-width:991px)":{
                paddingRight:"0", 
            },
        },
        "& a": {
            width:"100%",
            textDecoration: "none"
        },
    },

    innerListing: {
        padding: "12px 16px",
        borderRadius: "50px",
        display: "flex",
        alignItems: "center",
        background: "#fff",
        "& span": {
            minHeight: "30px",
            minWidth: "30px",
            color: "#fff",
            fontSize: "20px",
            lineHeight: "30px",
            background: "#FFC200",
            borderRadius: "100%",
            textAlign: "center",
            fontFamily: "'Noto Serif', serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& p": {
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "18px",
            textAlign: "left",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            margin: "0",
            paddingLeft: "10px",
        },
    },
    complate:{
        background:"#56b54c !important"
    }
})